import { ComparableEnvGroupType, type EnvGroup } from '@resistapp/common/comparable-env-groups';
import { EnvironmentSubType } from '@resistapp/common/environment-types';
import { MetricMode } from '@resistapp/common/types';
import { chain, isNil } from 'lodash';

export function getGeneralEnvironmentSubtype(subtype: EnvironmentSubType | undefined) {
  if (isNil(subtype)) {
    return undefined;
  }
  switch (true) {
    case subtype.includes('HOSPITAL_'):
      return 'hospital';
    case subtype.includes('URBAN_RUNOFF'):
      return 'runoff';
    case subtype.includes('URBAN_'):
      return 'urban';
    case subtype.includes('SEPTIC_'):
      return 'septic';
    default:
      return undefined;
  }
}

// We remove duplicate environments, so we always show different data, when changing environment types
export function getComparableGroups(availableEnvGroups: EnvGroup[], metricMode: MetricMode): string[] {
  return chain(availableEnvGroups)
    .uniqBy(group =>
      group.envs
        .map(env => env.id)
        .sort()
        .join(','),
    )
    .filter(
      group =>
        metricMode !== MetricMode.REDUCTION ||
        (group.type !== ComparableEnvGroupType.RAW_WASTEWATER &&
          group.type !== ComparableEnvGroupType.TREATED_WASTEWATER),
    )
    .map(g => g.type)
    .value();
}
