import { Box } from '@chakra-ui/react';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewLineData } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getNumDetectedAndAnalysedAssays } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { allGeneGroups, Target } from '@resistapp/common/assays';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { ReactNode } from 'react';
import { getGroupColor, getGroupTextColor } from '../shared/palettes';
import { theme } from '../shared/theme';
import { AntibioticSelector } from './antibiotic-selector';
import { EnvironmentTypeSelector } from './environment-type-selector';
import { MonthSelector } from './month-selector';
import { antibioticGroupOptions, OptionBase, OptionForTargets } from './types';

type Props = {
  title: string;
  onChangeAntibiotic: (value: OptionBase | OptionForTargets | null) => void;
  onChangeEnvTypeGroup: (value: OptionBase | null) => void;
  selectedTargets: Target[];
  trendData: OverviewLineData | undefined;
  children: ReactNode;
};

export function ScopeSelectors({
  onChangeAntibiotic,
  onChangeEnvTypeGroup,
  selectedTargets,
  trendData,
  children,
}: Props) {
  const { processMode, metricMode } = useOverviewContext();
  const selectedAntibiotic = getSelectedAntibioticOption(selectedTargets);

  // We only want to show antibiotics that have been analysed
  const analyzedAntibioticGroups = getAnalysedGroupsAndAll(trendData, metricMode, processMode);

  return (
    <Box fontSize={theme.fontSize.medium} fontWeight={theme.fontWeight.bold} position={'relative'}>
      {children}
      <AntibioticSelector
        metricMode={metricMode}
        onChange={onChangeAntibiotic}
        selectedAntibiotic={selectedAntibiotic as OptionForTargets}
        analyzedAntibioticGroups={analyzedAntibioticGroups}
      />
      <EnvironmentTypeSelector onChange={onChangeEnvTypeGroup} />
      <MonthSelector />
    </Box>
  );
}

function getAnalysedGroupsAndAll(
  trendData: OverviewLineData | undefined,
  metricMode: MetricMode,
  processMode: ProcessMode,
) {
  const analyzedAntibioticGroups = allGeneGroups.antibiotic.filter(antibiotic => {
    return trendData?.flat().some(datum => {
      return getNumDetectedAndAnalysedAssays(datum, antibiotic, metricMode, processMode).analysedCount > 0;
    });
  });
  return analyzedAntibioticGroups.length > 0
    ? (['All antibiotics', ...analyzedAntibioticGroups] satisfies Array<Target | 'All antibiotics'>)
    : ([] satisfies Array<Target | 'All antibiotics'>);
}

function getSelectedAntibioticOption(selectedGroups: Target[]): OptionBase {
  // We minus one because we don't want to include 'All antibiotics' in the count
  const isAllAntibiotics = selectedGroups.length === antibioticGroupOptions.length - 1;
  const selectedValue = isAllAntibiotics ? 'All antibiotics' : selectedGroups[0];
  const groupColor = isAllAntibiotics
    ? 'white'
    : selectedGroups[0]
      ? getGroupColor(selectedGroups[0], 'antibiotic')
      : 'white';
  const groupTextColor = isAllAntibiotics
    ? theme.colors.neutral800
    : selectedGroups[0]
      ? getGroupTextColor(selectedGroups[0], 'antibiotic')
      : theme.colors.neutral800;
  return {
    value: selectedValue,
    label: selectedValue,
    textColor: groupTextColor,
    bgColor: groupColor,
  };
}
