import { AbundanceStats, Pooling, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import {
  EnvGroup,
  EnvironmentTypeGroup,
  getComparableEnvironmentGroups,
} from '@resistapp/common/comparable-env-groups';
import { EnvironmentType, isAfterSample, isBeforeSample } from '@resistapp/common/environment-types';
import { ensureUtcMonth, friendlyMonth } from '@resistapp/common/friendly';
import { poolSampleEnvironments } from '@resistapp/common/pool-samples';
import { arePropertiesPresentInAllFullSamples } from '@resistapp/common/typeguards';
import {
  AdminAreaByLevel,
  Environment,
  FullAbundance,
  FullSample,
  FullSamplesByUID,
  FullSampleWithTime,
  MetricMode,
  PooledEnvHack,
  ProcessMode,
} from '@resistapp/common/types';
import { flattenSamplesByUID, safeAssert } from '@resistapp/common/utils';
import { chain, intersection, isNil, keys, pickBy } from 'lodash';
import { getResistanceIndexData, WithAbundances } from '../../../common/statistics/resistance-index';
import { DateDatum } from './research-plot-data';

export type SingleOverviewLineSeries = OverviewDatum[];
export type OverviewLineData = SingleOverviewLineSeries[];

export interface OverviewDatum extends DateDatum, AbundanceStats {
  environment: Environment | PooledEnvHack;
  lat: number | undefined;
  lon: number | undefined;
  inferredLat: number | undefined;
  inferredLon: number | undefined;
  adminLevels: AdminAreaByLevel | undefined;
  city: string | undefined;
  region: string | undefined;
  country: string | undefined;
  beforeAbundances: FullAbundance[] | undefined;
  afterAbundances: FullAbundance[] | undefined;
  environmentAfter?: Environment | PooledEnvHack;
}

export const unsuportedOverviewEnvTypeGroups: EnvironmentTypeGroup[] = [
  // AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS,
  EnvironmentType.CONTROL,
  EnvironmentType.STOOL, // Used as control in some projects
  EnvironmentType.FOOD,
];

export function getBeforeOrAfterAbundances<T extends WithAbundances>(
  datum: T,
  metricMode: MetricMode,
  processMode: ProcessMode,
) {
  const step: ProcessMode.AFTER | ProcessMode.BEFORE =
    processMode === ProcessMode.BEFORE || processMode === ProcessMode.AFTER
      ? processMode
      : metricMode === MetricMode.RISK
        ? ProcessMode.AFTER
        : ProcessMode.BEFORE;
  const key = step === ProcessMode.BEFORE ? ('beforeAbundances' as const) : ('afterAbundances' as const);
  const abundances = datum[key];
  return abundances;
}

export function getComparableEnvGroupsForOverview(samplesByUID: FullSamplesByUID, metricMode: MetricMode): EnvGroup[] {
  const flatSamples = flattenSamplesByUID(samplesByUID);
  const flatEnvs = chain(flatSamples)
    .map(s => s.environment)
    .uniqBy(env => env.id)
    .value();
  const comparableGroups = getComparableEnvironmentGroups(flatEnvs, metricMode);
  return comparableGroups.filter(group => !unsuportedOverviewEnvTypeGroups.includes(group.type));
}

/*
 * Get relevant samples for overview and drop admin levels that are not present in all samples
 *
 * Overview is designed for (and may make biological sense only for) selected environment types
 */
export function getSupportedSamplesWithConsistentAdminLevels(
  samplesByUID: FullSamplesByUID,
  envGroups: EnvGroup[],
): FullSample[] {
  const supportedEnvs = new Set(envGroups.flatMap(envGroup => envGroup.envs).map(env => env.id));
  const flatSamples = flattenSamplesByUID(samplesByUID);
  const flatSupportedSamples = flatSamples.filter(
    sample =>
      !isNil(sample.inferredLat) &&
      !isNil(sample.inferredLon) &&
      !isNil(sample.lat) &&
      !isNil(sample.lon) &&
      !isNil(sample.time) &&
      supportedEnvs.has(sample.environment.id),
  );
  const levelsInAllSamples = new Set(
    chain(flatSupportedSamples)
      .map(s => s.adminLevels || {})
      .map(levels => keys(levels).map(level => +level))
      .reduce((acc, levels) => intersection(acc, levels))
      .value(),
  );
  const supportedSamplesWithConsistentLevels = flatSupportedSamples.map(sample => ({
    ...sample,
    adminLevels: pickBy(sample.adminLevels, datum => levelsInAllSamples.has(datum.level)),
  }));
  return supportedSamplesWithConsistentLevels;
}

/*
 * Build BoxDatum series (arrays), where each series contains time series BoxDatums for one overview environment / location (line)
 */
export function buildOverviewLineData(
  samples: FullSample[],
  selectedEnvHash: Set<number>,
  processMode: ProcessMode,
  pooling: Pooling | undefined,
): OverviewLineData {
  if (!samples.length) {
    throw new Error('No supported samples available');
  }
  const samplesHaveTimes = arePropertiesPresentInAllFullSamples(samples, 'time');
  if (!samplesHaveTimes) {
    throw new Error('Supported samples unexpectedly missing time');
  }
  const samplesHaveLocations = arePropertiesPresentInAllFullSamples(samples, 'inferredLat');
  if (!samplesHaveLocations) {
    throw new Error('Supported samples unexpectedly missing location');
  }

  // Before pooling by admin level, we have to combine site samples
  const maybeSitePooledSamples =
    pooling?.type === PoolingType.SITE_AND_ADMIN_LEVEL
      ? poolSampleEnvironmentsForOverview(samples, {
          type: PoolingType.SITE,
          mode: PoolingMode.THROW_MISSING,
        })
      : samples;

  // Proceed with primary pooling
  const maybePooledSamples = poolSampleEnvironmentsForOverview(maybeSitePooledSamples, pooling);

  return chain(maybePooledSamples)
    .groupBy(sample => sample.environment.id)
    .mapValues((siteSamples, _, _c) => {
      return buildSingleOverviewLineSeries(siteSamples, selectedEnvHash, processMode);
    })
    .values()
    .value();
}

function poolSampleEnvironmentsForOverview(
  samples: FullSampleWithTime[],
  pooling: Pooling | undefined,
): FullSampleWithTime[] {
  // Special case checks
  if (!pooling) {
    return samples;
  } else if (pooling.type !== PoolingType.SITE_AND_ADMIN_LEVEL && pooling.type !== PoolingType.SITE) {
    throw Error('Only site and admin level pooling are supported in the overview');
  }

  // Combine sample environments so that samples with the same pooling criteria share the environment
  const pooledSamples = poolSampleEnvironments(samples, pooling, undefined);

  return pooledSamples;
}

function buildSingleOverviewLineSeries(
  siteSamples: FullSampleWithTime[],
  selectedEnvHash: Set<number>,
  processMode: ProcessMode,
): SingleOverviewLineSeries {
  return (
    chain(siteSamples)
      // Always use monthly grouping
      .groupBy(sample => getSnappedTimeFields(sample).snappedTime)
      .mapValues(siteSamplesForTimePoint => buildOverviewDatum(siteSamplesForTimePoint, selectedEnvHash, processMode))
      .values()
      .sortBy(datum => new Date(datum.date))
      .value()
  );
}

function buildOverviewDatum(
  siteOrAreaSamplesForTimePoint: FullSampleWithTime[],
  selectedEnvHash: Set<number>,
  processMode: ProcessMode,
): OverviewDatum {
  // Originally, all sites have a subtype or none have
  // Env type pooling should only ever result in 1 or 2 uniq sub types for pooled samples
  // Area pooling results in undefined or one subtype -> one uniq
  // Site pooling results in 1 for process sites and 2 for pooled sites
  const numSubEnvs = chain(siteOrAreaSamplesForTimePoint)
    .map(s => s.environment.subtype)
    .uniq()
    .value().length;
  const isIndividualProcessSite = numSubEnvs === 2; // Else is area or single sampled environment site marker

  // Allow all process samples for individual process sites so that we can show secondary figures in the UI
  const selectedSamples = siteOrAreaSamplesForTimePoint.filter(
    s => isIndividualProcessSite || keepSelectedEnvs(s, selectedEnvHash),
  );
  const beforeSamples = selectedSamples
    .filter(s => isBeforeSample(s) || (!isBeforeSample(s) && !isAfterSample(s)))
    .filter(_ => isIndividualProcessSite || processMode !== ProcessMode.AFTER)
    .filter(
      s =>
        isIndividualProcessSite ||
        !!selectedEnvHash.has((s.environment as PooledEnvHack).originalEnvironmentId || s.environment.id),
    );
  const afterSamples = selectedSamples
    .filter(s => isAfterSample(s))
    .filter(_ => isIndividualProcessSite || processMode !== ProcessMode.BEFORE)
    .filter(
      s =>
        isIndividualProcessSite ||
        !!selectedEnvHash.has((s.environment as PooledEnvHack).originalEnvironmentId || s.environment.id),
    );

  if (isIndividualProcessSite && beforeSamples.length + afterSamples.length !== selectedSamples.length) {
    throw Error(`Before samples.length ${beforeSamples.length} + ${afterSamples.length} != ${selectedSamples.length}`);
  }

  const isSupportedSample = (s: FullSampleWithTime) =>
    !!selectedEnvHash.has((s.environment as PooledEnvHack).originalEnvironmentId || s.environment.id);
  const hasSupportedEnvType = beforeSamples.find(isSupportedSample) || afterSamples.find(isSupportedSample);

  const beforeAbundances: FullAbundance[] = hasSupportedEnvType
    ? beforeSamples.flatMap(sampleLocal => sampleLocal.abundances)
    : [];
  const afterAbundances: FullAbundance[] = hasSupportedEnvType
    ? afterSamples.flatMap(sampleLocal => sampleLocal.abundances)
    : [];
  const { data } = getResistanceIndexData(
    processMode === ProcessMode.AFTER ? afterAbundances : beforeAbundances,
    undefined,
  );
  const firstSample = siteOrAreaSamplesForTimePoint[0];
  const { snappedTime, formattedTime } = getSnappedTimeFields(firstSample);
  return {
    ...data,
    date: snappedTime,
    beforeAbundances,
    afterAbundances,
    label: formattedTime,
    environment: firstSample.environment,
    lat: firstSample.lat,
    lon: firstSample.lon,
    inferredLat: firstSample.inferredLat,
    inferredLon: firstSample.inferredLon,
    adminLevels: firstSample.adminLevels,
    city: firstSample.city,
    region: firstSample.region,
    country: firstSample.country,
  };
}

function keepSelectedEnvs(s: FullSampleWithTime, selectedEnvHash: Set<number>) {
  const hackedEnv = s.environment as PooledEnvHack;
  safeAssert(!!hackedEnv.originalEnvironmentId, `Unexpectedly missing originalEnvironmentId`);
  return selectedEnvHash.has(hackedEnv.originalEnvironmentId);
}

function getSnappedTimeFields({ time }: Pick<FullSampleWithTime, 'time'>) {
  const snappedTime = ensureUtcMonth(time);
  const formattedTime = friendlyMonth(snappedTime, 'postfixAlways');
  return { snappedTime: snappedTime.toISOString(), formattedTime };
}
