import { SimpleGrid } from '@chakra-ui/react';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { get_inRawOrEffluentSamplesString } from '@resistapp/client/utils/string-manipulation';
import { Targets } from '@resistapp/common/assays';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { MetricMode } from '@resistapp/common/types';
import { RefObject, createRef, useRef } from 'react';
import { MetricTitle } from '../shared/metric-title';
import { AntibioticCompositionDetails } from './site-details/antibiotic-composition-details';
import { DetailGraph } from './site-details/detail-graph';
import { GeneralSiteDetails } from './site-details/general-site-details';
import { MSTDetails } from './site-details/mst-details';
import { PathogenDetails } from './site-details/pathogen-details';

export function SiteDetailBoxes() {
  const { queryFilters } = useSampleDataContext();
  const { metricMode, selectedSiteDatum, processMode } = useOverviewContext();
  const containerRefs = useRef<Array<RefObject<HTMLDivElement>>>(
    Array.from({ length: 5 }, () => createRef<HTMLDivElement>()),
  );

  if (!selectedSiteDatum) {
    return null;
  }

  const unit = metricMode === MetricMode.REDUCTION ? <MetricTitle noAntibiotic={true} plural={true} /> : 'Copy numbers';

  const components = [
    <PathogenDetails key="pathogen" containerRef={containerRefs.current[0]} />,
    <DetailGraph
      key="pollution"
      id="site-detail-box-pollution"
      containerRef={containerRefs.current[1]}
      minorTarget={MinorTarget.POLLUTION_MARKER}
      title="Pollution markers"
      titleExplanation={
        <span>
          {unit} of genes associated with AMR pollution
          {get_inRawOrEffluentSamplesString(selectedSiteDatum, processMode)}
        </span>
      }
      noGenesText="No pollution markers detected"
    />,
    metricMode === MetricMode.REDUCTION ? (
      <DetailGraph
        key="mge"
        id="site-detail-box-mge"
        containerRef={containerRefs.current[2]}
        minorTarget={MinorTarget.MGE}
        title="Mobile genetic elements"
        titleExplanation={<span>{unit} of genes associated with mobile genetic elements</span>}
        noGenesText="No mobile genetic elements detected"
      />
    ) : (
      <GeneralSiteDetails
        key="mge"
        id="site-detail-box-mge"
        containerRef={containerRefs.current[2]}
        target={Targets.MGE}
        title="Mobile genetic elements"
        explanation={
          <span>
            {unit} of genes associated with mobile genetic elements{' '}
            {get_inRawOrEffluentSamplesString(selectedSiteDatum, processMode)}
          </span>
        }
      />
    ),
    <MSTDetails key="mst" containerRef={containerRefs.current[3]} />,
    <AntibioticCompositionDetails
      key="antibiotic"
      containerRef={containerRefs.current[4]}
      antibioticTarget={queryFilters.filters.selectedTargets}
    />,
  ];

  const orderedComponents =
    metricMode === MetricMode.RISK || metricMode === MetricMode.REDUCTION
      ? [components[components.length - 1], ...components.slice(0, -1)]
      : components;

  return (
    <SimpleGrid columns={{ base: 1, lg: 2, '2xl': 3 }} gap={8} className="test_site-detail-boxes">
      {orderedComponents}
    </SimpleGrid>
  );
}
