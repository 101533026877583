import { Dictionary, get, keyBy, mapValues, mean, sortBy, uniq } from 'lodash';
import { GeneGrouping, getAssay, getGroup, sixteenS } from './assays';
import { getNormalisedValue } from './normalisation-mode';
import { assertSingleProject, getSampleUID } from './sample-uid-utils';
import {
  Abundance,
  AbundanceByBG,
  AbundancesByUBG,
  CsvRows,
  FullAbundance,
  FullProject,
  FullSample,
  NormalisationMode,
} from './types';
import { flattenSamplesByUID, getBioNumber } from './utils';

export function projectToCsvRows(sampling: FullProject, normalisationMode: NormalisationMode): CsvRows {
  assertSingleProject(sampling.samplesByUID);

  const samples = flattenSamplesByUID(sampling.samplesByUID);
  const abundanceByUBG = getAbundancesBySBG(samples);
  const allGenes = samples.reduce<string[]>((acc, sample) => {
    sample.abundances.forEach(r => {
      acc.push(r.gene);
    });
    return acc;
  }, []);
  const genes = uniq(allGenes);
  if (allGenes.length !== genes.length * samples.length) {
    throw Error('All genes not analysed for all samples');
  }

  const unsortedRows = genes.reduce<Array<Dictionary<string | number | undefined>>>((rows, gene) => {
    const assay = getAssay(gene);
    const group = getGroup(gene);
    if (group === undefined) {
      throw Error(`Unknown gene ${gene}`);
    }
    return [
      ...rows,
      samples.reduce<Dictionary<string | number | undefined>>(
        (row, sample) => {
          const UID = getSampleUID(sample);
          const abundance = abundanceByUBG[UID]?.[sample.bioRep]?.[gene];
          if (!abundance) {
            throw Error(`Missing abundance for ${getBioNumber(sample)} ${gene}`);
          }
          const value = getNormalisedValue(abundance, normalisationMode);
          row[getBioNumber(sample)] = value ? value : undefined;
          return row;
        },
        { group, gene, assay },
      ),
    ];
  }, []);

  const idxByGene = buildSortIdxByGene(samples, 'target');
  return sortBy(unsortedRows, row => (row.gene ? idxByGene[row.gene] : 0));
}

export function buildSortIdxByGene(allSamples: FullSample[], grouping: GeneGrouping, focusedSamples?: FullSample[]) {
  const abundanceBySBG = getAbundancesBySBG(allSamples);
  const allGenes = getUniqueGenes(allSamples).filter(gene => getGroup(gene) !== sixteenS || grouping === sixteenS);
  const meanByGroup = calcMeanByGroup(abundanceBySBG, grouping, false);
  const meanByGene = calcMeanByGene(abundanceBySBG, false);
  const tracesByGroup = calcMeanByGroup(abundanceBySBG, grouping, true);
  const tracesByGene = calcMeanByGene(abundanceBySBG, true);

  allGenes.sort((geneA, geneB) => {
    // The gene fallbacks are for 16s
    const groupA = getGroup(geneA, grouping) as string;
    const groupB = getGroup(geneB, grouping) as string;

    const groupPart = meanByGroup[groupB] - meanByGroup[groupA];
    const groupTrace = tracesByGroup[groupB] - tracesByGroup[groupA];
    const groupAlphaPart = groupA > groupB ? -1 : groupB > groupA ? 1 : 0;
    const genePart = meanByGene[geneB] - meanByGene[geneA];
    const geneTrace = tracesByGene[geneB] - tracesByGene[geneA];
    const assayAlphaPart = getAssay(geneA) < getAssay(geneB) ? -0.01 : getAssay(geneB) < getAssay(geneA) ? 0.01 : 0;
    return (groupPart || groupTrace || groupAlphaPart) * 1000000000 + (genePart || geneTrace || assayAlphaPart);
  });

  let idx = 0;
  const geneIsFocused = focusedSamples && keyBy(getUniqueGenes(focusedSamples), g => g);
  return allGenes.reduce<Dictionary<number>>((acc, gene) => {
    if (!geneIsFocused || geneIsFocused[gene]) {
      acc[gene] = idx++;
    }
    return acc;
  }, {});
}

function getUniqueGenes(samples: FullSample[]) {
  const allGenes = samples.reduce<string[]>((acc, sample) => {
    sample.abundances.forEach(r => {
      acc.push(r.gene);
    });
    return acc;
  }, []);
  const genes = uniq(allGenes);
  if (allGenes.length !== genes.length * samples.length) {
    throw Error('All genes not analysed for all samples');
  }
  return genes;
}

function getAbundancesBySBG(samples: FullSample[]): AbundancesByUBG {
  return samples.reduce<AbundancesByUBG>((acc, sample) => {
    const UID = getSampleUID(sample);
    const byBG = acc[UID] || {};
    if (byBG[sample.bioRep]) {
      throw Error(`Duplicate bio sample ${getBioNumber(sample)}`);
    }
    byBG[sample.bioRep] = sample.abundances.reduce<Dictionary<FullAbundance>>((acc2, r) => {
      if (get(acc2, r.gene, undefined)) {
        throw Error(`Duplicate abundance for sample ${getBioNumber(sample)} ${r.gene}`);
      }
      acc2[r.gene] = r;
      return acc2;
    }, {});
    acc[UID] = byBG;
    return acc;
  }, {});
}

function calcMeanByGroup(abundanceByUBG: AbundancesByUBG, grouping: GeneGrouping, traces: boolean) {
  const valuesByGroup: Dictionary<number[]> = buildValuesBy(abundanceByUBG, gene => getGroup(gene, grouping), traces);
  return mapValues(valuesByGroup, mean);
}

export function calcMeanByGene(abundanceBySBG: AbundancesByUBG, traces: boolean) {
  const valuesByGene: Dictionary<number[]> = buildValuesBy(abundanceBySBG, gene => gene, traces);
  return mapValues(valuesByGene, mean);
}

function buildValuesBy(abundanceByUBG: AbundancesByUBG, by: (gene: string) => string | undefined, traces = false) {
  const valuesBy: Dictionary<number[]> = {};
  Object.values(abundanceByUBG).forEach((abundanceByBG: AbundanceByBG | undefined) => {
    if (!abundanceByBG) {
      throw Error('Unexpected undefined abundanceByBG');
    }
    Object.values(abundanceByBG).forEach((abundanceByG?: Dictionary<Abundance>) => {
      if (!abundanceByG) {
        throw Error('Unexpected undefined abundanceByG');
      }
      Object.values(abundanceByG).forEach(r => {
        const b = by(r.gene) as string;
        if (!get(valuesBy, b, undefined)) {
          valuesBy[b] = [];
        }
        valuesBy[b].push(traces ? +r.traces : r.relative || 0);
      });
    });
  });
  return valuesBy;
}
