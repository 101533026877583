import { RawSample } from '@resistapp/common/types';
import { isNil } from 'lodash';

export function calculateNormalizationFactors(sample: RawSample) {
  const volumeNormalisationFactor = getVolumeNormalisationFactor(sample);
  const flowNormlisationFactor = getFlowNormlisationFactor(sample, volumeNormalisationFactor);
  const suspendedSolidsNormlisationFactor = getSuspendedSolidsNormlisationFactor(sample, volumeNormalisationFactor);
  const bodNormlisationFactor = getBodNormlisationFactor(sample, volumeNormalisationFactor);

  return {
    volumeNormalisationFactor,
    flowNormlisationFactor,
    suspendedSolidsNormlisationFactor,
    bodNormlisationFactor,
  };
}

function getVolumeNormalisationFactor(sample: RawSample) {
  // dilutedDnaConcentration is left unfilled if it's the same as elutedDnaConcentration
  const dilutedDnaConcentration = sample.dilutedDnaConcentration ?? sample.elutedDnaConcentration;
  const normalisationFactor =
    isNil(dilutedDnaConcentration) ||
    isNil(sample.elutedDnaConcentration) ||
    isNil(sample.elutedDnaVolume) ||
    isNil(sample.filteredVolume)
      ? undefined
      : ((100 / dilutedDnaConcentration) * sample.elutedDnaConcentration * sample.elutedDnaVolume) /
        sample.filteredVolume;
  return normalisationFactor;
}

function getFlowNormlisationFactor(sample: RawSample, volumeNormalisationFactor: number | undefined) {
  return isNil(volumeNormalisationFactor) || isNil(sample.flowRate)
    ? undefined
    : volumeNormalisationFactor * sample.flowRate; // copies/L * L/h = copies/h
}

function getSuspendedSolidsNormlisationFactor(sample: RawSample, volumeNormalisationFactor: number | undefined) {
  return isNil(volumeNormalisationFactor) || !sample.suspendedSolids
    ? undefined
    : volumeNormalisationFactor / sample.suspendedSolids; // copies/L / mg/L = copies/mg
}

function getBodNormlisationFactor(sample: RawSample, volumeNormalisationFactor: number | undefined) {
  return isNil(volumeNormalisationFactor) || !sample.bod ? undefined : volumeNormalisationFactor / sample.bod; // copies/L / mg/L = copies/mg
}
