import styled from '@emotion/styled';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { GeneGrouping, GroupingOption, defaultGroupingOptions, groupingName, sixteenS } from '@resistapp/common/assays';
import { useEffect } from 'react';
import { ResistomapSelect } from '../../forms/resistomap-select';
import { theme } from '../../shared/theme';

interface Props {
  queryFilters: QueryFilters;
}

export function GeneLegendFilter(props: Props) {
  const { absoluteModeData } = useSampleDataContext();
  const geneGroups = absoluteModeData.onAndEnabled
    ? [...defaultGroupingOptions, { value: sixteenS as GeneGrouping, label: groupingName[sixteenS] }]
    : defaultGroupingOptions;
  const selectedGeneGroup = geneGroups.find(group => group.value === props.queryFilters.filters.selectedTargetGrouping);

  useEffect(() => {
    if (!selectedGeneGroup?.value) {
      props.queryFilters.setGrouping(defaultGroupingOptions[0].value);
    }
  }, [selectedGeneGroup, absoluteModeData.onAndEnabled, props.queryFilters]);

  return (
    <Container>
      <ResistomapSelect<GroupingOption, false>
        options={geneGroups}
        value={selectedGeneGroup}
        onChange={value => {
          if (!value) return;
          // @ts-expect-error TODO: Fix this
          props.queryFilters.toggleGeneGroup(value.value, false);
          props.queryFilters.setGrouping(value.value);
        }}
        size="md"
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${theme.colors.neutral50};
`;
