import { Box } from '@chakra-ui/react';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { getLowerCaseMatches, QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import {
  allEnvGroupTypes,
  AllProjectEnvironmentTypesGroup,
  EnvironmentTypeGroup,
  getComparableEnvironmentGroups,
  getFriendlyComparableEnvGroupName,
} from '@resistapp/common/comparable-env-groups';
import { FullProject } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { InputActionMeta } from 'chakra-react-select';
import { useEffect, useMemo, useState } from 'react';
import { ResistomapSelect, resistomapSelectClassNamePrefix } from '../../forms/resistomap-select';
import { theme } from '../../shared/theme';

interface Props {
  queryFilters: QueryFilters;
  sampling: FullProject;
}

export function SampleAndTypeSearch(props: Props) {
  const [inputValue, setInputValue] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectionLabel, setSelectionLabel] = useState('');
  const environments = sortUniqEnvironments(props.sampling.samplesByUID);
  const noSamplesWithPhrase = 'No samples with phrase';

  // Note hacky extension of Select to a simple search field
  // - the label has to match the input to be shown
  useEffect(() => {
    if (inputValue) {
      const envNames = environments.map(env => env.name);
      const matchingEnvNames = getLowerCaseMatches(inputValue, envNames);
      const cnt = matchingEnvNames.length;

      const label =
        cnt > 1
          ? `Select ${cnt} samples with phrase '${inputValue.toLowerCase()}'`
          : cnt === 1
            ? `Select '${matchingEnvNames[0]}'`
            : `${noSamplesWithPhrase} '${inputValue.toLowerCase()}'`;

      setSelectionLabel(label);
    } else {
      setSelectionLabel(menuOpen ? 'Select all (or type to seach)' : 'All samples');
    }
  }, [inputValue, environments, menuOpen]);

  const comparableEnvGroups = useMemo(
    () => getComparableEnvironmentGroups(environments, undefined, true),
    [environments],
  );
  const allOrSelectedOption: Option = {
    label: selectionLabel,
    value: AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS,
  };
  const comparableGroupOptions: Option[] = comparableEnvGroups.map(group => ({
    label: getFriendlyComparableEnvGroupName(group.type),
    value: group.type,
  }));
  const options: Option[] = selectionLabel.startsWith(noSamplesWithPhrase)
    ? [...comparableGroupOptions, allOrSelectedOption]
    : [allOrSelectedOption, ...comparableGroupOptions];

  return (
    <Box style={{ width: '100%', backgroundColor: theme.colors.neutral50 }} className="test_sample-search">
      <ResistomapSelect<Option, false>
        options={options}
        size={'md'}
        isClearable={false}
        isDisabled={false}
        value={getSelectedOption(options, props.queryFilters.filters)}
        onChange={option => {
          if (option?.value !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS) {
            props.queryFilters.setEnvironmentTypeGroup(
              option?.value
                ? (option.value satisfies EnvironmentTypeGroup)
                : (AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS satisfies EnvironmentTypeGroup),
              true,
            );
          } else {
            const envNames = environments.map(env => env.name);
            const matchingEnvNames = inputValue ? getLowerCaseMatches(inputValue, envNames) : [];
            if (matchingEnvNames.length) {
              props.queryFilters.setMatchingEnvironmentsAcrossTypes(inputValue);
            } else {
              props.queryFilters.setEnvironmentTypeGroup(
                AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS,
                true,
              );
            }
          }
          setInputValue('');
        }}
        classNamePrefix={resistomapSelectClassNamePrefix}
        onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
          if (actionMeta.action === 'input-change') {
            setInputValue(newValue);
          }
        }}
        onMenuOpen={() => {
          setMenuOpen(true);
        }}
        onMenuClose={() => {
          setMenuOpen(false);
        }}
        isOptionDisabled={(option, _selectValue) => option.label.startsWith(noSamplesWithPhrase)}
      />
    </Box>
  );
}

type Option = {
  label: string;
  value: EnvironmentTypeGroup;
};

function getSelectedOption(options: Option[], filters: Filters): Option {
  const envTypeOptions = options.filter(opt => allEnvGroupTypes.includes(opt.value as EnvironmentTypeGroup));
  return envTypeOptions.length === 1
    ? options[0]
    : options.find(opt => filters.selectedEnvironmentTypeGroup === opt.value) || options[0];
}
