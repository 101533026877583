import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { DEFAULT_GENE_GROUPING } from '@resistapp/client/data-utils/gene-groups';
import { GeneGrouping, Target, Targets, allGeneGroups, sixteenS } from '@resistapp/common/assays';
import {
  AllProjectEnvironmentTypesGroup,
  EnvironmentTypeGroup,
  allEnvGroupTypes,
  filterGroupEnvironments,
  getComparableEnvironmentGroups,
} from '@resistapp/common/comparable-env-groups';
import {
  DEFAULT_END_INTERVAL,
  DEFAULT_START_INTERVAL,
  ensureValidUtcMidnightOrDefault,
} from '@resistapp/common/friendly';
import { Environment, FullProject, FullSamplesByUID, NormalisationMode } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import {
  AbunanceSelection,
  Filters,
  filterSamplesAndAbundances,
  getNextToggledStrings,
} from '../../data-utils/filter-data/filter';
import {
  QueryParams,
  getEnvironmentTypeParam,
  getEnvironmentsParams,
  getGeneGroupingParams,
  getGeneGroupsParams,
  getNormalisationModeParam,
  mutateEnvironmentRelatedSearchParams,
  mutateSearchParamsWithSelection,
} from '../../utils/url-manipulation';
import { constructFocusInfo } from './use-query-filters-utils';

export interface UseQueryFilters {
  focusSamplesByUID: (samplesByUID: FullSamplesByUID) => FullSamplesByUID;
  queryFilters: QueryFilters;
}

export interface QueryFilters {
  setGrouping: (grouping: GeneGrouping) => void;
  setEnvironmentTypeGroup: (type: EnvironmentTypeGroup, replaceAndClearEnvs: boolean) => void;
  toggleEnvironment: (name: string | string[] | undefined, removeOldSelections: boolean) => void;
  setMatchingEnvironmentsAcrossTypes: (search: string) => void;
  hasFocus: boolean;
  toggleGeneGroup: (
    group: Target | Target[],
    removeOldSelections: boolean,
    selectedGroupingInCaseStateHasNotUpdated?: GeneGrouping,
  ) => void;
  filters: Filters;
  focusInfo: string;
  setAbundanceMode: (mode: AbunanceSelection) => void;
  setNormalisationMode: (value: NormalisationMode) => void;
  resetFilters: () => void;
  setInterval: (start: Date | string, end: Date | string) => void;
}

const defaults = {
  abunanceSelection: AbunanceSelection.ANALYSED,
  geneGroups: allGeneGroups[DEFAULT_GENE_GROUPING],
};

function useFiltersInternal() {
  const [abundanceMode, setAbundanceMode] = useLocalStorageState(LocalStorageId.abundanceMode, {
    defaultValue: defaults.abunanceSelection,
  });
  const [searchParams] = useSearchParams();
  const start = ensureValidUtcMidnightOrDefault(searchParams.get(QueryParams.START), DEFAULT_START_INTERVAL);
  const end = ensureValidUtcMidnightOrDefault(searchParams.get(QueryParams.END), DEFAULT_END_INTERVAL);

  const [allProjectEnvironments, setAllProjectEnvironments] = useState<Environment[]>([]);
  const [allProjectEnvironmentNames, setAllProjectEnvironmentNames] = useState<string[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryGeneGroups = useMemo(() => getGeneGroupsParams(searchParams), [searchParams.get(QueryParams.GENE_GROUPS)]);
  const queryGeneGrouping = getGeneGroupingParams(searchParams);

  const envNamesHaveComma = allProjectEnvironmentNames.join('').includes(','); // For url backwards compatibility: legacy query param array separator support
  const queryEnvironmentNames = useMemo(
    () => getEnvironmentsParams(searchParams, envNamesHaveComma),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams.get(QueryParams.ENVIRONMENTS), envNamesHaveComma],
  );
  const queryEnvironmentGroup =
    getEnvironmentTypeParam(searchParams) ?? AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;

  const queryNormalisationMode = getNormalisationModeParam(searchParams);
  useEffect(() => {
    // Convert legacy value
    if ((abundanceMode as unknown) === 'ANALYZED') {
      setAbundanceMode(AbunanceSelection.ANALYSED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abundanceMode]);

  const environmentsImplicitlySelectedWithEnvTypeGroupSelection = useMemo(
    () => filterGroupEnvironments(allProjectEnvironments, queryEnvironmentGroup),
    [allProjectEnvironments, queryEnvironmentGroup],
  );

  // A convenience object containing the active query params
  // or all values when multi-select query params are not set
  const filters = useMemo<Filters>(
    () => ({
      selectedEnvironmentTypeGroup: queryEnvironmentGroup,
      selectedEnvironmentNamesOrdered: queryEnvironmentNames.length
        ? queryEnvironmentNames
        : environmentsImplicitlySelectedWithEnvTypeGroupSelection.map(e => e.name),
      selectedTargetGrouping: queryGeneGrouping,
      selectedTargets: (queryGeneGroups.length ? queryGeneGroups : allGeneGroups[queryGeneGrouping]) as Targets[],
      interval: {
        start,
        end,
      },
      normalisationMode: queryNormalisationMode,
      abundances: abundanceMode,
    }),
    [
      queryEnvironmentGroup,
      environmentsImplicitlySelectedWithEnvTypeGroupSelection,
      queryEnvironmentNames,
      queryGeneGrouping,
      queryGeneGroups,
      start,
      end,
      abundanceMode,
      queryNormalisationMode,
    ],
  );

  return {
    filters,
    setAllProjectEnvironments,
    setAllProjectEnvironmentNames,
    allProjectEnvironments,
    environmentsImplicitlySelectedWithEnvTypeGroupSelection,
    queryEnvironmentNames,
    queryGeneGroups,
    queryGeneGrouping,
    setAbundanceMode,
    allProjectEnvironmentNames,
  };
}

export function useFilters(): Filters {
  return useFiltersInternal().filters;
}

export function useQueryFilters(project: FullProject | undefined, absoluteModeOn?: boolean): UseQueryFilters {
  const {
    filters,
    setAllProjectEnvironments,
    setAllProjectEnvironmentNames,
    allProjectEnvironments,
    environmentsImplicitlySelectedWithEnvTypeGroupSelection,
    queryEnvironmentNames,
    queryGeneGroups,
    queryGeneGrouping,
    setAbundanceMode,
    allProjectEnvironmentNames,
  } = useFiltersInternal();
  const location = useLocation();
  const [allProjectEnvironmentTypes, setAllProjectEnvironmentTypes] = useState<EnvironmentTypeGroup[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const oldSamplingRef = useRef<number | null>(null);
  useEffect(() => {
    if (project && oldSamplingRef.current !== project.id) {
      oldSamplingRef.current = project.id;
      const projectEnvironments = sortUniqEnvironments(project.samplesByUID);
      const allEnvironmentNames = projectEnvironments.map(env => env.name);

      // Get both regular env types and comparable groups
      const comparableGroups = getComparableEnvironmentGroups(projectEnvironments, undefined);
      const allProjectEnvironmentTypesToBeSet = uniq([
        ...comparableGroups.map(g => g.type),
        ...projectEnvironments.map(env => env.type),
      ]);

      setAllProjectEnvironmentTypes(allProjectEnvironmentTypesToBeSet);
      setAllProjectEnvironments(projectEnvironments);
      setAllProjectEnvironmentNames(allEnvironmentNames);
    }
  }, [project, setAllProjectEnvironmentNames, setAllProjectEnvironments]); // searchParams, environmentsImplicitlySelectedWithEnvTypeGroupSelection, queryEnvironmentNames,

  useEffect(() => {
    // This should never be empty after data is loaded, and triggering this effect before
    // data is loaded would clear the environment names from query params
    if (!environmentsImplicitlySelectedWithEnvTypeGroupSelection.length) {
      return;
    }
    const allEnvTypeGroupEnvNames = allProjectEnvironments.map(e => e.name);
    const initialEnvironmentNames = queryEnvironmentNames.length
      ? getNextToggledStrings(queryEnvironmentNames, allEnvTypeGroupEnvNames, allEnvTypeGroupEnvNames, true)
      : allProjectEnvironments.map(e => e.name);

    mutateSearchParamsWithSelection(
      QueryParams.ENVIRONMENTS,
      searchParams,
      allEnvTypeGroupEnvNames,
      initialEnvironmentNames,
      true,
    );
  }, [
    searchParams,
    queryEnvironmentNames,
    environmentsImplicitlySelectedWithEnvTypeGroupSelection,
    allProjectEnvironments,
  ]);

  // We activate this, when the project changes. This should null the selections.
  useEffect(() => {
    const groups = queryGeneGroups.length
      ? getNextToggledStrings(queryGeneGroups, allGeneGroups[queryGeneGrouping], allGeneGroups[queryGeneGrouping], true)
      : allGeneGroups[queryGeneGrouping];

    mutateSearchParamsWithSelection(
      QueryParams.GENE_GROUPS,
      searchParams,
      allGeneGroups[queryGeneGrouping],
      groups,
      true,
    );
    setSearchParams(searchParams, { replace: true });

    // Only triggered on project, abs. mode or grouping change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryGeneGrouping, filters.abundances, location.pathname]);

  const setGrouping = useCallback(
    (value: GeneGrouping) => {
      searchParams.set(QueryParams.GENE_GROUP_GROUPING, value);
      searchParams.delete(QueryParams.GENE_GROUPS);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );
  useEffect(() => {
    if (!absoluteModeOn && queryGeneGrouping === sixteenS) {
      setGrouping('target');
    }
  }, [absoluteModeOn, queryGeneGrouping, setGrouping]);

  const updateEnvTypeGroupSearchParams = useCallback(
    (newGroup: EnvironmentTypeGroup, replaceAndClearEnvs: boolean) => {
      const validGroup = allEnvGroupTypes.includes(newGroup)
        ? newGroup
        : AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;
      mutateEnvironmentRelatedSearchParams(searchParams, validGroup, replaceAndClearEnvs);
      setSearchParams(searchParams, { replace: replaceAndClearEnvs });
    },
    [searchParams, setSearchParams],
  );

  const toggleEnvironment = useCallback(
    (name: string | string[] | undefined, removeOldSelections: boolean) => {
      const selectedEnvironmentNames = allProjectEnvironments.map(e => e.name);
      const next = name
        ? getNextToggledStrings(
            name,
            filters.selectedEnvironmentNamesOrdered,
            selectedEnvironmentNames,
            removeOldSelections,
          )
        : selectedEnvironmentNames;

      mutateSearchParamsWithSelection(
        QueryParams.ENVIRONMENTS,
        searchParams,
        selectedEnvironmentNames,
        next,
        removeOldSelections,
      );

      setSearchParams(searchParams, { replace: true });

      // Scroll to the map, when the selected environment changes
      const mapElement = document.querySelector('.mapboxgl-map');
      if (mapElement) {
        mapElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    [searchParams, setSearchParams, filters.selectedEnvironmentNamesOrdered, allProjectEnvironments],
  );

  const toggleGeneGroup = useCallback(
    (
      group: Target | Target[],
      removeOldSelections: boolean,
      selectedGroupingInCaseStateHasNotUpdated?: GeneGrouping,
    ) => {
      const realSelectedGrouping = selectedGroupingInCaseStateHasNotUpdated ?? queryGeneGrouping;
      const next = getNextToggledStrings<Target>(
        group,
        filters.selectedTargets,
        allGeneGroups[realSelectedGrouping] as Target[],
        removeOldSelections,
      );
      mutateSearchParamsWithSelection(
        QueryParams.GENE_GROUPS,
        searchParams,
        allGeneGroups[realSelectedGrouping],
        next,
        removeOldSelections,
      );
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams, queryGeneGrouping, filters],
  );

  return {
    focusSamplesByUID: (samplesByUID: FullSamplesByUID) => filterSamplesAndAbundances(samplesByUID, filters),
    queryFilters: {
      focusInfo: constructFocusInfo(filters, allProjectEnvironmentNames),
      toggleGeneGroup,
      setGrouping,
      filters,
      setAbundanceMode,
      setMatchingEnvironmentsAcrossTypes: (search: string) => {
        const newlyMatchedEnvNames = getLowerCaseMatches(search, allProjectEnvironmentNames);
        if (!search || !newlyMatchedEnvNames.length) {
          return;
        }
        const newlyMatchedEnvs = allProjectEnvironments.filter(e => newlyMatchedEnvNames.includes(e.name));
        const newlyMatchedEnvGroups = getComparableEnvironmentGroups(newlyMatchedEnvs, undefined);

        const allNewlyMatchedEnvTypes = uniq(newlyMatchedEnvs.map(e => e.type));
        updateEnvTypeGroupSearchParams(newlyMatchedEnvGroups[0].type, false);
        const allEnvNamesOfTheTheSelectedTypes = allProjectEnvironments
          .filter(e => allNewlyMatchedEnvTypes.includes(e.type))
          .map(e => e.name);

        const next = getNextToggledStrings(
          newlyMatchedEnvNames,
          allProjectEnvironmentNames,
          allEnvNamesOfTheTheSelectedTypes,
          true,
        );
        mutateSearchParamsWithSelection(
          QueryParams.ENVIRONMENTS,
          searchParams,
          allEnvNamesOfTheTheSelectedTypes,
          next,
          true,
        );
        setSearchParams(searchParams, { replace: true });
      },
      toggleEnvironment,
      setEnvironmentTypeGroup: updateEnvTypeGroupSearchParams,
      setNormalisationMode: (value: NormalisationMode) => {
        searchParams.set(QueryParams.NORMALISATION_MODE, value);
        setSearchParams(searchParams, { replace: true });
      },
      hasFocus:
        queryGeneGrouping !== 'target' ||
        filters.selectedTargets.length < allGeneGroups[queryGeneGrouping].length ||
        // NOTE: this doesn't check whether all project envs are within a selected custom group, but just conservatively assumes there may be focus
        // TODO: fix if it turns out to be an issue
        (filters.selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS &&
          !(
            allProjectEnvironmentTypes.length === 1 &&
            filters.selectedEnvironmentTypeGroup === allProjectEnvironmentTypes[0]
          )) ||
        (filters.selectedEnvironmentNamesOrdered.length &&
          filters.selectedEnvironmentNamesOrdered.length < allProjectEnvironmentNames.length) ||
        filters.interval.start.getTime() !== DEFAULT_START_INTERVAL.getTime() ||
        filters.interval.end.getTime() !== DEFAULT_END_INTERVAL.getTime(),
      resetFilters: () => {
        setSearchParams({}, { replace: true });
        setAbundanceMode(defaults.abunanceSelection);
      },
      setInterval: (requestedStart: Date | string, requestedEnd: Date | string) => {
        const snappedStart = ensureValidUtcMidnightOrDefault(requestedStart, DEFAULT_START_INTERVAL);

        if (snappedStart.getTime() === DEFAULT_START_INTERVAL.getTime()) {
          searchParams.delete('start');
        } else {
          searchParams.set('start', snappedStart.toISOString().slice(0, 10));
        }
        const snappedEnd = ensureValidUtcMidnightOrDefault(requestedEnd, DEFAULT_END_INTERVAL);
        if (snappedEnd.getTime() === DEFAULT_END_INTERVAL.getTime()) {
          searchParams.delete('end');
        } else {
          searchParams.set('end', snappedEnd.toISOString().slice(0, 10));
        }
        setSearchParams(searchParams, { replace: true });
      },
    },
  };
}

export function getLowerCaseMatches(searchString: string, stringsToMatchTo: string[]) {
  const lowerSearchString = searchString.toLowerCase();
  return stringsToMatchTo.filter(str => str.toLowerCase().includes(lowerSearchString));
}
