import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { ResistanceLevel } from '@resistapp/common/statistics/resistance-index';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { isNil } from 'lodash';
import { DownRightIcon, RightIcon, UpRightIcon } from '../icons/diaconal-arrow-icons';
import { OverviewMetricLabel, getChangeIndicatorColor } from '../shared/overview-metric-label';
import { theme } from '../shared/theme';

interface Datum {
  level: ResistanceLevel | null | undefined;
  metric: number | null;
  label?: string;
}

interface ResistanceBoxesProps {
  beforeOrChange?: Datum;
  after?: Datum;
}

export function ResistanceBoxes({ beforeOrChange, after }: ResistanceBoxesProps) {
  const { metricMode, processMode, activeChartUnit } = useOverviewContext();

  const changeDirection = getChangeDirection(beforeOrChange, after, metricMode);
  const renderBeforeAsWhite = processMode === ProcessMode.AFTER;
  const renderAfterAsWhite = processMode === ProcessMode.BEFORE;
  const showBeforeArrow = renderBeforeAsWhite && after !== undefined && changeDirection !== undefined;
  const showAfterArrow = processMode === ProcessMode.BEFORE && after !== undefined && changeDirection !== undefined;

  return (
    <Flex gap={theme.spacing[4]}>
      {!isNil(beforeOrChange?.metric) && (
        <Flex flexDirection="column" width="100px">
          <OverviewMetricLabel
            id="influentLevelContainer"
            level={beforeOrChange.level ?? null}
            metricMode={metricMode}
            metric={beforeOrChange.metric}
            style={{
              backgroundColor: renderBeforeAsWhite ? 'white' : undefined,
              color: renderBeforeAsWhite ? theme.colors.neutral500 : undefined,
              position: 'relative',
            }}
            activeChartUnit={activeChartUnit}
          >
            {showBeforeArrow && (
              <ArrowContainer right>
                {changeDirection < 0 ? (
                  <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                ) : changeDirection > 0 ? (
                  <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                ) : (
                  <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                )}
              </ArrowContainer>
            )}
          </OverviewMetricLabel>
          {beforeOrChange.label && <FluentsTextContainer>{beforeOrChange.label}</FluentsTextContainer>}
        </Flex>
      )}
      {after && after.metric !== null && metricMode !== MetricMode.REDUCTION && (
        <Flex flexDirection="column" width="100px">
          <OverviewMetricLabel
            id="effluentLevelContainer"
            level={after.level ?? null}
            metricMode={metricMode}
            metric={after.metric}
            style={{
              backgroundColor: renderAfterAsWhite ? 'white' : undefined,
              color: renderAfterAsWhite ? theme.colors.neutral500 : undefined,
            }}
            activeChartUnit={activeChartUnit}
          >
            {showAfterArrow && (
              <ArrowContainer>
                {changeDirection < 0 ? (
                  <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                ) : changeDirection > 0 ? (
                  <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                ) : (
                  <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                )}
              </ArrowContainer>
            )}
          </OverviewMetricLabel>
          {after.label && <FluentsTextContainer>{after.label}</FluentsTextContainer>}
        </Flex>
      )}
    </Flex>
  );
}

function getChangeDirection(
  firstLevel: Datum | undefined,
  secondLevel: Datum | undefined,
  metricMode: MetricMode,
): -1 | 1 | 0 | undefined {
  const roundedBeforeMetric = !isNil(firstLevel?.metric)
    ? Number(firstLevel.metric.toFixed(metricMode === MetricMode.ARGI || metricMode === MetricMode.REDUCTION ? 1 : 0))
    : null;
  const roundedAfterMetric = !isNil(secondLevel?.metric)
    ? Number(secondLevel.metric.toFixed(metricMode === MetricMode.ARGI || metricMode === MetricMode.REDUCTION ? 1 : 0))
    : null;
  return isNil(roundedAfterMetric) || isNil(roundedBeforeMetric) || metricMode === MetricMode.REDUCTION
    ? undefined
    : roundedAfterMetric === roundedBeforeMetric
      ? 0
      : roundedAfterMetric > roundedBeforeMetric
        ? 1
        : -1;
}

const FluentsTextContainer = styled.div`
  color: ${theme.colors.neutral500};
  text-align: center;
  font-size: ${theme.fontSize.extraSmall};
  font-weight: ${theme.fontWeight.heavy};
`;

const ArrowContainer = styled.div<{ right?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => (props.right ? 'right: 10px;' : 'left: 8px;')}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
`;
