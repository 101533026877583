import styled from '@emotion/styled';
import { resistanceLevelMetadata } from '@resistapp/client/data-utils/resistance-level';
import { ResistanceLevel } from '@resistapp/common/statistics/resistance-index';

import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { theme } from '../../shared/theme';
import { BaseMapLegend } from './base-map-legend';

export function ResistanceIndexMapLegend({ timeLegend }: { timeLegend: React.ReactNode | string }) {
  const { activeOverviewConfiguration } = useOverviewContext();
  return (
    <BaseMapLegend
      timeLegend={timeLegend}
      indicators={
        <>
          <MapLegendIndicator.low>{resistanceLevelMetadata[ResistanceLevel.low].shortTitle}</MapLegendIndicator.low>
          <MapLegendIndicator.moderate>
            {resistanceLevelMetadata[ResistanceLevel.moderate].shortTitle}
          </MapLegendIndicator.moderate>
          <MapLegendIndicator.high>{resistanceLevelMetadata[ResistanceLevel.high].shortTitle}</MapLegendIndicator.high>
        </>
      }
      indexRange={activeOverviewConfiguration.mapLegendTicks?.map(e => <div key={e}>{e}</div>)}
    />
  );
}

const baseIndicator = `
  height: 20px;
  border-radius: ${theme.borders.radius.small};
  padding-left: ${theme.spacing[2]};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.heavy};
  text-transform: uppercase;
`;

const MapLegendIndicator = {
  // The width: 41 / 39 comes from aligning with the index-numbers: 0.0, 1.0, 2.0... Those do not align with the divs otherwise 100%
  low: styled.div`
    width: 148px;
    background-color: ${theme.colors.levelLow};
    color: ${resistanceLevelMetadata.low.textColor};
    ${baseIndicator}
  `,
  moderate: styled.div`
    width: 135px;
    background-color: ${theme.colors.levelMod};
    color: ${resistanceLevelMetadata.moderate.textColor};
    ${baseIndicator}
  `,
  high: styled.div`
    width: 79px;
    background-color: ${theme.colors.levelHigh};
    color: ${resistanceLevelMetadata.high.textColor};
    ${baseIndicator}
  `,
};
