import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { AbunanceSelection } from '@resistapp/client/data-utils/filter-data/filter';
import { NormalisationMode } from '@resistapp/common/types';
import { TRACES_VAL } from '@resistapp/common/utils';

const QNT_COLORS = ['#550b1d', '#933b41', '#cc7878', '#dab9b7', '#cfd3ed', '#d6e7f2'];
const TRACE_COLOR = '#f2f2f2';
const NOT_DETECTED_COLOR = 'white';
export const QUANTIFIED_ONLY_COLORS = [...QNT_COLORS, NOT_DETECTED_COLOR];
export const ALL_COLORS = [...QNT_COLORS, TRACE_COLOR, NOT_DETECTED_COLOR];

const THRESHOLDS = {
  [NormalisationMode.SIXTEEN_S]: ['1', '0.1', '0.01', '1e-3', '1e-4', '1e-5'],
  [NormalisationMode.TEN_UL_DILUTED_DNA]: ['1e7', '1e6', '1e5', '1e4', '1e3', '1e2'],
  [NormalisationMode.LITRE]: ['1e9', '1e8', '1e7', '1e6', '1e5', '1e4'],
  [NormalisationMode.HOUR]: ['1e12', '1e11', '1e10', '1e9', '1e8', '1e7'],
  [NormalisationMode.MG_SS]: ['1e10', '1e9', '1e8', '1e7', '1e6', '1e5'],
  [NormalisationMode.MG_BOD]: ['1e10', '1e9', '1e8', '1e7', '1e6', '1e5'],
} as const;

const TRACES = 'traces';
const NOT_DETECTED = 'not detected';

function getBins(mode: NormalisationMode): string[] {
  const thresholds = THRESHOLDS[mode];
  const bins = thresholds.map(
    (t, i) => `≥ ${t}` + (mode === NormalisationMode.SIXTEEN_S && i === thresholds.length - 1 ? ' (LOD)' : ''),
  );
  return bins;
}

export function getBinLabels(normalisationMode: NormalisationMode, abundanceMode: AbunanceSelection): string[] {
  const bins = getBins(normalisationMode);
  if (abundanceMode === AbunanceSelection.QUANTIFIED_ONLY) {
    return [...bins, NOT_DETECTED];
  }
  return [...bins, TRACES, NOT_DETECTED];
}

export const WORLDMAP_BINS = [...getBins(NormalisationMode.SIXTEEN_S), 'no data'];
export const WORLDMAP_COLORS = [...QNT_COLORS, oldTheme.faintGray];

export function binColor(
  val: number | undefined | null,
  normalisationMode: NormalisationMode,
  isWorldmap = false,
): string {
  const colors = isWorldmap ? WORLDMAP_COLORS : ALL_COLORS;
  const thresholds = THRESHOLDS[normalisationMode];

  if (val === undefined || val === null) {
    return colors[colors.length - 1];
  }
  if (val === TRACES_VAL) {
    return colors[colors.length - 2];
  }

  for (let i = 0; i < thresholds.length; i++) {
    if (val >= +thresholds[i]) {
      return colors[i];
    }
  }
  return colors[colors.length - 1];
}
