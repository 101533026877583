import { Box } from '@chakra-ui/react';
import { getMarkerSize, ProcessMarker } from '@resistapp/client/components/map/markers/process-marker';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getMetricAndLevel } from '@resistapp/client/utils/metric-utils';
import { ensureUtcMonth, utcStartOfNextMonth } from '@resistapp/common/friendly';
import { getAllOriginalEnvironmentNames, MetricMode } from '@resistapp/common/types';
import { useTooltip } from '@visx/tooltip';
import { ScaleTime } from 'd3-scale';
import { isNil } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { chartLeftMargin, positioning } from './chart-styles';
import { getGradientScale, getResistanceIndexScale } from './scales';

export function useTrendchartTooltip(
  timeScale: ScaleTime<number | undefined, number | undefined> | undefined,
  shownAdminLevel: number | null | undefined,
  containerRef: React.RefObject<HTMLDivElement>,
) {
  const { queryFilters } = useSampleDataContext();
  const {
    selectedOrHoveredAreaOrSiteEnvId,
    setSelectedOrHoveredAreaOrSiteEnvId,
    trendData,
    metricMode,
    processMode,
    changeZoomedAdminArea,
    selectedSiteDatum,
    activeChartUnit,
    activeOverviewConfiguration,
  } = useOverviewContext();
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<OverviewDatum>();
  const envRef = useRef(selectedOrHoveredAreaOrSiteEnvId);

  const positionTooltip = useCallback(
    (datum: OverviewDatum) => {
      const [metric] = getMetricAndLevel(
        datum,
        queryFilters.filters.selectedTargets,
        metricMode,
        processMode,
        activeChartUnit,
      );

      if (isNil(metric)) {
        return;
      }
      if (!timeScale) {
        throw Error('Unexpectedly moved mouse without timescale');
      }

      const containerRect = containerRef.current?.getBoundingClientRect() || { height: 0 };
      const graphHeight = containerRect.height - positioning.margin.top - positioning.margin.bottom;
      const indexScale =
        metricMode === MetricMode.ARGI
          ? getResistanceIndexScale(graphHeight)
          : getGradientScale(graphHeight, [
              activeOverviewConfiguration.detailBarGraphMin,
              activeOverviewConfiguration.detailBarGraphMax(),
            ]);
      const shadowWidth = 2;
      const markerSize = getMarkerSize(datum, metricMode, processMode);
      const leftOffset = markerSize.width / 2 + shadowWidth - markerSize.chartOffset;
      const topOffset = markerSize.height - 2 * shadowWidth;
      const b = indexScale(metric);

      showTooltip({
        tooltipLeft: (timeScale(new Date(datum.date.toString())) ?? 0) + leftOffset,
        tooltipTop: b - topOffset,
        tooltipData: datum,
      });
    },
    [
      timeScale,
      showTooltip,
      containerRef,
      activeOverviewConfiguration,
      metricMode,
      processMode,
      activeChartUnit,
      queryFilters.filters.selectedTargets,
    ],
  );

  useEffect(() => {
    if (timeScale && trendData && envRef.current !== selectedOrHoveredAreaOrSiteEnvId) {
      envRef.current = selectedOrHoveredAreaOrSiteEnvId;
      const envData = trendData.find(data => data[0]?.environment.id === selectedOrHoveredAreaOrSiteEnvId);
      const lastDatum = envData?.length && envData[envData.length - 1];
      if (lastDatum) {
        positionTooltip(lastDatum);
      }
    }
  }, [positionTooltip, showTooltip, trendData, timeScale, selectedOrHoveredAreaOrSiteEnvId]);

  const mouseMoveHandler = (data: OverviewDatum[], event: React.MouseEvent<SVGElement>) => {
    const { offsetX } = event.nativeEvent;

    if (!timeScale) {
      throw Error('Unexpectedly moved mouse without timescale');
    }

    const closestDatum = data.reduce((prev, curr) => {
      const currentTimePoint = timeScale(new Date(curr.date));
      const previousTimePoint = timeScale(new Date(prev.date));
      if (isNil(currentTimePoint) || isNil(previousTimePoint)) {
        console.error(`Unexpectedly nill time`, typeof curr, curr, curr.date, typeof prev, prev, prev.date);
        return prev;
      }
      const currentXPoint = currentTimePoint + chartLeftMargin;
      const previousXPoint = previousTimePoint + chartLeftMargin;

      return Math.abs(currentXPoint - offsetX) < Math.abs(previousXPoint - offsetX) ? curr : prev;
    });

    positionTooltip(closestDatum);
    setSelectedOrHoveredAreaOrSiteEnvId(closestDatum.environment.id);
  };

  const mouseClickHandler = useCallback(
    (data: OverviewDatum) => {
      // If admin areas are active
      if (selectedSiteDatum) {
        const startOfMonth = ensureUtcMonth(data.date);
        const startOfNextMonth = utcStartOfNextMonth(startOfMonth);
        queryFilters.setInterval(startOfMonth, startOfNextMonth);
      } else if (shownAdminLevel) {
        const nextAdminLevel = Object.values(data.adminLevels ?? {}).find(d => Number(d.level) === shownAdminLevel);
        if (nextAdminLevel) {
          changeZoomedAdminArea(nextAdminLevel);
        }
      } else {
        const envName = data.environment.name;
        !isEnvironmentSelected(envName, queryFilters.filters.selectedEnvironmentNamesOrdered) &&
          queryFilters.toggleEnvironment(getAllOriginalEnvironmentNames(data.environment, data.environmentAfter), true);
      }
    },
    [queryFilters, shownAdminLevel, changeZoomedAdminArea, selectedSiteDatum],
  );

  const onMouseLeave = () => {
    hideTooltip();
  };

  // This is not needed, because we have mouseMoveHandler already
  // const onMouseEnter = () => {};

  const TooltipComponentForARGIMarker = useCallback(() => {
    if (!tooltipOpen || !tooltipData) {
      return null;
    }

    return (
      <Box position="absolute" top={tooltipTop} left={(tooltipLeft || 0) + 20} id="tooltip-component">
        <ProcessMarker
          overviewDatum={tooltipData}
          position="chart"
          onClick={() => {
            mouseClickHandler(tooltipData);
          }}
        />
      </Box>
    );
  }, [tooltipOpen, tooltipData, tooltipLeft, tooltipTop, mouseClickHandler]);
  const TooltipComponentForRiskScore = TooltipComponentForARGIMarker;
  const TooltipComponentForReduction = TooltipComponentForARGIMarker;

  return {
    mouseMoveHandler,
    mouseClickHandler,
    onMouseLeave,
    TooltipComponentForARGIMarker,
    TooltipComponentForRiskScore,
    TooltipComponentForReduction,
    hideTooltip,
  };
}

function isEnvironmentSelected(name: string, selectedEnvironmentNamesOrdered: string[]) {
  return name === selectedEnvironmentNamesOrdered[0] && selectedEnvironmentNamesOrdered.length === 1;
}
