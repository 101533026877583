import { calculateMarkerBounds } from '@resistapp/client/components/map/markers/marker-utils';
import {
  activateColoredRegions,
  deactivateColoredRegions,
  getBoundingBoxForEnvironment,
} from '@resistapp/client/components/map/overview-map-utils';
import {
  OverviewDatum,
  buildOverviewLineData,
  getComparableEnvGroupsForOverview,
  getSupportedSamplesWithConsistentAdminLevels,
} from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { useCountries } from '@resistapp/client/hooks/api';
import { QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { ApiError } from '@resistapp/client/utils/error';
import { MapSourceWithLevel, getMapSourceFromAdminLevel } from '@resistapp/client/utils/map-sources';
import {
  OverviewChartConfiguration,
  getActiveChartUnit,
  getOverviewConfiguration,
} from '@resistapp/client/utils/overview-chart-configurations';
import { Pooling, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import { Target, allGeneGroups, isAntibiotic } from '@resistapp/common/assays';
import {
  areSamplesAnalysedWithOneHealthPanel,
  countMetricGenes,
} from '@resistapp/common/assays-temp-96-gene-minor-targets';
import {
  AllProjectEnvironmentTypesGroup,
  ComparableEnvGroupType,
  EnvGroup,
  EnvironmentTypeGroup,
  getProcessMode,
} from '@resistapp/common/comparable-env-groups';
import { EnvironmentType } from '@resistapp/common/environment-types';
import {
  DEFAULT_END_INTERVAL,
  DEFAULT_START_INTERVAL,
  ensureUtcMonth,
  utcStartOfNextMonth,
} from '@resistapp/common/friendly';
import { calculateNormalizationFactors } from '@resistapp/common/normalisation-utils';
import { getAdminAreaKey } from '@resistapp/common/pool-samples';
import {
  AdminArea,
  AdminLevelKey,
  ChartUnit,
  FullProject,
  FullSample,
  MetricMode,
  NormalisationMode,
  ProcessMode,
  getAllOriginalEnvironmentNames,
} from '@resistapp/common/types';
import { Dictionary, chain, filter, isNil, keys, mapValues } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LngLatBoundsLike, LngLatLike, MapRef, ViewStateChangeEvent } from 'react-map-gl';
import { buildMapData, getUniqueMonths } from '../../data-utils/plot-data/build-map-data';
import { useSampleDataContext } from '../sample-data-context';
import { getCountriesFromSamples, getCountryAdminLevelIntersection } from './overview-context-utils';

export interface OverviewContextData {
  // ======== These properties are for overview in general (excluding map)
  hasPreparedData: boolean;
  trendData: OverviewDatum[][] | undefined; // environment time series (shortcut for trendDataByLevel[adminLevel])
  mapData: OverviewDatum[] | undefined; // latest data point from each envirnoment in the time series (shortcut for mapDataByLevel[adminLevel])
  data: FullProject | undefined;
  loading: boolean;
  error: Error | ApiError | null;
  selectedAntibiotic: Target | undefined;
  siteCount: number | undefined;
  selectedEnvironmentTypeGroup: EnvironmentTypeGroup;
  // NOTE => Used only in month-selector
  availableMonths: string[];
  setMonth: (date: Date | null) => void;
  selectedMonth: Date | null;
  processMode: ProcessMode;
  metricMode: MetricMode;
  activeOverviewConfiguration: OverviewChartConfiguration;
  availableEnvGroups: EnvGroup[] | undefined;
  // NOTE => Used only in overview-focus-header
  activeChartUnit: ChartUnit;
  // NOTE => Used only in overview-focus-header
  samplingPointsCount: number | undefined;
  // NOTE => Used only in overview-focus-header
  topLevelMapData: OverviewDatum | undefined;
  isOneHealthProject: boolean | undefined;
  selectedSiteDatum: OverviewDatum | undefined; // A single site selected for site view
  shownAdminLevel: number | null | undefined;
  previousAdminAreasLifo: AdminArea[];
  zoomedMapData: OverviewDatum | undefined;
  metricGeneCnt: number | undefined;
  // If nextAdminArea is null, it means we selected site, if it was undefined, it means we un-selected project
  changeZoomedAdminArea: (
    nextAdminArea: AdminArea | null | undefined,
    options?: { countryId?: string; previous?: boolean },
  ) => void;
  // NOTE => THESE SHOULD HANDLE ONLY HOVER OR SELECT, NOW IT'S VERY CONFUSING NAME / FUNCTIONALITY
  selectedOrHoveredAreaOrSiteEnvId: number | undefined; // Environment id (either area or site) that is currently hovered over, or selected in site view
  setSelectedOrHoveredAreaOrSiteEnvId: (id: number | undefined) => void;
  zoomAndCenter: (zoomOrBounds: { zoom: number; center: LngLatLike } | { bounds: LngLatBoundsLike }) => void;
  selectedCountry: string | undefined;
  supportedSamples: FullSample[] | undefined;
  trendDataByLevel: Dictionary<OverviewDatum[][]> | undefined;
  zoomedAdminArea: AdminArea | null | undefined;

  // ======== Pure map properties - to mapbox-map module
  zoom: number | undefined;
  handleZoom: (e: ViewStateChangeEvent) => void;

  // ======== mapContext properties
  // NOTE => CHANGE setMapRef TO MAPINSTANCE. We don't need the ref, just the mapInstance.
  setMapRef: (mapRef: MapRef) => void;
  mapRef: MapRef | null;
  notAvailableReason: string | null;
  activeMapSource: MapSourceWithLevel | undefined;
  // NOTE => This should be changed to "mapLoaded", using the mapbox own / more natural detection and useMemo or such, instead of react states.
  setMapLoaded: (mapLoaded: boolean) => void;
}

const GLOBAL_NAME = 'Global';
const GLOBAL_ENVIRONMENT_ID = -99999999;

interface ProviderProps {
  children: React.ReactNode;
  metricMode: MetricMode;
}

const OverviewContext = createContext<OverviewContextData | undefined>(undefined);

export function OverviewContextProvider({ children, metricMode }: ProviderProps) {
  const { data, queryFilters, loading, error } = useSampleDataContext();
  const { data: countryByAlpha3 } = useCountries();

  // REFS
  const mapDataUpdateInvocationCnt = useRef(0);
  const queryFiltersRef = useRef<QueryFilters['filters'] | null>(null);
  const metricModeRef = useRef<MetricMode | null>(null);
  const dataRef = useRef(data);

  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const [previousMapData, setPreviousMapData] = useState<OverviewDatum[] | null>(null);

  // INTERNAL STATE

  // Admin level and site selection state
  const [levelsWithZoomableAreas, setLevelsWithZoomableAreas] = useState<number[]>([]); // Ascending zoomable levels (from highest up level to the most detailed that still hides more than one site in some area)
  const [zoomedAdminArea, setZoomedAdminArea] = useState<AdminArea | null | undefined>(); // null means no admin level pooling (individual site samples)
  const [shownAdminLevel, setShownAdminLevel] = useState<number | null | undefined>(); // The rendered pooling level. This is the next level from zoomedAdminArea.level in levelsWithZoomableAreas, or null if there is not next level (individual sites are shown)
  const [previousAdminAreasLifo, setPreviousAdminAreasLifo] = useState<AdminArea[]>([]);
  const [selectedOrHoveredAreaOrSiteEnvId, setSelectedOrHoveredAreaOrSiteEnvId] = useState<number>();
  const [availableMonths, setAvailableMonths] = useState<string[]>([]);
  const [availableEnvGroups, setAvailableEnvGroups] = useState<EnvGroup[] | undefined>();
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();

  // Map state
  const [zoom, setZoom] = useState<number>();
  const [activeMapSource, setActiveMapSource] = useState<MapSourceWithLevel | undefined>();
  const [mapLoaded, setMapLoaded] = useState(false);

  // Data
  const [supportedSamples, setSupportedSamples] = useState<FullSample[] | undefined>();
  const [trendDataByLevel, setTrendDataByLevel] = useState<Dictionary<OverviewDatum[][]> | undefined>();
  const [mapDataByLevel, setMapDataByLevel] = useState<Dictionary<OverviewDatum[]> | undefined>();
  const [trendData, setTrendData] = useState<OverviewDatum[][] | undefined>();
  const [mapData, setMapData] = useState<OverviewDatum[] | undefined>();

  // Project metadata
  const [isOneHealthProject, setIsOneHealthProject] = useState<boolean>();
  const [metricGeneCnt, setMetricGeneCnt] = useState<number>();

  // Rendering helpers
  const [notAvailableReason, setNotAvailableReason] = useState<string | null>(null);

  // Derived state - env. type group and process mode selection
  const activeEnvGroup =
    availableEnvGroups?.find(group => group.type === queryFilters.filters.selectedEnvironmentTypeGroup) ||
    availableEnvGroups?.[0];
  const processMode = getProcessMode(activeEnvGroup?.type, metricMode, supportedSamples || []);

  const availableNormalisationModes = useMemo(
    () =>
      supportedSamples && activeEnvGroup?.envs
        ? determineAvailableNormalisationModes(
            supportedSamples.filter(s => activeEnvGroup.envs.find(e => e.id === s.environment.id)),
          )
        : [NormalisationMode.SIXTEEN_S],
    [supportedSamples, activeEnvGroup?.envs],
  );

  const activeChartUnit = getActiveChartUnit(metricMode, availableNormalisationModes);
  const activeOverviewConfiguration = getOverviewConfiguration(metricMode, activeChartUnit);

  // Derived state - antibiotic selection
  const selectedAntibiotic =
    queryFilters.filters.selectedTargets.length === 1 ? queryFilters.filters.selectedTargets[0] : undefined;

  // Derived state - site selection
  const uniqSelectedLocations = chain(supportedSamples)
    .filter(s => queryFilters.filters.selectedEnvironmentNamesOrdered.includes(s.environment.name))
    .map(s => `${s.lat},${s.lon}`)
    .uniq()
    .value();
  const singleSiteSelected =
    queryFilters.filters.selectedEnvironmentNamesOrdered.length === 1 ||
    (queryFilters.filters.selectedEnvironmentNamesOrdered.length === 2 && uniqSelectedLocations.length === 1);
  const selectedEnvNames =
    // We aren't zoomed in an andmin area or showing admin levels and there is only one site (selected 1 or 2 envs)
    zoomedAdminArea === null && shownAdminLevel === null && singleSiteSelected
      ? queryFilters.filters.selectedEnvironmentNamesOrdered
      : [];
  const selectedSiteDatum = selectedEnvNames.length
    ? mapData?.filter(d =>
        selectedEnvNames.some(
          envName =>
            'originalEnvironmentNames' in d.environment && d.environment.originalEnvironmentNames?.includes(envName),
        ),
      )[0]
    : undefined;
  const selectedSiteName = selectedSiteDatum?.environment.name;

  // QUERY PARAM AND ENV TYPE GROUP INITIALISATION
  const [queryParamsInitialised, setQueryParamsInitialised] = useState(false);

  const { filters, setEnvironmentTypeGroup, setGrouping, toggleGeneGroup } = queryFilters;
  useEffect(() => {
    const dataChanged = JSON.stringify(data) !== JSON.stringify(dataRef.current);
    const queryFiltersChanged = JSON.stringify(filters) !== JSON.stringify(queryFiltersRef.current);
    const metricModeChanged = metricMode !== metricModeRef.current;
    if (data && (dataChanged || queryFiltersChanged || metricModeChanged)) {
      dataRef.current = data;
      queryFiltersRef.current = filters;
      metricModeRef.current = metricMode;

      const freshSelectableEnvGroups =
        dataChanged || metricModeChanged
          ? getComparableEnvGroupsForOverview(data.samplesByUID, metricMode)
          : availableEnvGroups || [];
      if (dataChanged || metricModeChanged) {
        setAvailableEnvGroups(freshSelectableEnvGroups);
      }

      let paramsUpdated = false;
      if (
        metricModeChanged &&
        metricMode !== MetricMode.REDUCTION &&
        filters.selectedEnvironmentTypeGroup === ComparableEnvGroupType.WATER_TREATMENT &&
        freshSelectableEnvGroups.find(group => group.type === ComparableEnvGroupType.TREATED_WASTEWATER)
      ) {
        // HACK: Wastewater treatment env group does not currently show up correctly in ARGI or RISK mode
        // (and TREATED_WASTEWATER does not show up currectly in REDUCTION mode)
        // Consider, deprecating TREATED_WASTEWATER and fixing how WATER_TREATMENT is shown in ARGI and RISK mode.
        setEnvironmentTypeGroup(ComparableEnvGroupType.TREATED_WASTEWATER, false);
        paramsUpdated = true;
      } else if (
        metricModeChanged &&
        metricMode === MetricMode.REDUCTION &&
        filters.selectedEnvironmentTypeGroup === ComparableEnvGroupType.TREATED_WASTEWATER &&
        freshSelectableEnvGroups.find(group => group.type === ComparableEnvGroupType.WATER_TREATMENT)
      ) {
        // HACK: Wastewater treatment env group does not currently show up correctly in ARGI or RISK mode
        // (and TREATED_WASTEWATER does not show up currectly in REDUCTION mode)
        // Consider, deprecating TREATED_WASTEWATER and fixing how WATER_TREATMENT is shown in ARGI and RISK mode.
        setEnvironmentTypeGroup(ComparableEnvGroupType.WATER_TREATMENT, false);
        paramsUpdated = true;
      } else if (
        // Default is treated as automatic switching based on metric mode
        filters.selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS &&
        !freshSelectableEnvGroups.find(group => group.type === filters.selectedEnvironmentTypeGroup)
      ) {
        setEnvironmentTypeGroup(AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS, true);
        paramsUpdated = true;
      }
      if (filters.selectedTargetGrouping !== 'antibiotic') {
        setGrouping('antibiotic');
        paramsUpdated = true;
      }
      if (!filters.selectedTargets.length || filters.selectedTargets.find(target => !isAntibiotic(target))) {
        toggleGeneGroup(allGeneGroups.antibiotic, true, 'antibiotic');
        paramsUpdated = true;
      }

      // TODO initiate setPreviousAdminAreasLifo if landing on site view

      // If params were updated, wait for the next render cycle before setting queryParamsReady
      if (paramsUpdated) {
        setTimeout(() => {
          setQueryParamsInitialised(true);
        }, 0);
      } else if (!queryParamsInitialised) {
        setQueryParamsInitialised(true);
      }
    }
  }, [
    data,
    filters,
    toggleGeneGroup,
    setGrouping,
    setEnvironmentTypeGroup,
    queryParamsInitialised,
    metricMode,
    availableEnvGroups,
  ]);

  // PREPARE SUPPORTED SAMPLES WITH CONSISTENT ADMIN LEVELS
  // Overview does not use focussedByUID, because it needs to look at all the genes.
  // However, query parameters are still used to keep track of focused samples between overview, sample view and reseach view
  const samplesByUID = data?.samplesByUID;
  useEffect(() => {
    if (samplesByUID && availableEnvGroups) {
      const supportedSamplesWithNormalisedLevels = getSupportedSamplesWithConsistentAdminLevels(
        samplesByUID,
        availableEnvGroups,
      );
      setSupportedSamples(supportedSamplesWithNormalisedLevels);
      setIsOneHealthProject(areSamplesAnalysedWithOneHealthPanel(samplesByUID));
      setMetricGeneCnt(countMetricGenes(samplesByUID, metricMode, selectedAntibiotic));
    }
  }, [samplesByUID, metricMode, selectedAntibiotic, availableEnvGroups]);

  // PREPARE OVERVIEW TREND, MAP AND HELPER DATA
  useEffect(() => {
    if (!supportedSamples?.length || !queryParamsInitialised || !activeEnvGroup) {
      return;
    }

    // Avoid race conditions that can easily happen eg when an orignial map data update with all project data is missing co-ordinates,
    // but a subsequent, map update with filtered data isn't
    const thisInvocation = ++mapDataUpdateInvocationCnt.current;
    (() => {
      try {
        // Uncomment and load overview for end-to-end test project to update buildOverviewLineData regression test input.json
        // console.log('focusedByUID', JSON.stringify(focusedByUID));

        const levelsInAllSamples = keys(supportedSamples[0].adminLevels);
        const poolings: Pooling[] = [...levelsInAllSamples, null].map(level =>
          isNil(level)
            ? {
                type: PoolingType.SITE,
                mode: PoolingMode.THROW_MISSING,
              }
            : {
                type: PoolingType.SITE_AND_ADMIN_LEVEL,
                mode: PoolingMode.THROW_MISSING,
                level: +level,
              },
        );

        const selectedEnvSet = new Set(activeEnvGroup.envs.map(env => env.id));
        const newTrendDataByLevel = chain(poolings)
          .keyBy(pooling => ('level' in pooling ? `${pooling.level}` : 'null'))
          // Replace groupYearly with false to always use monthly data
          .mapValues(pooling => buildOverviewLineData(supportedSamples, selectedEnvSet, processMode, pooling))
          .value();

        const newMapDataByLevel = mapValues(newTrendDataByLevel, levelTrendData =>
          buildMapData(levelTrendData, queryFilters.filters.interval),
        );
        newMapDataByLevel['1'] = [getGlobalOveriewDatum()];
        const zoomableLevels = determineZoomableLevels(newMapDataByLevel, selectedCountry);
        const months = getUniqueMonths(newTrendDataByLevel['null']);

        // This may be a usefull spot to log any map area issues

        if (mapDataUpdateInvocationCnt.current === thisInvocation) {
          setTrendDataByLevel(newTrendDataByLevel);
          setMapDataByLevel(newMapDataByLevel);
          setLevelsWithZoomableAreas(zoomableLevels);
          setAvailableMonths(months);
          setNotAvailableReason(null);
        }
      } catch (err) {
        if (err instanceof Error) {
          setNotAvailableReason(err.message);
          console.error('OverviewContext error', err);
        } else {
          console.error('OverviewContext error', err);
        }
      }
    })();
  }, [supportedSamples, queryParamsInitialised, queryFilters.filters.interval, activeEnvGroup, processMode]);

  // When selected country is changed, we need to update the zoomable levels to country specific ones
  useEffect(() => {
    if (mapDataByLevel) {
      const zoomableLevels = determineZoomableLevels(mapDataByLevel, selectedCountry);
      setLevelsWithZoomableAreas(zoomableLevels);
      if (!zoomableLevels.length) {
        setShownAdminLevel(null);
      } else if (
        (shownAdminLevel === null && previousAdminAreasLifo.length) ||
        (previousAdminAreasLifo.length &&
          zoomedAdminArea &&
          getAdminAreaKey(zoomedAdminArea) ===
            getAdminAreaKey(previousAdminAreasLifo[previousAdminAreasLifo.length - 1]))
      ) {
        setShownAdminLevel(previousAdminAreasLifo[previousAdminAreasLifo.length - 1].level);
      }
    }
  }, [selectedCountry, mapDataByLevel]);

  useEffect(() => {
    if (trendDataByLevel && mapDataByLevel) {
      // shownAdminLevel can be null, we want to make sure the type is correct. Index can not be null
      const correctAdminLevel = shownAdminLevel || 'null';
      const newTrendData = trendDataByLevel[correctAdminLevel];
      // When one specific site is selected (transient aggregated environments have negative ids)
      if (shownAdminLevel === null && selectedOrHoveredAreaOrSiteEnvId && selectedOrHoveredAreaOrSiteEnvId > 0) {
        const selectedTrendData = newTrendData
          .flat()
          .find(dataLocal => dataLocal.environment.id === selectedOrHoveredAreaOrSiteEnvId);
        setTrendData([[selectedTrendData]] as OverviewDatum[][]);
      } else {
        setTrendData(newTrendData);
      }

      const countriesMapData = mapValues(mapDataByLevel, dataLocal =>
        filter(dataLocal, d => d.country === selectedCountry || !selectedCountry),
      );
      const newMapData = countriesMapData[correctAdminLevel];

      setMapData(newMapData);
    } else {
      setTrendData([]);
      setMapData([]);
    }
  }, [levelsWithZoomableAreas, trendDataByLevel, mapDataByLevel, shownAdminLevel, selectedOrHoveredAreaOrSiteEnvId]);

  // PREPARE MAP SOURCES (COLORED REGIONS) AND ZOOM TO BOUNDS
  useEffect(
    () => {
      if (!trendData || !mapData || !countryByAlpha3 || !supportedSamples) {
        return;
      }

      const mapInstance = mapRef?.getMap();
      if (selectedSiteDatum) {
        if (mapInstance && mapLoaded && selectedSiteName) {
          const datum = mapData.find(d => {
            const siteEnvNames = getAllOriginalEnvironmentNames(d.environment, d.environmentAfter);
            return siteEnvNames.includes(selectedSiteName);
          });
          if (!isNil(datum?.inferredLon) && !isNil(datum.inferredLat)) {
            mapInstance.flyTo({ zoom: 13, center: [datum.inferredLon, datum.inferredLat], padding: 120 });
          }
        }
        return;
      }

      const countries = getCountriesFromSamples(supportedSamples);
      const { country } =
        shownAdminLevel !== 2 && countries.length === 1
          ? getCountryAdminLevelIntersection(supportedSamples)
          : { country: selectedCountry };
      const mapSource = shownAdminLevel ? getMapSourceFromAdminLevel(shownAdminLevel, country) : undefined;
      if (mapInstance && mapLoaded) {
        if (!mapSource || selectedSiteName) {
          setActiveMapSource(undefined);
          if (zoomedAdminArea?.boundaries) {
            zoomAndCenter({ bounds: zoomedAdminArea.boundaries });
          } else {
            const bounds = calculateMarkerBounds(mapData);
            zoomAndCenter({ bounds });
          }
          deactivateColoredRegions(mapInstance);
        } else if (shownAdminLevel) {
          setActiveMapSource(mapSource);
          deactivateColoredRegions(mapInstance);
          const boundingBoxes = chain(trendData)
            .map(_data => {
              const environmentId = _data[0].environment.id;
              return [environmentId, getBoundingBoxForEnvironment(_data)];
            })
            .fromPairs()
            .value() as Dictionary<{ ne: [number, number]; sw: [number, number] }>;
          activateColoredRegions(
            mapInstance,
            mapSource,
            mapData,
            setSelectedOrHoveredAreaOrSiteEnvId,
            boundingBoxes,
            changeZoomedAdminArea,
            queryFilters.filters,
            metricMode,
            processMode,
            activeChartUnit,
          );
          const mapDataChanged = JSON.stringify(previousMapData) !== JSON.stringify(mapData);
          if (zoomedAdminArea?.boundaries && mapDataChanged) {
            zoomAndCenter({ bounds: zoomedAdminArea.boundaries });
          } else if (mapDataChanged) {
            const bounds = calculateMarkerBounds(mapData);
            zoomAndCenter({ bounds });
          }
          setPreviousMapData(mapData);
        }
      }
    },
    // previousMapData is missing in dependency array by design.
    [
      trendData,
      mapData,
      supportedSamples,
      shownAdminLevel,
      zoomedAdminArea,
      mapRef,
      mapLoaded,
      selectedSiteDatum,
      selectedSiteName,
      metricMode,
      processMode,
      activeChartUnit,
    ],
  );

  // PREPARE ADMIN LEVELS
  useEffect(() => {
    if (selectedSiteName && shownAdminLevel) {
      setShownAdminLevel(null);
    } else if (mapDataByLevel && singleSiteSelected && isNil(zoomedAdminArea)) {
      // Decipher selected site from query filters
      const siteData = mapDataByLevel['null'];
      const siteToKeepSelected = siteData.find(d => {
        const siteEnvNames = getAllOriginalEnvironmentNames(d.environment, d.environmentAfter);
        return (
          (siteEnvNames.length === queryFilters.filters.selectedEnvironmentNamesOrdered.length ||
            queryFilters.filters.selectedEnvironmentNamesOrdered.length === 1) &&
          siteEnvNames.some(envName => queryFilters.filters.selectedEnvironmentNamesOrdered.includes(envName))
        );
      });
      if (siteToKeepSelected) {
        setZoomedAdminArea(null);
        setShownAdminLevel(null);
        setSelectedOrHoveredAreaOrSiteEnvId(siteToKeepSelected.environment.id);
      }
    } else if (
      mapDataByLevel &&
      keys(mapDataByLevel).length &&
      levelsWithZoomableAreas.length &&
      zoomedAdminArea === undefined
    ) {
      const highestLevel = levelsWithZoomableAreas[0];
      const levelMapData = mapDataByLevel[highestLevel];
      const firstArea = levelMapData[0]?.adminLevels?.[`${highestLevel}` as AdminLevelKey];
      setZoomedAdminArea(firstArea);
      setShownAdminLevel(levelsWithZoomableAreas.find(level => firstArea && level > firstArea.level) || null);
    }
  }, [
    mapDataByLevel,
    levelsWithZoomableAreas,
    selectedSiteName,
    zoomedAdminArea,
    singleSiteSelected,
    queryFilters.filters.selectedEnvironmentNamesOrdered,
  ]);

  // PREPARE CALLBACKS
  const handleZoom = useCallback(
    (e: ViewStateChangeEvent) => {
      const { zoom: newZoom } = e.viewState;
      setZoom(newZoom);
    },
    [setZoom],
  );
  const zoomAndCenter = useCallback(
    (zoomOrBounds: { zoom: number; center: LngLatLike } | { bounds: LngLatBoundsLike }) => {
      const MIN_ZOOM = 13;
      const mapInstance = mapRef?.getMap();
      if (mapInstance && 'zoom' in zoomOrBounds) {
        mapInstance.flyTo({
          zoom: Math.min(MIN_ZOOM, zoomOrBounds.zoom),
          center: zoomOrBounds.center,
          padding: 120,
        });
        setZoom(zoomOrBounds.zoom);
      } else if (mapInstance && 'bounds' in zoomOrBounds) {
        const cameraForBounds = mapInstance.cameraForBounds(zoomOrBounds.bounds);
        mapInstance.flyTo({
          zoom: Math.min(MIN_ZOOM, cameraForBounds?.zoom || MIN_ZOOM),
          center: cameraForBounds?.center,
          padding: 120,
        });
      }
    },
    [mapRef],
  );
  const changeZoomedAdminArea = useCallback(
    (
      adminArea: AdminArea | null | undefined,
      { countryId, previous }: { countryId?: string; previous?: boolean } = {},
    ) => {
      // If adminArea is magically undefined, it means site is being unselected. If the site is being unselected and we
      // don't have any adminAreas to show, we zoom out so show all markers
      if (adminArea === undefined && levelsWithZoomableAreas.length >= 1 && mapData) {
        const bounds = calculateMarkerBounds(mapData);
        zoomAndCenter({ bounds });
      } else if (
        previousAdminAreasLifo.length &&
        adminArea &&
        getAdminAreaKey(adminArea) === getAdminAreaKey(previousAdminAreasLifo[previousAdminAreasLifo.length - 1])
      ) {
        setZoomedAdminArea(adminArea);
        if (adminArea.level === 1) {
          setShownAdminLevel(2);
        } else {
          setShownAdminLevel(levelsWithZoomableAreas.find(level => level > adminArea.level) || null);
        }
        if (previous) {
          previousAdminAreasLifo.length === 1 &&
            previousAdminAreasLifo[0].name === GLOBAL_NAME &&
            setSelectedCountry(undefined);
          setPreviousAdminAreasLifo(previousAdminAreasLifo.slice(0, -1));
        }
      } else if (!adminArea) {
        setZoomedAdminArea(null);
        setShownAdminLevel(null);
      } else {
        countryId && setSelectedCountry(countryId);
        setZoomedAdminArea(adminArea);
        setShownAdminLevel(levelsWithZoomableAreas.find(level => level > adminArea.level) || null);
        if (zoomedAdminArea && getAdminAreaKey(adminArea) !== getAdminAreaKey(zoomedAdminArea)) {
          setPreviousAdminAreasLifo([...previousAdminAreasLifo, zoomedAdminArea]);
        }
      }
    },
    [zoomedAdminArea, previousAdminAreasLifo, levelsWithZoomableAreas],
  );

  // AUTOSELECT SITE WHEN ZOOMING INTO SINGLE SITE ADMIN AREA
  const toggleEnvironment = queryFilters.toggleEnvironment;
  useEffect(() => {
    if (zoomedAdminArea && !shownAdminLevel && !activeMapSource) {
      const sitesInAdminArea = getAllSitesInAdminArea(zoomedAdminArea, mapData);

      if (sitesInAdminArea && sitesInAdminArea.length === 1) {
        toggleEnvironment(sitesInAdminArea[0].environment.name, true);
        changeZoomedAdminArea(null);
      }
    }
  }, [zoomedAdminArea, shownAdminLevel, activeMapSource, mapData, changeZoomedAdminArea, toggleEnvironment]);

  const correctSetMapRef = (mapRefLocal: MapRef | null) => {
    if (!mapRef && mapRefLocal) {
      setMapRef(mapRefLocal);
    }
  };

  const getAdminAreaDatum = (level: number, name: string) => {
    return mapDataByLevel && mapDataByLevel[level].find(area => area.environment.name === name);
  };

  const setMonth = useCallback(
    (date: Date | null) => {
      if (date === null) {
        queryFilters.setInterval(DEFAULT_START_INTERVAL, DEFAULT_END_INTERVAL);
      } else {
        const startOfMonth = ensureUtcMonth(date);
        const startOfNextMonth = utcStartOfNextMonth(startOfMonth);
        queryFilters.setInterval(startOfMonth, startOfNextMonth);
      }
    },
    [queryFilters],
  );

  const selectedMonth =
    // Currently the interval.start can present the 1900 year, which is less than 0
    queryFilters.filters.interval.start.valueOf() > 0 ? queryFilters.filters.interval.start : null;

  // CONTEXT DATA ASSEMBLY
  const contextData = {
    hasPreparedData: !!trendData && !!mapData,
    mapData,
    siteCount: mapDataByLevel && mapDataByLevel['null'].length,
    samplingPointsCount:
      mapDataByLevel && mapDataByLevel['null'].reduce((acc, d) => acc + (d.environmentAfter ? 2 : 1), 0),
    selectedSiteDatum,
    trendData,
    selectedAntibiotic,
    previousAdminAreasLifo,
    loading: loading || !queryParamsInitialised,
    error,
    setSelectedOrHoveredAreaOrSiteEnvId,
    selectedOrHoveredAreaOrSiteEnvId,
    activeMapSource,
    setMapRef: correctSetMapRef,
    mapRef,
    handleZoom,
    processMode,
    shownAdminLevel,
    isOneHealthProject,
    metricGeneCnt,
    zoomAndCenter,
    availableEnvGroups,
    zoom,
    data,
    changeZoomedAdminArea,
    zoomedAdminArea,
    setMapLoaded,
    topLevelMapData:
      mapDataByLevel && levelsWithZoomableAreas.length ? mapDataByLevel[levelsWithZoomableAreas[0]][0] : undefined,
    zoomedMapData: zoomedAdminArea ? getAdminAreaDatum(zoomedAdminArea.level, zoomedAdminArea.name) : undefined,
    metricMode,
    notAvailableReason,
    availableMonths,
    setMonth,
    selectedMonth,
    selectedEnvironmentTypeGroup: queryFilters.filters.selectedEnvironmentTypeGroup,
    activeOverviewConfiguration,
    activeChartUnit,
    selectedCountry,
    supportedSamples,
    trendDataByLevel,
  };

  return <OverviewContext.Provider value={contextData}>{children}</OverviewContext.Provider>;
}

export function useOverviewContext() {
  const context = useContext(OverviewContext);
  if (!context) {
    throw new Error('useOverviewContext must be used within a OverviewContextProvider');
  }
  return context;
}

export function useSelectedSiteDatumOrThrow() {
  const { selectedSiteDatum } = useOverviewContext();
  if (!selectedSiteDatum) {
    throw new Error('Expected site view to be active');
  }
  return selectedSiteDatum;
}

/*
 * Determine admin levels whose areas we can ZOOM TO. This includes
 * - First, the default zoom level: the highest level that has only one area with samples (this level is never shown as areas)
 * - Last, zoomable level that has more than one site in some area (this level is shown as colored areas, and sites are shown when zooming into one)
 * - And usefull levels in between the first and the last level
 *   - Useless levels that have as many datums as the next area are skipped so that user always gets more data on each click
 *
 * Mind the ZOOMED area & level vs SHOWN level concepts:
 * When ZOOMING into a level 2 area, data is SHOWN on level 3 (or whatever is the next available level in all samples)
 */
export function determineZoomableLevels(
  mapDataByLevel: Dictionary<OverviewDatum[]> & { null?: OverviewDatum[] },
  selectedCountry?: string,
): number[] {
  if (!mapDataByLevel['null']) {
    throw new Error('Expected null level to exist');
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (mapDataByLevel['1'] && mapDataByLevel['1'].length > 1) {
    throw new Error('Expected maximum only one site on level 1');
  }

  const numSites = mapDataByLevel['null'].length;

  // First filter data by country if one is selected
  const filteredByCountry = chain(mapDataByLevel)
    .mapValues(levelAreas =>
      selectedCountry ? levelAreas.filter(area => area.country === selectedCountry) : levelAreas,
    )
    .value();

  const ret = chain(filteredByCountry)
    .toPairs()
    // Consider non-aggregated site level as the deepest level
    .sortBy(([level]) => (isNil(level) ? 16 : +level))
    .filter(([_, levelAreas]) => levelAreas.length > 0)
    .filter(
      // Get rid of weird corner cases where a level has less areas than its upper level
      ([_, levelAreas], pairIndex, allPairs) => !pairIndex || levelAreas.length >= allPairs[pairIndex - 1][1].length,
    )
    .filter(([adminLevel, levelAreas], pairIndex, allPairs) => {
      // Skip useless levels where the next level doesn't have any more areas (note: expects that null level still exists)
      // Exception with countries, which we handle with detecting is adminLevel 2
      return (
        (!selectedCountry && adminLevel === '2' && levelAreas.length > 1) ||
        (pairIndex < allPairs.length - 1 && allPairs[pairIndex + 1][1].length > levelAreas.length)
      );
    })
    // Ensure that some area on each level has more than one data point
    // Also drops special case null site level as the last operation
    .filter(([_, levelAreas]) => levelAreas.length < numSites)
    .map(([level]) => +level)
    .value();

  return ret;
}

function getAllSitesInAdminArea(adminArea: AdminArea, mapData: OverviewDatum[] | undefined) {
  return mapData?.filter(datum => {
    const key = String(adminArea.level) as AdminLevelKey;
    const localAdminArea = datum.adminLevels?.[key];
    return localAdminArea && getAdminAreaKey(localAdminArea) === getAdminAreaKey(adminArea);
  });
}

function determineAvailableNormalisationModes(samples: FullSample[]): NormalisationMode[] {
  const hasVolume = samples.every(s => !isNil(calculateNormalizationFactors(s).volumeNormalisationFactor));
  const hasTime = samples.every(s => !isNil(calculateNormalizationFactors(s).flowNormlisationFactor));
  const hasSS = samples.every(s => !isNil(calculateNormalizationFactors(s).suspendedSolidsNormlisationFactor));
  const hasBOD = samples.every(s => !isNil(calculateNormalizationFactors(s).bodNormlisationFactor));

  return chain([
    hasSS && NormalisationMode.MG_SS,
    hasBOD && NormalisationMode.MG_BOD,
    hasTime && NormalisationMode.HOUR,
    hasVolume && NormalisationMode.LITRE,
    NormalisationMode.TEN_UL_DILUTED_DNA,
    NormalisationMode.SIXTEEN_S,
  ])
    .filter(Boolean)
    .value() as NormalisationMode[];
}

export function getGlobalOveriewDatum(): OverviewDatum {
  return {
    environment: { type: EnvironmentType.OTHER, name: GLOBAL_NAME, id: GLOBAL_ENVIRONMENT_ID },
    adminLevels: { 1: { name: GLOBAL_NAME, level: 1 } },
    lat: undefined,
    lon: undefined,
    inferredLat: undefined,
    inferredLon: undefined,
    beforeAbundances: [],
    afterAbundances: [],
    country: undefined,
    region: undefined,
    city: undefined,
    date: '',
    label: '',
  };
}
