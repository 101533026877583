import { Kbd, ListItem, UnorderedList } from '@chakra-ui/react';
import { getGroupPalette } from '@resistapp/client/components/shared/palettes';
import { Filters, handleFiltersSelectionWithKeys } from '@resistapp/client/data-utils/filter-data/filter';
import { GeneGrouping, Target, defaultGroupingOptions } from '@resistapp/common/assays';
import { scaleOrdinal } from '@visx/scale';
import { useRef } from 'react';
import { ChartHotkeyInstructions } from '../../tooltips/chart-hotkey-instructions';
import { BaseLegend } from './base-legend';
import { KeysPressOptions, Legend } from './legend';

interface GeneLegendProps {
  availableGroups: string[];
  filters: Filters;
  setGrouping: (grouping: GeneGrouping) => void;
  toggleGeneGroup: (group: Target | Target[], only: boolean) => void;
  disableKeys?: boolean;
  header?: string;
  defaultValue?: GeneGrouping;
  description?: string;
  legendHeight?: string;
  topHeight?: string;
  showOptions?: boolean;
  id?: string;
}

export function GeneLegend({
  availableGroups,
  filters,
  disableKeys,
  header,
  defaultValue = 'target',
  toggleGeneGroup,
  setGrouping,
  description,
  legendHeight,
  topHeight,
  showOptions,
  id,
}: GeneLegendProps) {
  const previousSelection = useRef<string | undefined>();
  const options = defaultGroupingOptions;
  const selectedGroupingOption = options.find(o => o.value === filters.selectedTargetGrouping)?.value || defaultValue;

  const reversedGroups = [...availableGroups].reverse();
  const palette = getGroupPalette(reversedGroups, filters.selectedTargetGrouping);

  const colorScale = scaleOrdinal<string, string>({
    domain: reversedGroups,
    range: palette,
  });
  const getOpacity = (label: string) => (filters.selectedTargets.includes(label as Target) ? 1 : 0.4);
  const onClick = (label: string, keys: KeysPressOptions) => {
    const [newGroups, removeOldSelections] = handleFiltersSelectionWithKeys(
      filters.selectedTargets,
      reversedGroups,
      label,
      previousSelection.current,
      keys,
      Boolean(disableKeys),
    );
    previousSelection.current = previousSelection.current && keys.shift ? previousSelection.current : label;
    toggleGeneGroup(newGroups as Target[], removeOldSelections);
  };
  const labelFormat = (label: string | number) => String(label);

  return (
    <BaseLegend
      id={id}
      header={header}
      description={description}
      options={showOptions ? options : undefined}
      onChange={value => {
        // We currently only support antibiotics (Target)
        toggleGeneGroup(value as GeneGrouping as Target, false);
        setGrouping(value as GeneGrouping);
      }}
      defaultValue={selectedGroupingOption}
      TooltipContent={
        <UnorderedList>
          <ListItem>
            <Kbd>Click</Kbd> a label or chart bar to select a group
          </ListItem>
          {disableKeys ? undefined : <ChartHotkeyInstructions label="groups" />}
        </UnorderedList>
      }
      height={topHeight}
    >
      <Legend
        colorScale={colorScale}
        labelFormat={labelFormat}
        getOpacity={getOpacity}
        noColor={disableKeys}
        onClick={onClick}
        // This is here to make the legend height work on the world view when it has a select element in it.
        height={legendHeight}
      />
    </BaseLegend>
  );
}
