import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { DeadCenter } from '../components/shared/layout';
import { MaybeApiError } from '../components/states/error';

export function RootErrorView() {
  const routeError = useRouteError();

  useEffect(() => {
    Sentry.captureException(routeError);
  }, [routeError]);

  const unknownMessage = 'An unknown error occured, please contact support';
  const error =
    routeError instanceof Error
      ? routeError
      : typeof routeError === 'string'
        ? new Error(routeError)
        : typeof routeError === 'object'
          ? new Error((routeError as { message?: string }).message || unknownMessage)
          : new Error(unknownMessage);

  return (
    <DeadCenter>
      <MaybeApiError error={error} />
    </DeadCenter>
  );
}
