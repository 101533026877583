import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getMetricColor } from '@resistapp/client/utils/metric-utils';
import { ChartUnit, MetricMode } from '@resistapp/common/types';
import { theme } from '../../shared/theme';
import { BaseMapLegend } from './base-map-legend';

export function RiskScoreMapLegend({ timeLegend }: { timeLegend: React.ReactNode | string }) {
  const { activeOverviewConfiguration } = useOverviewContext();
  return (
    <BaseMapLegend
      timeLegend={timeLegend}
      indicators={<ColorScale />}
      indexRange={activeOverviewConfiguration.mapLegendTicks?.map(e => <div key={e}>{e}</div>)}
    />
  );
}

const ColorScale = styled.div`
  width: 100%;
  height: 20px;
  background: linear-gradient(
    to right,
    ${getMetricColor(0, MetricMode.RISK, ChartUnit.COPYNUMBER)},
    ${getMetricColor(100, MetricMode.RISK, ChartUnit.COPYNUMBER)}
  );
  border-radius: ${theme.borders.radius.small};
`;
