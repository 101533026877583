import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getBeforeOrAfterAbundances } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getFriendlyInterval } from '@resistapp/common/date-utils';
import { MetricMode } from '@resistapp/common/types';
import { ReductionMapLegend } from './reduction-map-legend';
import { ResistanceIndexMapLegend } from './resistance-index-map-legend';
import { RiskScoreMapLegend } from './risk-score-map-legend';

export function MapLegend() {
  const { metricMode, mapData, processMode } = useOverviewContext();
  if (!mapData) {
    return null;
  }
  const timeLegend = getFriendlyInterval(
    mapData.filter(d => getBeforeOrAfterAbundances(d, metricMode, processMode)?.length),
  );

  switch (metricMode) {
    case MetricMode.RISK:
      return <RiskScoreMapLegend timeLegend={timeLegend} />;
    case MetricMode.ARGI:
      return <ResistanceIndexMapLegend timeLegend={timeLegend} />;
    case MetricMode.REDUCTION:
      return <ReductionMapLegend timeLegend={timeLegend} />;
    default:
      return null;
  }
}
