import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { GeneAndCopyNumber } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { getMetricColor } from '@resistapp/client/utils/metric-utils';
import { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { MetricMode } from '@resistapp/common/types';
import { Bar } from '@visx/shape';
import { Text } from '@visx/text';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import { isNil, min } from 'lodash';
import { Fragment, MouseEventHandler } from 'react';
import { theme } from '../shared/theme';
import { BarLabelThreshold, getScaleLabel, tickLabelProps, twoSidedBarMargin } from './copy-number-bar-graph';
import { formatGeneMutationSuffixes } from './site-details/general-site-details';

const textMargin = 6 as const;
const maxBarHeight = 42 as const;

interface BarProps {
  genesAndNumbers: GeneAndCopyNumber[];
  geneAndCopyNumber: GeneAndCopyNumber;
  metricMode: MetricMode;
  scaleProperty: keyof GeneAndCopyNumber;
  is2Sided: boolean;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleBand<string>;
  xMax: number;
  handleMouseMove: (
    event: React.MouseEvent | React.TouchEvent,
    newTooltipData: GeneAndCopyNumber | undefined,
    enter?: boolean,
  ) => void;
  activeOverviewConfiguration: OverviewChartConfiguration;
}

export function CopyNumberBar(props: BarProps) {
  const {
    genesAndNumbers,
    geneAndCopyNumber,
    metricMode,
    scaleProperty,
    is2Sided,
    xScale,
    yScale,
    xMax,
    handleMouseMove,
    activeOverviewConfiguration,
  } = props;
  const { activeChartUnit } = useOverviewContext();
  const { gene, assay } = geneAndCopyNumber;
  const scaleValue = geneAndCopyNumber[scaleProperty] as number;
  const is2SidedNegativeValue = is2Sided && scaleValue && scaleValue < 0;
  const originalBarHeight = yScale.bandwidth();
  const barHeight = min([originalBarHeight, maxBarHeight]) || 0;
  const barHeightDiff = originalBarHeight - barHeight;
  if (isNil(scaleValue)) {
    return null;
  }
  const formattedNumber = activeOverviewConfiguration.formatNumber?.(scaleValue);
  const graphCenter = (xMax - (is2Sided ? twoSidedBarMargin.left * twoSidedBarMargin.rightQuantifier : 0)) / 2;
  // With 2 sided, the bar width is half the distance to the center of the graph, but extends to both sides.
  const barWidth = is2Sided ? Math.min(xScale(Math.abs(scaleValue)) / 2, graphCenter) : xScale(scaleValue);
  const barY = (yScale(getScaleLabel(assay, gene)) || 0) + barHeightDiff / 2;
  const barX = is2SidedNegativeValue ? graphCenter - barWidth : is2Sided ? graphCenter : 0;
  const textPosition = getTextPosition(is2Sided, scaleValue, barWidth, graphCenter);

  // Determine the bar color based on the metric mode
  const barColor =
    metricMode === MetricMode.REDUCTION
      ? getMetricColor(scaleValue, MetricMode.REDUCTION, activeChartUnit)
      : !is2Sided || is2SidedNegativeValue
        ? theme.colors.neutral700
        : theme.colors.maroon;

  const onMouseProps: Record<string, MouseEventHandler<SVGRectElement>> = {
    onMouseLeave: event => {
      handleMouseMove(event, undefined);
    },
    onMouseMove: event => {
      const geneData = genesAndNumbers.find(d => d.assay === assay);
      handleMouseMove(event, geneData, true);
    },
    onMouseEnter: event => {
      const geneData = genesAndNumbers.find(d => d.assay === assay);
      handleMouseMove(event, geneData, true);
    },
  };

  const isTextOnBar = !textPosition.isOutside;

  // Determine the text color
  const textColor =
    metricMode === MetricMode.REDUCTION && isTextOnBar
      ? activeOverviewConfiguration.getFriendlyTextColor(scaleValue)
      : textPosition.isOutside
        ? tickLabelProps.color
        : 'white';

  return (
    <Fragment key={`bar-${gene}`}>
      <Bar
        key={`bar-${gene}`}
        y={barY}
        x={barX}
        rx={3}
        width={barWidth}
        height={barHeight}
        fill={barColor}
        {...onMouseProps}
      />
      <Text
        x={textPosition.x}
        y={barY + barHeight / 2}
        textAnchor={textPosition.textAnchor}
        dy=".35em"
        fill={textColor}
        style={tickLabelProps}
        {...onMouseProps}
      >
        {formattedNumber}
      </Text>
      {is2Sided && (
        <Text
          x={graphCenter - (is2SidedNegativeValue ? -textMargin : textMargin)}
          y={barY + barHeight / 2}
          textAnchor={getLabelAnchor(textPosition.textAnchor, textPosition.isOutside)}
          dy=".35em"
          fill="black"
          style={tickLabelProps}
          {...onMouseProps}
        >
          {formatGeneMutationSuffixes(gene)}
        </Text>
      )}
    </Fragment>
  );
}

type LabelPosition = 'start' | 'end';
function getTextPosition(is2Sided: boolean, value: number, barWidth: number, graphCenter: number) {
  const isOutside = barWidth < BarLabelThreshold;

  if (is2Sided) {
    if (value < 0) {
      const barEdge = graphCenter - barWidth;
      return {
        x: barEdge - (isOutside ? textMargin : -textMargin),
        isOutside,
        textAnchor: (isOutside ? 'end' : 'start') as LabelPosition,
      };
    } else {
      const barEdge = graphCenter + barWidth;
      return {
        x: barEdge + (isOutside ? textMargin : -textMargin),
        isOutside,
        textAnchor: (isOutside ? 'start' : 'end') as LabelPosition,
      };
    }
  } else {
    return {
      x: barWidth + (isOutside ? textMargin : -textMargin),
      isOutside,
      textAnchor: (isOutside ? 'start' : 'end') as LabelPosition,
    };
  }
}

function getLabelAnchor(textAnchor: 'start' | 'end', isOutside: boolean) {
  return textAnchor === 'start' ? (isOutside ? 'end' : 'start') : isOutside ? 'start' : 'end';
}
