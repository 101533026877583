import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getMetricColor } from '@resistapp/client/utils/metric-utils';
import { getOverviewConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { ChartUnit, MetricMode } from '@resistapp/common/types';
import { theme } from '../../shared/theme';
import { BaseMapLegend } from './base-map-legend';

export function ReductionMapLegend({ timeLegend }: { timeLegend: React.ReactNode | string }) {
  const { activeChartUnit, activeOverviewConfiguration } = useOverviewContext();
  return (
    <BaseMapLegend
      timeLegend={timeLegend}
      indicators={<ColorScale activeChartUnit={activeChartUnit} />}
      indexRange={activeOverviewConfiguration.mapLegendTicks?.map(e => (
        // The marginLeft takes into account that some of the numbers have minuses in front of them and take up
        // space. Without the margin the scale is not inline with the colored bar over it.
        <div key={e} style={{ width: '100%' }}>
          {e}
        </div>
      ))}
    />
  );
}

const ColorScale = styled.div<{ activeChartUnit: ChartUnit }>`
  width: 100%;
  height: 20px;
  background: linear-gradient(
    to right,
    ${props =>
      getMetricColor(
        getOverviewConfiguration(MetricMode.REDUCTION, props.activeChartUnit).detailBarGraphMin,
        MetricMode.REDUCTION,
        props.activeChartUnit,
      )},
    ${props => getMetricColor(0, MetricMode.REDUCTION, props.activeChartUnit)},
    ${props =>
      getMetricColor(
        getOverviewConfiguration(MetricMode.REDUCTION, props.activeChartUnit).detailBarGraphMax(),
        MetricMode.REDUCTION,
        props.activeChartUnit,
      )}
  );
  border-radius: ${theme.borders.radius.small};
`;
