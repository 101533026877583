import { Box, Button, Flex, Input, ListItem, UnorderedList } from '@chakra-ui/react';
import { ResistomapSelect } from '@resistapp/client/components/forms/resistomap-select';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { RThirdTitle } from '@resistapp/client/components/headers/r-third-title';
import { DeleteIcon } from '@resistapp/client/components/icons/delete-icon';
import { InfoTooltipIcon } from '@resistapp/client/components/icons/info-tooltip-icon';
import { AddOrganizationAccessDropdown } from '@resistapp/client/components/selects/add-project-access-dropdown';
import { AddUserAccessDropdown } from '@resistapp/client/components/selects/add-user-access-dropdown';
import { GridView } from '@resistapp/client/components/shared/layout';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import {
  useDeleteOrganizationProject,
  useDeleteUserProject,
  useOrganizations,
  usePatchProjectImages,
  usePatchProjectName,
  usePatchProjectStatus,
  useUsers,
} from '@resistapp/client/hooks/api';
import { downloadDataUrl, getImageDataUrlFromHtml, getImageDataUrlFromSVG } from '@resistapp/client/utils/exporting';
import { getProjectOrganizationIds, getUserProjectIds } from '@resistapp/common/access';
import { isAdmin, resistomapOrganizationId } from '@resistapp/common/features';
import { getProjectStatusInfo } from '@resistapp/common/friendly';
import { NormalisedValueName } from '@resistapp/common/normalisation-mode';
import { FullProject, NormalisationMode, Organisation, Project, ProjectStatus, User } from '@resistapp/common/types';
import { useCallback, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { getExtraFilenames } from './download-and-link-section';
import { FileDropzone } from './dropzone';

const exportOptions = [
  { value: 1, label: 'Please choose target', id: '', type: '', plotType: '' },
  {
    value: 2,
    label: 'Detected genes chart',
    id: 'research-view-detected-genes-chart',
    type: 'chart',
    plotType: 'bar',
  },
  {
    value: 3,
    label: 'Samples chart',
    id: 'research-view-samples-chart',
    type: 'chart',
    plotType: 'box',
  },
  {
    value: 4,
    label: 'Gene details chart',
    id: 'research-view-gene-details-chart',
    type: 'chart',
    plotType: 'heat',
  },
  {
    value: 5,
    label: 'Detected genes legend',
    id: 'research-view-detected-genes-legend',
    type: 'legend',
    plotType: 'bar',
  },
  {
    value: 6,
    label: 'Samples legend',
    id: 'research-view-samples-legend',
    type: 'legend',
    plotType: 'box',
  },
  {
    value: 7,
    label: 'Gene details legend',
    id: 'research-view-gene-details-legend',
    type: 'legend',
    plotType: 'heat',
  },
] as const;

interface Props {
  project: FullProject;
  triggerDelete: () => Promise<any>;
}

export function AdminSection({ project, triggerDelete }: Props) {
  const { plotData, queryFilters } = useResearchContext();
  const { refetchData: refetchProject } = useSampleDataContext();
  const { user } = useUser();
  const { data: allUsers, send: loadUsers } = useUsers();
  const { data: allOrganizations } = useOrganizations();
  const { send: patchImages, data: imageSaved } = usePatchProjectImages(project.id);
  const patchStatus = usePatchProjectStatus(project.id);
  const patchName = usePatchProjectName(project.id, refetchProject);
  const [projectName, setProjectName] = useState(project.name);
  const [exportObject, setExportObject] = useState<(typeof exportOptions)[number]>();

  const plotFileNames = getExtraFilenames(project);
  const usersWithAccess =
    allUsers?.filter(u => !isAdmin(u) && getUserProjectIds(u).find(projectId => projectId === project.id)) || [];
  const usersWithoutAccess =
    allUsers?.filter(u => !isAdmin(u) && getUserProjectIds(u).find(projectId => projectId !== project.id)) || [];
  useEffect(() => {
    if (isAdmin(user)) {
      void loadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const organizationsWithAccess =
    allOrganizations?.filter(u => getProjectOrganizationIds(u).find(projectId => projectId === project.id)) || [];
  const organizationsWithoutAccess = allOrganizations?.filter(u => !organizationsWithAccess.includes(u)) || [];

  const onDrop = useCallback(
    (files: FileWithPath[]) => {
      for (const file of files) {
        if (!file.name.endsWith('.pdf') && !file.name.endsWith('.csv')) {
          alert(`Please convert ${file.name} into pdf or csv format.`);
          return;
        }
      }
      patchImages(files);
    },
    [patchImages],
  );
  useEffect(() => {
    if (imageSaved) {
      window.location.reload();
    }
  }, [imageSaved]);

  if (!isAdmin(user)) {
    return null;
  }

  const projectStatusOptions = Object.values(ProjectStatus).map(v => ({ label: v, value: v }));

  return (
    <GridView>
      <RSecondTitle>Project Administration</RSecondTitle>
      <Flex flexWrap="wrap" gap={oldTheme.spacing.m}>
        <Flex minWidth={250} flexDirection="column">
          <RThirdTitle>Rename project</RThirdTitle>
          <Flex alignItems="center">
            <Input
              value={projectName}
              onChange={event => {
                setProjectName(event.target.value);
              }}
            />
            <Button
              disabled={!!projectName || projectName === project.name}
              onClick={_event => {
                patchName.mutate(projectName);
              }}
            >
              Rename
            </Button>
          </Flex>
        </Flex>
        <Box minWidth={250}>
          <RThirdTitle>Project status</RThirdTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flexGrow: 1 }}>
              <ResistomapSelect<{ label: string; value: ProjectStatus }, false>
                options={projectStatusOptions}
                defaultValue={projectStatusOptions.find(o => o.value === project.status)}
                onChange={option => {
                  if (option?.value) {
                    if (
                      option.value === ProjectStatus.POOLED_MANUAL ||
                      option.value === ProjectStatus.POOLED ||
                      option.value === ProjectStatus.LEGACY
                    ) {
                      alert('Unsupported status change');
                      return;
                    }
                    patchStatus.mutate(option.value);
                  }
                }}
              />
            </div>
            <InfoTooltipIcon placement="top">
              <UnorderedList>
                {Object.values(ProjectStatus).map(status => (
                  <ListItem key={status}>
                    <b>{status}:</b> {getProjectStatusInfo(status)}
                  </ListItem>
                ))}
              </UnorderedList>
            </InfoTooltipIcon>
          </div>
        </Box>
        <Box minWidth={250}>
          <RThirdTitle>User access</RThirdTitle>
          <UnorderedList style={{ listStyleType: 'none' }}>
            {usersWithAccess.map((u, k) => (
              <UserAccessListItem key={`uali${k}`} user={u} project={project} />
            ))}
            <ListItem>
              <AddUserAccessDropdown users={usersWithoutAccess} projectId={project.id} />
            </ListItem>
          </UnorderedList>
        </Box>
        <Box minWidth={250}>
          <RThirdTitle>Organization access</RThirdTitle>
          <UnorderedList style={{ listStyleType: 'none' }}>
            {organizationsWithAccess.map(org => (
              <OrganizationAccessListItem key={org.id} organization={org} project={project} />
            ))}
            <ListItem>
              <AddOrganizationAccessDropdown organizations={organizationsWithoutAccess} projectId={project.id} />
            </ListItem>
          </UnorderedList>
        </Box>
        <Box minWidth={150} maxWidth={200}>
          <RThirdTitle>Extra analyses</RThirdTitle>
          <ul>
            {plotFileNames.map((filename, key) => {
              return (
                <li key={key}>
                  {filename}{' '}
                  <DeleteIcon
                    // eslint-disable-next-line @typescript-eslint/require-await
                    triggerDelete={async () => {
                      patchImages([], filename);
                    }}
                    name={filename}
                  />
                </li>
              );
            })}
          </ul>
          <div style={{ marginTop: -20, width: '80%' }}>
            <FileDropzone onDrop={onDrop} />
          </div>
        </Box>
        <Box>
          <RThirdTitle>Warnings</RThirdTitle>
          <Warnings warnings={project.warnings} />
        </Box>
        <Box>
          <RThirdTitle>Danger zone</RThirdTitle>
          <DeleteIcon
            style={{ fontSize: oldTheme.fontSize.xl }}
            name={`${project.name} project`}
            triggerDelete={triggerDelete}
          />
        </Box>
        <Flex flexDirection="column">
          <ResistomapSelect<(typeof exportOptions)[number], false>
            options={exportOptions}
            defaultValue={exportOptions[0]}
            onChange={option => {
              if (option && 'id' in option) {
                setExportObject(option);
              }
            }}
            id="export-type-selector"
            className="test_export-type-selector"
          />
          <Button
            onClick={() => {
              if (!exportObject || !exportObject.id || !plotData) {
                return;
              }

              void onExport(exportObject, project, queryFilters.filters.normalisationMode);
            }}
          >
            Export
          </Button>
        </Flex>
      </Flex>
    </GridView>
  );
}

async function onExport(
  exportObject: (typeof exportOptions)[number],
  project: FullProject,
  normalisationMode: NormalisationMode,
) {
  // The user didn't choose any type to export
  if (!exportObject.id) {
    return;
  }

  const overlay = createOverlay('Exporting the image...');
  const fileName = `${project.id}_${NormalisedValueName[normalisationMode].replace(/\s+/g, '-')}_${exportObject.label}`;

  if (exportObject.type === 'chart') {
    const padding =
      exportObject.plotType === 'heat' ? { left: 130, bottom: 60, right: 1 } : { left: 0, bottom: -10, right: 5 };
    const element = document.getElementById(exportObject.id);
    if (!(element instanceof SVGElement)) {
      throw new Error(`Element with id ${exportObject.id} is not an SVGElement`);
    }
    // When you want to preview the element on the page to check it, instead of downloading it, use keepElement: true
    try {
      const dataUrl = await getImageDataUrlFromSVG(element, exportObject.plotType, { padding, keepElement: false });
      if (dataUrl) {
        downloadDataUrl(dataUrl, `${fileName}.png`);
      }
    } catch (error) {
      console.error('Error exporting SVG:', error);
    } finally {
      document.body.removeChild(overlay);
    }
  } else {
    const element = document.getElementById(exportObject.id) as HTMLElement;
    try {
      const dataUrl = await getImageDataUrlFromHtml(element, true);
      if (dataUrl) {
        downloadDataUrl(dataUrl, `${fileName}.png`);
      }
    } catch (error) {
      console.error('Error exporting PNG:', error);
    } finally {
      document.body.removeChild(overlay);
    }
  }
}

function UserAccessListItem({ user, project }: { user: User; project: Project }) {
  const { send } = useDeleteUserProject(user.id, project.id);
  return (
    <ListItem>
      {user.firstName} {user.lastName} <DeleteIcon name={`${user.firstName}'s access`} triggerDelete={send} />
    </ListItem>
  );
}
function OrganizationAccessListItem({ organization, project }: { organization: Organisation; project: Project }) {
  const { send } = useDeleteOrganizationProject(organization.id, project.id);
  return (
    <ListItem>
      {organization.name}{' '}
      {organization.id !== resistomapOrganizationId ? (
        <DeleteIcon name={`${organization.name}'s access`} triggerDelete={send} />
      ) : null}
    </ListItem>
  );
}

function Warnings({ warnings }: { warnings: string[] | undefined }) {
  return (
    <div style={{ marginBottom: '16px' }}>
      {warnings?.length ? warnings.map((warning, key) => <div key={key}> {warning}</div>) : 'None'}
    </div>
  );
}

function createOverlay(textContent: string) {
  const overlayElement = document.createElement('div');
  overlayElement.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  `;

  const content = document.createElement('div');
  content.style.cssText = `
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  `;

  content.textContent = textContent;

  overlayElement.appendChild(content);
  document.body.appendChild(overlayElement);

  return overlayElement;
}
