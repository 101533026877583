import { PartialDict, Sample } from '@resistapp/common/types';
import { isNil, uniq } from 'lodash';
import { z } from 'zod';

/*  
    EnvironmentType represents the high-level category of the sampled environment, indicating the origin process of the sample.
   
    NOTE WHEN UPDATING EnvironmentTypes, THEIR NAMES OR SUB-TYPE MAPPINGS!
    - Migrate existing values in database if renaming types
    - Upadte EnvironmentType, getParentType, friendlyEnvSubType and friendlyEnvironmentType
    - Update Customer template 'Sample types™ sheet and named ranges
    - Add backward compatibility logic for analysing older sheets
    - Add backward compatibility logic for query params (getEnvironmentTypeParams)
    - Update sample-form-validaiton.gs (and its active version in sheets, might not be used?)
    - Update comparableGroupSpecs if needed
*/
export enum EnvironmentType {
  WASTEWATER = 'WASTEWATER',
  NATURAL_WATER = 'NATURAL_WATER',
  OTHER_WATER = 'OTHER_WATER',
  SEDIMENT = 'SEDIMENT',
  SLUDGE = 'SLUDGE',
  SOIL = 'SOIL',
  STOOL = 'STOOL',
  MANURE = 'MANURE',
  FOOD = 'FOOD',
  CONTROL = 'CONTROL',
  OTHER = 'OTHER',
}

export const zEnvironmentType = z.nativeEnum(EnvironmentType);

export const envTypeByName: PartialDict<EnvironmentType> = Object.fromEntries(
  Object.entries(EnvironmentType).map(([key, value]) => [friendlyEnvironmentType(value), key as EnvironmentType]),
);
export const EnvironmentTypeWithAllAndMatching = {
  ...EnvironmentType,
  ALL: 'ALL',
} as const;

export enum EnvSubTypePostFix {
  _RAW = '_RAW',
  _TREATED = '_TREATED',
  _UPSTREAM = '_UPSTREAM',
  _DOWNSTREAM = '_DOWNSTREAM',
}

export const beforeSubtypePostfixes = [EnvSubTypePostFix._RAW, EnvSubTypePostFix._UPSTREAM];
export const afterSubtypePostfixes = [EnvSubTypePostFix._TREATED, EnvSubTypePostFix._DOWNSTREAM];

export function getEnvSubtypePoolingKey(sample: Sample): string {
  const { lat, lon, environment } = sample;
  const key = getEnvSubTypeBaseString(environment.subtype);
  const poolById = isNil(lat) || isNil(lon) || isNil(environment.subtype) || key === environment.subtype;
  if (poolById && !sample.environment.id) {
    throw new Error(`Sample ${sample.id} missing environment id in getEnvSubtypePoolingKey`);
  }
  return poolById ? `${sample.environment.id}` : `${key}-${lat}-${lon}`;
}

export function getEnvSubTypeBaseString(envSubType: EnvironmentSubType | undefined): string | undefined {
  const postfixPattern = Object.values(EnvSubTypePostFix).join('|');
  const regex = new RegExp(`(${postfixPattern})$`, 'g');
  return envSubType?.replace(regex, '');
}

export function getSiteCountFromSamples(samples: Sample[]) {
  return uniq(Object.values(samples).map(sample => getEnvSubtypePoolingKey(sample))).length;
}

export function isBeforeSample(sample: { environment: { subtype?: EnvironmentSubType } }): boolean {
  return (
    !!sample.environment.subtype?.endsWith(EnvSubTypePostFix._RAW) ||
    !!sample.environment.subtype?.endsWith(EnvSubTypePostFix._UPSTREAM)
  );
}

export function isAfterSample(sample: { environment: { subtype?: EnvironmentSubType } }): boolean {
  return (
    !!sample.environment.subtype?.endsWith(EnvSubTypePostFix._TREATED) ||
    !!sample.environment.subtype?.endsWith(EnvSubTypePostFix._DOWNSTREAM)
  );
}

/*
    EnvironmentSubType represents lower-level category of the sampled environment, indicating the ORIGIN PROCESS of the sample.
    EnvironmentSubType is a child of EnvironmentType in a is-a hierachy, and sub types should represesnt MECE subsets of the parent types.

    NOTE WHEN UPDATING EnvironmentSubTypes, THEIR NAMES OR TYPE MAPPINGS!
    - _RAW, _TREATED, _UPSTREAM, _DOWNSTREAM suffixes are expected for pooling samples for process performance evaluation (see: EnvSubTypePostFix)
    - Update comparableGroupSpecs if needed
    - Update EnvironmentSubType, getParentType and friendlyEnvSubType
    - Update Customer template 'Sample types™ sheet

    TODO: add links between environments (instead of _raw/_treated, _upstream/_downstream)
    TODO: add a separate field for intended use (next steps in the process)
*/
export enum EnvironmentSubType {
  // WASTEWATER
  HOSPITAL_WASTEWATER_RAW = 'HOSPITAL_WASTEWATER_RAW', // clinical
  HOSPITAL_WASTEWATER_TREATED = 'HOSPITAL_WASTEWATER_TREATED',
  PRIMARY_HEALTH_CARE_RAW = 'PRIMARY_HEALTH_CARE_RAW', // clinical, Clinic / terverysasema like mehiläinen or kalasatama
  PRIMARY_HEALTH_CARE_TREATED = 'PRIMARY_HEALTH_CARE_TREATED',
  URBAN_WASTEWATER_RAW = 'URBAN_WASTEWATER_RAW', // population
  URBAN_WASTEWATER_TREATED = 'URBAN_WASTEWATER_TREATED',
  INDUSTRIAL_WASTEWATER_RAW = 'INDUSTRIAL_WASTEWATER_RAW',
  INDUSTRIAL_WASTEWATER_TREATED = 'INDUSTRIAL_WASTEWATER_TREATED',
  AGRICULTURAL_WASTEWATER_RAW = 'AGRICULTURAL_WASTEWATER_RAW',
  AGRICULTURAL_WASTEWATER_TREATED = 'AGRICULTURAL_WASTEWATER_TREATED',
  SEPTIC_TANK_RAW = 'SEPTIC_TANK_RAW', // population
  SEPTIC_TANK_TREATED = 'SEPTIC_TANK_TREATED',
  GRAY_WATER = 'GRAY_WATER',
  BLACK_WATER = 'BLACK_WATER', // population
  STORMWATER = 'STORMWATER', // Monsuun / melting snow
  URBAN_RUNOFF = 'URBAN_RUNOFF', // population, mix off, stormwater, irrigation water runoff, washwater, oil, fuel chemical spills and leaks, sanitary sewer overflows, ground water infiltration, construction runoff, residential and commercial runoff
  UNSPECIFIED_SEWAGE_WASTEWATER = 'UNSPECIFIED_SEWAGE_WASTEWATER',
  OTHER_WASTEWATER = 'OTHER_WASTEWATER',

  // NATURAL_WATER
  RAINWATER = 'RAINWATER', // without touching the ground
  GROUNDWATER = 'GROUNDWATER',
  SEAWATER = 'SEAWATER',
  UNSPECIFIED_SURFACE_WATER_UPSTREAM = 'UNSPECIFIED_SURFACE_WATER_UPSTREAM', // Only for old samples, not on sheet
  UNSPECIFIED_SURFACE_WATER_DOWNSTREAM = 'UNSPECIFIED_SURFACE_WATER_DOWNSTREAM', // Only for old samples, not on sheet
  BRACKISH_WATER_UPSTREAM = 'BRACKISH_WATER_UPSTREAM',
  BRACKISH_WATER_DOWNSTREAM = 'BRACKISH_WATER_DOWNSTREAM',
  COASTAL_WATER_UPSTREAM = 'COASTAL_WATER_UPSTREAM',
  COASTAL_WATER_DOWNSTREAM = 'COASTAL_WATER_DOWNSTREAM',
  FRESH_WATER_UPSTREAM = 'FRESH_WATER_UPSTREAM', // Streaming fresh water w/o or before dumping anything into it (within the project consideration)
  FRESH_WATER_DOWNSTREAM = 'FRESH_WATER_DOWNSTREAM',
  FRESH_LAKE_WATER = 'FRESH_LAKE_WATER',
  SPRING_WATER = 'SPRING_WATER',
  WETLAND_WATER = 'WETLAND_WATER',

  // OTHER_WATER
  INDUSTRIAL_WATER = 'INDUSTRIAL_WATER',
  DRINKING_WATER = 'DRINKING_WATER',
  RECLAIMED_WATER = 'RECLAIMED_WATER',

  // SEDIMENT
  SEDIMENT_RIVER = 'SEDIMENT_RIVER',
  SEDIMENT_LAKE = 'SEDIMENT_LAKE',
  SEDIMENT_BRACKISH = 'SEDIMENT_BRACKISH',
  SEDIMENT_SEABED = 'SEDIMENT_SEABED',

  // SOIL
  AGRICULTURAL_SOIL = 'AGRICULTURAL_SOIL',
  URBAN_SOIL = 'URBAN_SOIL',
  FOREST_SOIL = 'FOREST_SOIL',
  OTHER_NATURAL_SOIL = 'OTHER_NATURAL_SOIL',
  SAND = 'SAND',
  OTHER_SOIL = 'OTHER_SOIL',

  // STOOL - fresh excrement collected directly from the animals
  HUMAN_STOOL = 'HUMAN_STOOL',
  WILD_ANIMAL_STOOL = 'WILD_ANIMAL_STOOL',
  CAT_STOOL = 'CAT_STOOL',
  DOG_STOOL = 'DOG_STOOL',
  OTHER_COMPANION_ANIMAL_STOOL = 'OTHER_COMPANION_ANIMAL_STOOL',
  PRODUCTION_CATTLE_STOOL = 'PRODUCTION_CATTLE_STOOL',
  PRODUCTION_SWINE_STOOL = 'PRODUCTION_SWINE_STOOL',
  PRODUCTION_POULTRY_STOOL = 'PRODUCTION_POULTRY_STOOL',
  PRODUCTION_SHEEP_STOOL = 'PRODUCTION_SHEEP_STOOL',
  PRODUCTION_ANIMAL_OTHER_STOOL = 'PRODUCTION_ANIMAL_OTHER_STOOL',

  // MANURE - collected, stored, or treated excrement possibly mixed with urine or ground
  CATTLE_MANURE = 'CATTLE_MANURE',
  SWINE_MANURE = 'SWINE_MANURE',
  SHEEP_MANURE = 'SHEEP_MANURE',
  POULTRY_MANURE = 'POULTRY_MANURE',
  OTHER_MANURE = 'OTHER_MANURE',

  // SLUDGE
  ACTIVATED_SLUDGE = 'ACTIVATED_SLUDGE',
  SEWAGE_SLUDGE = 'SEWAGE_SLUDGE',

  // FOOD
  BEEF = 'BEEF',
  PORK = 'PORK',
  POULTRY_MEAT = 'POULTRY_MEAT',
  FISH = 'FISH',
  SHELLFISH = 'SHELLFISH',
  MEAT_OTHER = 'MEAT_OTHER',
  DAIRY = 'DAIRY',
  FRUITS = 'FRUITS',
  VEGETABLES = 'VEGETABLES',
  LEGUMES = 'LEGUMES',
  OTHER_PLANT_BASED_FOOD = 'PLANT_BASED_OTHER',
  FOOD_OTHER = 'FOOD_OTHER',

  // CONTROL
  NTC = 'NTC',
  POSITIVE_CONTROL = 'POSITIVE_CONTROL',
  OTHER_CONTROL = 'OTHER_CONTROL',

  // OTHER
  ISOLATE = 'ISOLATE',
  ENVIRONMENTAL_SWAB = 'ENVIRONMENTAL_SWAB',
  ANIMAL_FEED = 'ANIMAL_FEED',
  COMPOST = 'COMPOST',
  DUST = 'DUST',
}

export const zEnvironmentSubType = z.nativeEnum(EnvironmentSubType);
export const envSubtypeByName: PartialDict<EnvironmentType> = Object.fromEntries(
  Object.entries(EnvironmentSubType).map(([key, value]) => [
    friendlyEnvSubType(value, getParentType(value)),
    key as EnvironmentType,
  ]),
);

export function assertEnvTypes(subType: EnvironmentSubType | null | undefined, type: EnvironmentType) {
  if (!isLegalSubtype(subType, type)) {
    throw new Error(`Invalid environment subtype ${subType} for type ${type}`);
  }
}

export function getParentType(subType: EnvironmentSubType): EnvironmentType {
  switch (subType) {
    case EnvironmentSubType.HOSPITAL_WASTEWATER_RAW:
    case EnvironmentSubType.HOSPITAL_WASTEWATER_TREATED:
    case EnvironmentSubType.PRIMARY_HEALTH_CARE_RAW:
    case EnvironmentSubType.PRIMARY_HEALTH_CARE_TREATED:
    case EnvironmentSubType.URBAN_WASTEWATER_RAW:
    case EnvironmentSubType.URBAN_WASTEWATER_TREATED:
    case EnvironmentSubType.INDUSTRIAL_WASTEWATER_RAW:
    case EnvironmentSubType.INDUSTRIAL_WASTEWATER_TREATED:
    case EnvironmentSubType.AGRICULTURAL_WASTEWATER_RAW:
    case EnvironmentSubType.AGRICULTURAL_WASTEWATER_TREATED:
    case EnvironmentSubType.GRAY_WATER:
    case EnvironmentSubType.BLACK_WATER:
    case EnvironmentSubType.SEPTIC_TANK_RAW:
    case EnvironmentSubType.SEPTIC_TANK_TREATED:
    case EnvironmentSubType.STORMWATER:
    case EnvironmentSubType.UNSPECIFIED_SEWAGE_WASTEWATER:
    case EnvironmentSubType.URBAN_RUNOFF:
    case EnvironmentSubType.OTHER_WASTEWATER:
      return EnvironmentType.WASTEWATER;
    case EnvironmentSubType.RAINWATER:
    case EnvironmentSubType.GROUNDWATER:
    case EnvironmentSubType.SEAWATER:
    case EnvironmentSubType.UNSPECIFIED_SURFACE_WATER_UPSTREAM:
    case EnvironmentSubType.UNSPECIFIED_SURFACE_WATER_DOWNSTREAM:
    case EnvironmentSubType.BRACKISH_WATER_UPSTREAM:
    case EnvironmentSubType.BRACKISH_WATER_DOWNSTREAM:
    case EnvironmentSubType.COASTAL_WATER_UPSTREAM:
    case EnvironmentSubType.COASTAL_WATER_DOWNSTREAM:
    case EnvironmentSubType.FRESH_WATER_UPSTREAM:
    case EnvironmentSubType.FRESH_WATER_DOWNSTREAM:
    case EnvironmentSubType.FRESH_LAKE_WATER:
    case EnvironmentSubType.SPRING_WATER:
    case EnvironmentSubType.WETLAND_WATER:
      return EnvironmentType.NATURAL_WATER;
    case EnvironmentSubType.INDUSTRIAL_WATER:
    case EnvironmentSubType.DRINKING_WATER:
    case EnvironmentSubType.RECLAIMED_WATER:
      return EnvironmentType.OTHER_WATER;
    case EnvironmentSubType.SEDIMENT_RIVER:
    case EnvironmentSubType.SEDIMENT_LAKE:
    case EnvironmentSubType.SEDIMENT_BRACKISH:
    case EnvironmentSubType.SEDIMENT_SEABED:
      return EnvironmentType.SEDIMENT;
    case EnvironmentSubType.AGRICULTURAL_SOIL:
    case EnvironmentSubType.URBAN_SOIL:
    case EnvironmentSubType.FOREST_SOIL:
    case EnvironmentSubType.SAND:
    case EnvironmentSubType.OTHER_NATURAL_SOIL:
    case EnvironmentSubType.OTHER_SOIL:
      return EnvironmentType.SOIL;
    case EnvironmentSubType.HUMAN_STOOL:
    case EnvironmentSubType.WILD_ANIMAL_STOOL:
    case EnvironmentSubType.CAT_STOOL:
    case EnvironmentSubType.DOG_STOOL:
    case EnvironmentSubType.OTHER_COMPANION_ANIMAL_STOOL:
    case EnvironmentSubType.PRODUCTION_CATTLE_STOOL:
    case EnvironmentSubType.PRODUCTION_SWINE_STOOL:
    case EnvironmentSubType.PRODUCTION_POULTRY_STOOL:
    case EnvironmentSubType.PRODUCTION_SHEEP_STOOL:
    case EnvironmentSubType.PRODUCTION_ANIMAL_OTHER_STOOL:
      return EnvironmentType.STOOL;
    case EnvironmentSubType.CATTLE_MANURE:
    case EnvironmentSubType.SWINE_MANURE:
    case EnvironmentSubType.SHEEP_MANURE:
    case EnvironmentSubType.POULTRY_MANURE:
    case EnvironmentSubType.OTHER_MANURE:
      return EnvironmentType.MANURE;
    case EnvironmentSubType.ACTIVATED_SLUDGE:
    case EnvironmentSubType.SEWAGE_SLUDGE:
      return EnvironmentType.SLUDGE;
    case EnvironmentSubType.BEEF:
    case EnvironmentSubType.PORK:
    case EnvironmentSubType.POULTRY_MEAT:
    case EnvironmentSubType.DAIRY:
    case EnvironmentSubType.FISH:
    case EnvironmentSubType.SHELLFISH:
    case EnvironmentSubType.FRUITS:
    case EnvironmentSubType.VEGETABLES:
    case EnvironmentSubType.LEGUMES:
    case EnvironmentSubType.OTHER_PLANT_BASED_FOOD:
    case EnvironmentSubType.MEAT_OTHER:
    case EnvironmentSubType.FOOD_OTHER:
      return EnvironmentType.FOOD;
    case EnvironmentSubType.NTC:
    case EnvironmentSubType.POSITIVE_CONTROL:
    case EnvironmentSubType.OTHER_CONTROL:
      return EnvironmentType.CONTROL;
    case EnvironmentSubType.ISOLATE:
    case EnvironmentSubType.ENVIRONMENTAL_SWAB:
    case EnvironmentSubType.ANIMAL_FEED:
    case EnvironmentSubType.COMPOST:
    case EnvironmentSubType.DUST:
      return EnvironmentType.OTHER;
  }
}

/*
    NOTE WHEN UPDATING EnvironmentSubTypes, THEIR NAMES OR TYPE MAPPINGS!
    - _RAW, _TREATED, _UPSTREAM, _DOWNSTREAM suffixes are expected for pooling samples for process performance evaluation (see: EnvSubTypePostFix)
    - Update comparableGroupSpecs if needed
    - Update EnvironmentSubType, getParentType and friendlyEnvSubType
    - Update Customer template 'Sample types™ sheet
*/
export function isLegalSubtype(subType: EnvironmentSubType | null | undefined, type: EnvironmentType): boolean {
  if (isNil(subType)) {
    return true;
  }
  const expectedType = getParentType(subType);
  return type === expectedType;
}

export function friendlyEnvSubTypeOrType(
  subType: EnvironmentSubType | null | undefined,
  type: EnvironmentType,
): string {
  if (subType) {
    return friendlyEnvSubType(subType, type);
  }
  return friendlyEnvironmentType(type);
}

/*
    NOTE WHEN UPDATING EnvironmentSubTypes, THEIR NAMES OR TYPE MAPPINGS!
    - _RAW, _TREATED, _UPSTREAM, _DOWNSTREAM suffixes are expected for pooling samples for process performance evaluation (see: EnvSubTypePostFix)
    - Update comparableGroupSpecs if needed
    - Update EnvironmentSubType, getParentType and friendlyEnvSubType
    - Update Customer template 'Sample types™ sheet
*/
export function friendlyEnvSubType(subType: EnvironmentSubType | null | undefined, _type?: EnvironmentType): string {
  const type = _type ?? (subType && getParentType(subType));
  if (!type) {
    throw new Error('No type or subtype provided');
  }
  switch (subType) {
    // WASTEWATER
    case EnvironmentSubType.HOSPITAL_WASTEWATER_RAW:
      return 'Hospital wastewater (raw)'; // clinical (though has feces)
    case EnvironmentSubType.HOSPITAL_WASTEWATER_TREATED:
      return 'Hospital wastewater (treated)';
    case EnvironmentSubType.PRIMARY_HEALTH_CARE_RAW:
      return 'Primary health care wastewater (raw)';
    case EnvironmentSubType.PRIMARY_HEALTH_CARE_TREATED:
      return 'Primary health care wastewater (treated)';
    case EnvironmentSubType.URBAN_WASTEWATER_RAW:
      return 'Urban wastewater (raw)'; // public health / human feces
    case EnvironmentSubType.URBAN_WASTEWATER_TREATED:
      return 'Urban wastewater (treated)';
    case EnvironmentSubType.INDUSTRIAL_WASTEWATER_RAW:
      return 'Industrial wastewater (raw)';
    case EnvironmentSubType.INDUSTRIAL_WASTEWATER_TREATED:
      return 'Industrial wastewater (treated)';
    case EnvironmentSubType.AGRICULTURAL_WASTEWATER_RAW:
      return 'Agricultural wastewater (raw)';
    case EnvironmentSubType.AGRICULTURAL_WASTEWATER_TREATED:
      return 'Agricultural wastewater (treated)';
    case EnvironmentSubType.GRAY_WATER:
      return 'Gray water';
    case EnvironmentSubType.BLACK_WATER:
      return 'Black water'; // public health / human feces
    case EnvironmentSubType.SEPTIC_TANK_RAW:
      return 'Septic tank (initial compartment)'; // public health / human feces
    case EnvironmentSubType.SEPTIC_TANK_TREATED:
      return 'Septic tank (final compartment)';
    case EnvironmentSubType.STORMWATER:
      return 'Stormwater'; // maybe (no)
    case EnvironmentSubType.URBAN_RUNOFF:
      return 'Urban runoff'; // public health / human feces
    case EnvironmentSubType.UNSPECIFIED_SEWAGE_WASTEWATER:
      return 'Unspecified sewage wastewater';
    case EnvironmentSubType.OTHER_WASTEWATER:
      return 'Other wastewater';
    // NATURAL WATER
    case EnvironmentSubType.RAINWATER:
      return 'Rainwater';
    case EnvironmentSubType.GROUNDWATER:
      return 'Groundwater';
    case EnvironmentSubType.BRACKISH_WATER_UPSTREAM:
      return 'Brackish water (w/o WW discharge)';
    case EnvironmentSubType.BRACKISH_WATER_DOWNSTREAM:
      return 'Brackish water (with WW discharge)';
    case EnvironmentSubType.SEAWATER:
      return 'Seawater';
    case EnvironmentSubType.COASTAL_WATER_UPSTREAM:
      return 'Coastal water (w/o WW discharge)';
    case EnvironmentSubType.COASTAL_WATER_DOWNSTREAM:
      return 'Coastal water (after WW discharge)';
    case EnvironmentSubType.FRESH_LAKE_WATER:
      return 'Lake or pond water';
    case EnvironmentSubType.FRESH_WATER_UPSTREAM:
      return 'River or stream water (w/o WW discharge)';
    case EnvironmentSubType.FRESH_WATER_DOWNSTREAM:
      return 'River or stream water (after WW discharge)';
    case EnvironmentSubType.SPRING_WATER:
      return 'Spring water';
    case EnvironmentSubType.UNSPECIFIED_SURFACE_WATER_UPSTREAM:
      return 'Surface water';
    case EnvironmentSubType.UNSPECIFIED_SURFACE_WATER_DOWNSTREAM:
      return 'Surface water (with WW discharge)';
    case EnvironmentSubType.WETLAND_WATER:
      return 'Wetland water';
    // OTHER WATER
    case EnvironmentSubType.INDUSTRIAL_WATER:
      return 'Industrial water';
    case EnvironmentSubType.DRINKING_WATER:
      return 'Drinking water';
    case EnvironmentSubType.RECLAIMED_WATER:
      return 'Reclaimed or recycled water';
    // SEDIMENT
    case EnvironmentSubType.SEDIMENT_RIVER:
      return 'River sediment';
    case EnvironmentSubType.SEDIMENT_LAKE:
      return 'Lake sediment';
    case EnvironmentSubType.SEDIMENT_BRACKISH:
      return 'Brackish water sediment';
    case EnvironmentSubType.SEDIMENT_SEABED:
      return 'Seabed sediment';
    // SOIL
    case EnvironmentSubType.AGRICULTURAL_SOIL:
      return 'Agricultural soil';
    case EnvironmentSubType.URBAN_SOIL:
      return 'Urban soil';
    case EnvironmentSubType.FOREST_SOIL:
      return 'Forest soil';
    case EnvironmentSubType.OTHER_NATURAL_SOIL:
      return 'Other natural soil';
    case EnvironmentSubType.SAND:
      return 'Sand';
    case EnvironmentSubType.OTHER_SOIL:
      return 'Other soil';
    // STOOL
    case EnvironmentSubType.HUMAN_STOOL:
      return 'Human stool';
    case EnvironmentSubType.WILD_ANIMAL_STOOL:
      return 'Wild animal stool';
    case EnvironmentSubType.CAT_STOOL:
      return 'Cat stool';
    case EnvironmentSubType.DOG_STOOL:
      return 'Dog stool';
    case EnvironmentSubType.OTHER_COMPANION_ANIMAL_STOOL:
      return 'Other companion animal stool';
    case EnvironmentSubType.PRODUCTION_CATTLE_STOOL:
      return 'Production cattle stool';
    case EnvironmentSubType.PRODUCTION_SWINE_STOOL:
      return 'Production swine stool';
    case EnvironmentSubType.PRODUCTION_POULTRY_STOOL:
      return 'Production poultry stool';
    case EnvironmentSubType.PRODUCTION_SHEEP_STOOL:
      return 'Production sheep stool';
    case EnvironmentSubType.PRODUCTION_ANIMAL_OTHER_STOOL:
      return 'Production animal stool (other)';
    // MANURE
    case EnvironmentSubType.CATTLE_MANURE:
      return 'Cattle manure';
    case EnvironmentSubType.SWINE_MANURE:
      return 'Swine manure';
    case EnvironmentSubType.SHEEP_MANURE:
      return 'Sheep manure';
    case EnvironmentSubType.POULTRY_MANURE:
      return 'Poultry manure';
    case EnvironmentSubType.OTHER_MANURE:
      return 'Other manure';
    // SLUDGE
    case EnvironmentSubType.ACTIVATED_SLUDGE:
      return 'Activated sludge';
    case EnvironmentSubType.SEWAGE_SLUDGE:
      return 'Sewage sludge';
    // Food
    case EnvironmentSubType.BEEF:
      return 'Beef';
    case EnvironmentSubType.PORK:
      return 'Pork';
    case EnvironmentSubType.POULTRY_MEAT:
      return 'Poultry meat';
    case EnvironmentSubType.DAIRY:
      return 'Dairy';
    case EnvironmentSubType.FISH:
      return 'Fish';
    case EnvironmentSubType.SHELLFISH:
      return 'Shellfish';
    case EnvironmentSubType.FRUITS:
      return 'Fruits';
    case EnvironmentSubType.VEGETABLES:
      return 'Vegetables';
    case EnvironmentSubType.LEGUMES:
      return 'Legumes';
    case EnvironmentSubType.OTHER_PLANT_BASED_FOOD:
      return 'Plant based (other)';
    case EnvironmentSubType.MEAT_OTHER:
      return 'Meat (other)';
    case EnvironmentSubType.FOOD_OTHER:
      return 'Food (other)';
    // Control
    case EnvironmentSubType.NTC:
      return 'No template control';
    case EnvironmentSubType.POSITIVE_CONTROL:
      return 'Positive control';
    case EnvironmentSubType.OTHER_CONTROL:
      return 'Other control';
    // Other
    case EnvironmentSubType.ISOLATE:
      return 'Isolate';
    case EnvironmentSubType.ENVIRONMENTAL_SWAB:
      return 'Environmental swab';
    case EnvironmentSubType.COMPOST:
      return 'Compost';
    case EnvironmentSubType.ANIMAL_FEED:
      return 'Animal feed';
    case EnvironmentSubType.DUST:
      return 'Dust';
    case undefined:
    case null:
      return friendlyEnvironmentType(type);
  }
}

export function friendlyEnvironmentType(type: EnvironmentType): string {
  switch (type) {
    case EnvironmentType.WASTEWATER:
      return 'Wastewater';
    case EnvironmentType.NATURAL_WATER:
      return 'Water (natural)';
    case EnvironmentType.OTHER_WATER:
      return 'Water (other)';
    case EnvironmentType.SEDIMENT:
      return 'Sediment';
    case EnvironmentType.SLUDGE:
      return 'Sludge';
    case EnvironmentType.SOIL:
      return 'Soil';
    case EnvironmentType.STOOL:
      return 'Stool';
    case EnvironmentType.MANURE:
      return 'Manure';
    case EnvironmentType.FOOD:
      return 'Food';
    case EnvironmentType.CONTROL:
      return 'Lab control';
    case EnvironmentType.OTHER:
      return 'Other';
  }
}
