import { binColor } from '@resistapp/client/components/plots/legends/heatmap-bins';
import { PlotTooltip, usePlotTooltip } from '@resistapp/client/components/tooltips/plot-tooltip';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { BarDatum, getBarDatumKeys } from '@resistapp/client/data-utils/plot-data/research-plot-data';
import { useFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { getKeyPressesFromMouseClick } from '@resistapp/client/utils/general';
import { GeneGrouping, Target } from '@resistapp/common/assays';
import { BarStack } from '@visx/shape';
import { Fragment } from 'react/jsx-runtime';
import { theme } from '../../shared/theme';
import { GeneBarStackProps, getGroupItemWidthAndMargin } from './data-groups';
import { ScaleBand, ScaleTime } from './multi-plot';
import { BarTooltipData, HeatmapTooltipContent, NumDetectedTooltipContent, getAbundace } from './tooltips';
import {
  getEnvironmentDatas,
  getSampleSelector,
  getSelectedEnvironmentIds,
  getSemiTransparentColorFromScale,
} from './utils';

export function GenesBarStack(props: GeneBarStackProps) {
  const {
    datum,
    geneGroups,
    xScale,
    yScale,
    groupWidth,
    groupLength,
    currentGroupLength,
    colorScale,
    isHeatmap,
    sortIdxByGene,
    totalGroupSize,
    bioRepCount,
    j,
    i,
    singleGeneHeatBoxHeight,
    againstTime,
    showSampleNumbers,
    project,
  } = props;
  const { queryFilters, samplesBeingSelected, setSamplesBeingSelected } = useResearchContext();
  const { groupLeftMargin, itemWidth } = getGroupItemWidthAndMargin(groupWidth, groupLength, currentGroupLength);
  const keys = isHeatmap ? getBarDatumKeys(datum) : geneGroups;
  // This checks whether the chart can fit the gene names next to it, in the whitespace.
  // totalGroupSize * 145 is the scaling width of the gene names, and window.innerWidth - 430 is the starting size.
  const fitsInChart = totalGroupSize * 177 < window.innerWidth - 458;
  const isLastAndTimed = againstTime && i === totalGroupSize - 1;
  const showGeneNames =
    isHeatmap && singleGeneHeatBoxHeight === 10 && fitsInChart && j === bioRepCount - 1 && !isLastAndTimed;
  const { environmentNames, nameById } = getEnvironmentDatas(
    project.samplesByUID,
    queryFilters.filters.selectedEnvironmentTypeGroup,
  );
  const onClick = getSampleSelector(
    showSampleNumbers,
    queryFilters.filters.selectedEnvironmentNamesOrdered as Target[],
    environmentNames as Target[],
    samplesBeingSelected,
    setSamplesBeingSelected,
    queryFilters.toggleEnvironment,
    getSelectedEnvironmentIds(queryFilters.filters.selectedEnvironmentNamesOrdered, nameById),
    true,
    false,
  );

  return (
    <>
      <BarStack<BarDatum, string>
        data={[datum]}
        keys={keys}
        x={_ => undefined /* calculated below */}
        xScale={xScale}
        yScale={yScale}
        color={
          samplesBeingSelected.ids.includes(datum.label) ? getSemiTransparentColorFromScale(colorScale) : colorScale
        }
      >
        {stackRectangles =>
          stackRectangles.map((rectangle, index) =>
            rectangle.bars.map(stackDatum => (
              <Fragment key={`brect${rectangle.index}-${rectangle.key}`}>
                {/* This is a rectangle that is full height and resides under the other rects. It contains a tooltip 
              with total number of detected genes, making empty samples more noticable */}
                {index === 0 && !isHeatmap && (
                  <BarRect
                    {...props}
                    height={277}
                    y={-1}
                    groupLeftMargin={groupLeftMargin}
                    itemWidth={itemWidth}
                    stackDatum={stackDatum}
                    rectIdx={sortIdxByGene[rectangle.key]}
                    color="transparent"
                    general={true}
                  />
                )}
                <BarRect
                  {...props}
                  height={isHeatmap ? singleGeneHeatBoxHeight : stackDatum.height}
                  groupLeftMargin={groupLeftMargin}
                  itemWidth={itemWidth}
                  stackDatum={stackDatum}
                  rectIdx={sortIdxByGene[rectangle.key]}
                  onClick={e => {
                    onClick(datum.label, getKeyPressesFromMouseClick(e));
                  }}
                />
              </Fragment>
            )),
          )
        }
      </BarStack>
      {showGeneNames && (
        <BarStack<BarDatum, string>
          data={[datum]}
          keys={keys}
          x={_ => undefined /* calculated below */}
          xScale={xScale}
          yScale={yScale}
          color={
            samplesBeingSelected.ids.includes(datum.label) ? getSemiTransparentColorFromScale(colorScale) : colorScale
          }
        >
          {stackRectangles =>
            stackRectangles.map(rectangle =>
              rectangle.bars.map(stackDatum => (
                <BarRectName
                  key={`brect${rectangle.index}-${rectangle.key}`}
                  {...props}
                  height={singleGeneHeatBoxHeight}
                  groupLeftMargin={groupLeftMargin}
                  itemWidth={itemWidth}
                  stackDatum={stackDatum}
                  rectIdx={sortIdxByGene[rectangle.key]}
                  bioRepCount={bioRepCount}
                />
              )),
            )
          }
        </BarStack>
      )}
    </>
  );
}

interface BarRectProps extends GeneBarStackProps {
  groupLeftMargin: number;
  itemWidth: number;
  stackDatum: BarTooltipData;
  rectIdx: number;
  grouping: GeneGrouping;
  height: number;
  y?: number;
  color?: string;
  general?: boolean;
  onClick?: (e: React.MouseEvent<SVGRectElement>) => void;
}

function BarRect(props: BarRectProps) {
  const {
    height,
    rectIdx,
    isHeatmap,
    grouping,
    againstTime,
    i,
    j,
    xScale,
    colorScale,
    ordinalAligmentFixup,
    groupLeftMargin,
    groupWidth,
    itemWidth,
    stackDatum,
    tooltipStuff,
    color,
    y,
    general,
    onClick,
  } = props;
  const { tooltipData, tooltipProps, handleMouseMove } = usePlotTooltip<BarTooltipData>(tooltipStuff);
  const { normalisationMode } = useFilters();
  const scaledX = againstTime
    ? (xScale as ScaleTime)(new Date(new Date(stackDatum.bar.data.date)))
    : (xScale as ScaleBand)(`${i}`);
  const yUpper = y || (isHeatmap ? rectIdx * height : stackDatum.y);
  const x = ordinalAligmentFixup + groupLeftMargin + j * itemWidth + scaledX - groupWidth / 2;
  const localColor = color || (isHeatmap ? binColor(getAbundace(stackDatum), normalisationMode) : stackDatum.color);

  return (
    <>
      <rect
        x={x}
        y={yUpper}
        height={height}
        width={itemWidth}
        fill={localColor}
        onMouseLeave={event => {
          handleMouseMove(event, undefined);
        }}
        onMouseMove={event => {
          handleMouseMove(event, stackDatum);
        }}
        onMouseEnter={event => {
          handleMouseMove(event, stackDatum, true);
        }}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
        onClick={onClick}
      />
      {tooltipData && (
        <PlotTooltip {...tooltipProps}>
          {isHeatmap ? (
            <HeatmapTooltipContent grouping={grouping} tooltipData={tooltipData} colorScale={colorScale} />
          ) : (
            <NumDetectedTooltipContent
              tooltipData={tooltipData}
              colorScale={colorScale}
              grouping={grouping}
              showGeneInfo={!general}
            />
          )}
        </PlotTooltip>
      )}
    </>
  );
}

function BarRectName(props: BarRectProps) {
  const {
    height,
    rectIdx,
    isHeatmap,
    againstTime,
    i,
    xScale,
    ordinalAligmentFixup,
    groupLeftMargin,
    groupWidth,
    itemWidth,
    stackDatum,
    y,
  } = props;
  const scaledX = againstTime
    ? (xScale as ScaleTime)(new Date(new Date(stackDatum.bar.data.date)))
    : (xScale as ScaleBand)(`${i}`);
  const yUpper = y || (isHeatmap ? rectIdx * height : stackDatum.y);
  const x = ordinalAligmentFixup + groupLeftMargin + scaledX - groupWidth / 2 + 51;

  if (stackDatum.key === 'sampleNumber') {
    return null;
  }
  return (
    <text x={x} y={yUpper + 7} height={height} width={itemWidth} fill={theme.colors.neutral500} fontSize={10}>
      {stackDatum.key}
    </text>
  );
}
