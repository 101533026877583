import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { curveLinear } from '@vx/curve';
import { EventType } from '@vx/event/lib/types';
import { isNil } from 'lodash';
import { Fragment, useState } from 'react';
import { theme } from '../../../shared/theme';

export type OnHover = (event: EventType) => void;

interface Props {
  data: OverviewDatum[];
  timeScale: (value: Date | number) => number | undefined;
  valueScale: (value: OverviewDatum) => number | undefined;
  selected: boolean;
  mouseMoveHandler: (data: OverviewDatum[], event: React.MouseEvent<SVGElement>) => void;
  mouseClickHandler: (data: OverviewDatum, event?: React.MouseEvent) => void;
  siteSelected: boolean;
}

export function TrendLineWithCircles(props: Props) {
  const { data, timeScale, valueScale, selected, mouseMoveHandler, mouseClickHandler, siteSelected } = props;
  const { selectedMonth } = useOverviewContext();
  const [isHoveringCircle, setIsHoveringCircle] = useState<number | false>(false);

  const nonNullData = data.filter(d => !isNil(valueScale(d)));

  return (
    <Group className="trend-line-with-circles">
      <LinePath<OverviewDatum>
        curve={curveLinear}
        data={nonNullData}
        x={d => timeScale(new Date(d.date.toString())) ?? 0}
        y={d => valueScale(d) ?? 0}
        stroke={selected ? theme.colors.neutral900 : theme.colors.neutral400}
        strokeWidth={3}
        strokeOpacity={1}
        style={{ pointerEvents: 'visibleStroke' }}
        onMouseMove={event => {
          mouseMoveHandler(data, event);
        }}
      />
      {nonNullData.map((d, j) => (
        <Fragment key={j}>
          <circle
            r={
              isHoveringCircle === j && siteSelected
                ? 10
                : getRadius(d, selected, selectedMonth, selectedMonth === null && j === data.length - 1)
            }
            cx={timeScale(new Date(d.date.toString())) ?? 0}
            cy={valueScale(d) ?? 0}
            fill={selected ? theme.colors.neutral900 : theme.colors.neutral400}
            onMouseMove={event => {
              mouseMoveHandler(nonNullData, event);
            }}
            onClick={event => {
              siteSelected && mouseClickHandler(d, event);
            }}
            onMouseOver={() => {
              siteSelected && setIsHoveringCircle(j);
            }}
            onMouseOut={() => {
              siteSelected && setIsHoveringCircle(false);
            }}
            style={{ cursor: siteSelected ? 'pointer' : 'default' }}
          />
        </Fragment>
      ))}
    </Group>
  );
}

function getRadius(data: OverviewDatum, selected: boolean, selectedMonth: Date | null, isLastPoint: boolean) {
  const isSelectedMonth = selectedMonth?.toISOString() === data.date.toString() || isLastPoint;
  const radiusMultiplier = isSelectedMonth ? 1.7 : 1;

  return selected ? 5 * radiusMultiplier : 4 * radiusMultiplier;
}
