import styled from '@emotion/styled';
import { friendlyMetricValue, getMetricColor } from '@resistapp/client/utils/metric-utils';
import { getOverviewConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { ResistanceLevel } from '@resistapp/common/statistics/resistance-index';
import { ChartUnit, MetricMode } from '@resistapp/common/types';
import { theme } from './theme';

export const OverviewMetricLabel = styled.div<{
  level: ResistanceLevel | null;
  fullWidth?: boolean;
  metricMode: MetricMode;
  metric: number | null;
  activeChartUnit: ChartUnit;
}>`
  position: relative;
  height: 40px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: ${props =>
    props.metric !== null ? getMetricColor(props.metric, props.metricMode, props.activeChartUnit) : 'white'};
  ${_props => _props.metric && theme.addBorder(300, 'small')}
  padding: ${theme.spacing[3]} ${theme.spacing[2]};

  color: ${props =>
    getOverviewConfiguration(props.metricMode, props.activeChartUnit).getFriendlyTextColor(props.metric)};
  font-feature-settings: 'ss01' on;
  font-size: ${theme.fontSize.h3};
  font-weight: ${theme.fontWeight.bold};

  &::after {
    content: '${props => friendlyMetricValue(props.metric, props.metricMode, props.activeChartUnit)}';
  }
`;

export const ResistanceLevelNeutralContainer = styled(OverviewMetricLabel)`
  position: relative;
  background-color: transparent;
  border: 1px solid ${theme.colors.neutral300};
  color: ${theme.colors.neutral500};
`;

export function getChangeIndicatorColor(change: number) {
  return change === 1 ? theme.colors.levelHigh : change === -1 ? theme.colors.levelLow : '#606060';
}
