import { chain, isNil } from 'lodash';
import { NormalisationMode, type FullSamplesByUID, type StrippedFullAbundance } from './types';

export const NormalisationModesAndProperties = {
  [NormalisationMode.MG_SS]: 'copiesPerMgSS',
  [NormalisationMode.HOUR]: 'copiesPerHour',
  [NormalisationMode.LITRE]: 'copiesPerL',
  [NormalisationMode.TEN_UL_DILUTED_DNA]: 'absolute',
  [NormalisationMode.MG_BOD]: 'copiesPerMgBod',
  [NormalisationMode.SIXTEEN_S]: 'relative',
} as const; // satisfies Partial<Record<NormalisationMode, keyof FullAbundance>>;
export const AbsoluteNormalisationModesInPriorityOrder = [
  NormalisationMode.MG_SS,
  NormalisationMode.HOUR,
  NormalisationMode.LITRE,
  NormalisationMode.TEN_UL_DILUTED_DNA,
  NormalisationMode.MG_BOD,
] as const;

export function getBestSupportedNormalisedMode(researchPlotData: FullSamplesByUID | undefined) {
  const abundances = chain(researchPlotData)
    .values()
    .map(samples =>
      chain(samples)
        .map(sample => sample.abundances)
        .flatten()
        .value(),
    )
    .flatten()
    .value();

  return (
    AbsoluteNormalisationModesInPriorityOrder.find(mode => {
      const modeProperty = NormalisationModesAndProperties[mode];
      return (
        abundances.length &&
        abundances.every(abundance => {
          const value = abundance[modeProperty];
          return !isNil(value);
        })
      );
    }) ?? NormalisationMode.TEN_UL_DILUTED_DNA
  );
}

export function isConsideredAbsolute(normalisationMode: NormalisationMode) {
  return normalisationMode !== NormalisationMode.SIXTEEN_S;
}

export function getNormalisedValue(
  abundance: StrippedFullAbundance,
  normalisationMode: keyof typeof NormalisationModesAndProperties,
) {
  const modeProperty = NormalisationModesAndProperties[normalisationMode];
  return abundance[modeProperty];
}

export const NormalisedValueName = {
  [NormalisationMode.SIXTEEN_S]: 'Relative abundance',
  [NormalisationMode.MG_SS]: 'Copy numbers per mg of SS',
  [NormalisationMode.HOUR]: 'Copy numbers per hour',
  [NormalisationMode.LITRE]: 'Copy numbers per L',
  [NormalisationMode.TEN_UL_DILUTED_DNA]: 'Copy numbers',
  [NormalisationMode.MG_BOD]: 'Copy numbers per mg BOD',
} as const;
