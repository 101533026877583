import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { RThirdTitle } from '@resistapp/client/components/headers/r-third-title';
import { getNumberOfDetectedGenesBySampleNumCsv } from '@resistapp/client/components/plots/bar-box-map/bar-data-utils';
import { Grid, GridCell } from '@resistapp/client/components/shared/grid';
import { GridView } from '@resistapp/client/components/shared/layout';
import { theme } from '@resistapp/client/components/shared/theme';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { fetchCorrelations } from '@resistapp/client/hooks/api';
import { useFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { hasOverview, isAdmin, isNonDemo } from '@resistapp/common/features';
import { NormalisedValueName } from '@resistapp/common/normalisation-mode';
import { projectToCsvRows } from '@resistapp/common/project-csv';
import { FullProject, isApproved, Project } from '@resistapp/common/types';
import { csvFormat } from 'd3-dsv';
import { chunk } from 'lodash';
import { DownloadLink } from '../../components/links/download-link';
import { UrlLink } from '../../components/links/url-link';

interface Props {
  sampling: FullProject;
}

export function DownloadAndLinkSection({ sampling }: Props) {
  const { user } = useUser();
  const { normalisationMode } = useFilters();
  const plotFileNames = getExtraFilenames(sampling);
  const hasCtFiles = Object.keys(sampling.qpcrFiles).length > 0;

  return (
    <GridView>
      <RSecondTitle>Downloads and links</RSecondTitle>
      <Grid>
        <GridCell widthpercentage={100}>
          <RThirdTitle>Analysis results</RThirdTitle>
          <DownloadLink
            filename={`${NormalisedValueName[normalisationMode]}.csv`}
            getFileContent={() => csvFormat(projectToCsvRows(sampling, normalisationMode))}
          />
          <DownloadLink
            filename="Numbers of detected genes.csv"
            getFileContent={() => getNumberOfDetectedGenesBySampleNumCsv(sampling.samplesByUID)}
          />
          {plotFileNames.map((filename, key) => (
            <DownloadLink key={`dplot-${key}`} filename={filename} samplingId={sampling.id} />
          ))}
        </GridCell>
        {user && hasCtFiles && (
          <GridCell style={{ paddingTop: theme.oldSpacing.m }} widthpercentage={100}>
            <RThirdTitle>qPCR CT values</RThirdTitle>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {chunk(Object.keys(sampling.qpcrFiles), 4).map((fileChunk, chunkKey) => (
                <div key={`txtchunk${chunkKey}`} style={{ flexDirection: 'column' }}>
                  {fileChunk.map((filename, key) => {
                    return (
                      <DownloadLink key={key} filename={filename} getFileContent={() => sampling.qpcrFiles[filename]} />
                    );
                  })}
                </div>
              ))}
            </div>
          </GridCell>
        )}
        {isNonDemo(user) && !hasOverview(user) && sampling.customerSheetLink && (
          <GridCell style={{ paddingTop: theme.oldSpacing.m }} widthpercentage={100}>
            <RThirdTitle>Project specification</RThirdTitle>
            {isApproved(sampling.status) ? (
              <UrlLink text="Sample and gene specification sheet" href={sampling.customerSheetLink} />
            ) : (
              <span>Specification will be available after the project has been completed</span>
            )}
          </GridCell>
        )}
        {isAdmin(user) && (
          <GridCell style={{ paddingTop: theme.oldSpacing.m }} widthpercentage={100}>
            <RThirdTitle>Internal</RThirdTitle>
            {sampling.customerSheetLink && <UrlLink text="Customer sheet" href={sampling.customerSheetLink} />}
            {sampling.labSheetLink && <UrlLink text="Lab sheet" href={sampling.labSheetLink} />}
            {hasFocusedByUID(sampling) && (
              <DownloadLink
                filename="Correlations.csv"
                getFileContent={async () => await fetchCorrelations(sampling.focusedByUID)}
              />
            )}
          </GridCell>
        )}
      </Grid>
    </GridView>
  );
}

export function getExtraFilenames(sampling: Project) {
  return sampling.filenames?.filter(name => name.endsWith('.pdf') || name.endsWith('.csv')) || [];
}

function hasFocusedByUID(sampling: FullProject): sampling is FullProject & { focusedByUID: string } {
  return sampling.focusedByUID !== undefined;
}
