import { Box } from '@chakra-ui/react';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { ComparableEnvGroupType, getFriendlyComparableEnvGroupName } from '@resistapp/common/comparable-env-groups';
import { MetricMode } from '@resistapp/common/types';
import { chain } from 'lodash';
import { useState } from 'react';
import { IntegratedTextSelect } from '../selects/integrated-text-select';
import { theme } from '../shared/theme';
import { OptionForEnvironmentType } from './types';

interface Props {
  onChange: (value: OptionForEnvironmentType | null) => void;
}

export function EnvironmentTypeSelector({ onChange }: Props) {
  const { availableEnvGroups, selectedEnvironmentTypeGroup, metricMode } = useOverviewContext();
  const [selectActive, setSelectActive] = useState(false);

  if (!availableEnvGroups) return null;

  // We remove duplicate environments, so we always show different data, when changing environment types
  const uniqGroups = chain(availableEnvGroups)
    .uniqBy(group =>
      group.envs
        .map(env => env.id)
        .sort()
        .join(','),
    )
    .map(g => g.type)
    .value();

  // The options that are not available are shown as disabled (disabled and grey in the UI)
  const options: OptionForEnvironmentType[] = availableEnvGroups.map(({ type }) => ({
    value: type,
    label: getFriendlyComparableEnvGroupName(type),
    isDisabled:
      !uniqGroups.some(group => group === type) ||
      (metricMode === MetricMode.REDUCTION && type !== ComparableEnvGroupType.WATER_TREATMENT) ||
      (metricMode !== MetricMode.REDUCTION && type === ComparableEnvGroupType.WATER_TREATMENT),
  }));

  const value = options.find(option => option.value === selectedEnvironmentTypeGroup) || options[0];

  return (
    <Box
      onClick={() => {
        !selectActive && setSelectActive(true);
      }}
      style={{ cursor: 'pointer' }}
    >
      <IntegratedTextSelect<OptionForEnvironmentType>
        className="test_environment-type-selector"
        options={options}
        value={value}
        size="sm"
        menuIsOpen={selectActive}
        onChange={selectedOption => {
          onChange(selectedOption);
          setSelectActive(false);
        }}
        onMenuClose={() => {
          setSelectActive(false);
        }}
        renderOption={option => <div>{option.label}</div>}
      >
        <div
          style={{
            fontWeight: theme.fontWeight.bold,
          }}
        >
          {value.label}
        </div>
      </IntegratedTextSelect>
    </Box>
  );
}
