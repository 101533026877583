import { Flex } from '@chakra-ui/react';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  getGenesAndCopyNumbers,
  getNumDetectedAndAnalysedAssays,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { ReactNode } from 'react';
import { CopyNumberBarGraph } from '../copy-number-bar-graph';
import { SiteDetailTitle } from '../site-detail-title';
import { SiteGeneDetailBox } from '../site-gene-detail-box';
import { SiteDetailContainer, SiteDetailsBaseProps } from './general-site-details';
import { detailGraphHeight } from './site-details-utils';

const graphMargins = 26;

type Props = SiteDetailsBaseProps & {
  minorTarget: MinorTarget;
  title: string;
  titleExplanation: ReactNode;
  noGenesText: string;
  id?: string;
};

export function DetailGraph({ containerRef, minorTarget, title, titleExplanation, noGenesText, id }: Props) {
  const { metricMode, processMode, selectedSiteDatum, activeOverviewConfiguration } = useOverviewContext();
  if (!selectedSiteDatum) {
    return null;
  }

  const { detectedCount, analysedCount } = getNumDetectedAndAnalysedAssays(
    selectedSiteDatum,
    minorTarget,
    metricMode,
    processMode,
  );
  const width = (containerRef.current?.clientWidth || 130) - graphMargins;

  if (detectedCount === 0) {
    return (
      <SiteDetailContainer ref={containerRef} id={id}>
        <SiteDetailTitle title={title} explanation={noGenesText} counts={{ detectedCount, analysedCount }} />
        <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
          <SiteGeneDetailBox target={minorTarget} />
        </Flex>
      </SiteDetailContainer>
    );
  } else {
    const genesAndCopyNumbers = getGenesAndCopyNumbers(selectedSiteDatum, minorTarget, metricMode, processMode);
    const friendlyGenesAndNumbers = activeOverviewConfiguration.getFriendlyNumber(
      genesAndCopyNumbers,
      selectedSiteDatum,
      minorTarget,
    );

    return (
      <SiteDetailContainer ref={containerRef} key="site-detail-box-mge">
        <SiteDetailTitle title={title} explanation={titleExplanation} counts={{ detectedCount, analysedCount }} />
        <CopyNumberBarGraph
          genesAndNumbers={friendlyGenesAndNumbers}
          width={width}
          height={detailGraphHeight}
          events={false}
          metricMode={metricMode}
          activeOverviewConfiguration={activeOverviewConfiguration}
        />
      </SiteDetailContainer>
    );
  }
}
