import styled from '@emotion/styled';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { BarDatum, BoxDatum, getNumDetectedTotal } from '@resistapp/client/data-utils/plot-data/research-plot-data';
import { useExperimentalMetric } from '@resistapp/client/hooks/use-experimental-metric';
import { useFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { GeneGrouping, getGroup } from '@resistapp/common/assays';
import { friendlyAbundance, friendlyDate } from '@resistapp/common/friendly';
import { isConsideredAbsolute, NormalisedValueName } from '@resistapp/common/normalisation-mode';
import { NormalisationMode } from '@resistapp/common/types';
import { SeriesPoint } from '@visx/shape/lib/types';
import { ScaleOrdinal } from 'd3-scale';
import { getGroupTextColor } from '../../shared/palettes';

export type BarTooltipData = {
  bar: SeriesPoint<BarDatum>;
  key: string;
  index: number;
  height: number;
  width: number;
  y: number;
  color: string;
};

export function getAbundace(datum: BarTooltipData) {
  return +datum.bar.data[datum.key];
}

interface BarTooltipContentProps {
  tooltipData: BarTooltipData;
}

interface NumDetectedTooltipContentProps extends BarTooltipContentProps {
  tooltipData: BarTooltipData;
  colorScale: ScaleOrdinal<string, string>;
  grouping: GeneGrouping;
  showGeneInfo?: boolean;
}

export function NumDetectedTooltipContent({
  tooltipData,
  colorScale,
  grouping,
  showGeneInfo = false,
}: NumDetectedTooltipContentProps) {
  const total = getNumDetectedTotal(tooltipData.bar.data);
  const groupColor = colorScale(tooltipData.key);
  const groupTextColor = getGroupTextColor(tooltipData.key, grouping);

  return (
    <div style={{ padding: 8 }}>
      <div>
        <strong>{tooltipData.bar.data.label} </strong>{' '}
        {tooltipData.bar.data.date && ` on ${friendlyDate(tooltipData.bar.data.date, 'prefixMon', true)}`}
      </div>
      <div style={{ marginTop: oldTheme.spacing.xxs, fontSize: oldTheme.fontSize.s }}>
        <strong>{total} genes detected in total</strong> in selected targets
      </div>
      {showGeneInfo && (
        <div style={{ marginTop: oldTheme.spacing.min, fontSize: oldTheme.fontSize.s }}>
          <strong>
            {tooltipData.bar.data[tooltipData.key]}{' '}
            <div
              style={{
                backgroundColor: groupColor,
                color: groupTextColor,
                borderRadius: '6px',
                padding: '2px 2px',
                display: 'inline-block',
              }}
            >
              {tooltipData.key}
            </div>{' '}
            genes detected
          </strong>
        </div>
      )}
    </div>
  );
}

interface HeatmapTooltipContentProps extends BarTooltipContentProps {
  grouping: GeneGrouping;
  colorScale: ScaleOrdinal<string, string>;
}

export function HeatmapTooltipContent({ colorScale, tooltipData, grouping }: HeatmapTooltipContentProps) {
  const { normalisationMode } = useFilters();
  const valueName = NormalisedValueName[normalisationMode];
  const groupName = getGroup(tooltipData.key, grouping) as string;
  const groupColor = colorScale(groupName);
  const groupTextColor = getGroupTextColor(groupName, grouping);
  const value = getAbundace(tooltipData);
  return (
    <div style={{ padding: 8 }}>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr style={{ paddingBottom: '12px' }}>
            <Label>Group:</Label>
            <Value>
              <span
                style={{ backgroundColor: groupColor, color: groupTextColor, borderRadius: '6px', padding: '1px 4px' }}
              >
                {groupName}
              </span>
            </Value>
          </tr>
          <tr style={{ paddingBottom: '12px' }}>
            <Label>Sample:</Label>
            <Value>{tooltipData.bar.data.label}</Value>
          </tr>
          <tr style={{ paddingBottom: '12px' }}>
            <Label>Gene:</Label>
            <Value>{tooltipData.key}</Value>
          </tr>
          <tr style={{ paddingBottom: '12px' }}>
            <Label>{valueName}:</Label>
            <Value>{friendlyAbundance(value)}</Value>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const Label = styled.td`
  width: 150;
  text-align: right;
`;

const Value = styled.td`
  ${oldTheme.fontWeight.bold}
  width: 150;
  text-align: left;
`;

interface BoxTooltipContentProps {
  tooltipData: BoxDatum;
}

export function BoxTooltipContent({ tooltipData }: BoxTooltipContentProps) {
  const { normalisationMode } = useFilters();
  const showExperimentalMetrics = !!useExperimentalMetric();
  return (
    <>
      <div style={{ color: oldTheme.darkGray, padding: 8 }}>
        <strong>{tooltipData.label}</strong>
        {tooltipData.date && ` on ${friendlyDate(tooltipData.date, 'prefixMon', true)}`}
      </div>
      <div style={{ marginTop: oldTheme.spacing.xxs, fontSize: oldTheme.fontSize.s }}>
        {showExperimentalMetrics && (
          <>
            {Object.entries(tooltipData.experimentalMetrics || {}).map(([key, value]) => (
              <div key={key}>
                <b>{key}: </b>
                {(value as number | undefined)?.toFixed(key.startsWith('A') ? 1 : 0)}
              </div>
            ))}
            <br />
          </>
        )}
        <br />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td />
              <td style={{ textAlign: 'left', fontWeight: 'bold' }}>
                {NormalisedValueNameShort[normalisationMode]}&nbsp;
              </td>
              <td style={{ textAlign: 'left', fontWeight: 'bold' }}>log₁₀</td>
            </tr>
            {tooltipData.max === tooltipData.min && (
              <tr>
                <td style={{ textAlign: 'right' }} />
                <td>
                  {tooltipData.max
                    ? (10 ** tooltipData.max).toFixed(isConsideredAbsolute(normalisationMode) ? 0 : 6)
                    : '-'}
                </td>
                <td>{tooltipData.max?.toFixed(6)}</td>
              </tr>
            )}
            {tooltipData.max !== tooltipData.min && (
              <>
                <tr>
                  <td style={{ textAlign: 'right' }}>
                    <span style={{ fontSize: 10 }}>T</span>&nbsp;&nbsp;&nbsp;&nbsp; upper
                  </td>
                  <td>
                    {tooltipData.max
                      ? (10 ** tooltipData.max).toFixed(isConsideredAbsolute(normalisationMode) ? 0 : 6)
                      : '-'}
                  </td>
                  <td>{tooltipData.max?.toFixed(6)}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>Q3</td>
                  <td>
                    {tooltipData.thirdQuartile
                      ? (10 ** tooltipData.thirdQuartile).toFixed(isConsideredAbsolute(normalisationMode) ? 0 : 6)
                      : '-'}
                  </td>
                  <td>{tooltipData.thirdQuartile?.toFixed(6)}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>×&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mean</td>
                  <td>
                    {tooltipData.mean
                      ? (10 ** tooltipData.mean).toFixed(isConsideredAbsolute(normalisationMode) ? 0 : 6)
                      : '-'}
                  </td>
                  <td>{tooltipData.mean?.toFixed(6)}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>—&nbsp;median</td>
                  <td>
                    {tooltipData.median
                      ? (10 ** tooltipData.median).toFixed(isConsideredAbsolute(normalisationMode) ? 0 : 6)
                      : '-'}
                  </td>
                  <td>{tooltipData.median?.toFixed(6)}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>Q1</td>
                  <td>
                    {tooltipData.firstQuartile
                      ? (10 ** tooltipData.firstQuartile).toFixed(isConsideredAbsolute(normalisationMode) ? 0 : 6)
                      : '-'}
                  </td>
                  <td>{tooltipData.firstQuartile?.toFixed(6)}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>⊥&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;lower</td>
                  <td>
                    {tooltipData.min
                      ? (10 ** tooltipData.min).toFixed(isConsideredAbsolute(normalisationMode) ? 0 : 6)
                      : '-'}
                  </td>
                  <td>{tooltipData.min?.toFixed(6)}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

const NormalisedValueNameShort = {
  [NormalisationMode.SIXTEEN_S]: 'Rel. abund.',
  [NormalisationMode.MG_SS]: 'Copy num. per mg SS',
  [NormalisationMode.HOUR]: 'Copy num. per hour',
  [NormalisationMode.LITRE]: 'Copy num. per L',
  [NormalisationMode.TEN_UL_DILUTED_DNA]: 'Copy num.',
  [NormalisationMode.MG_BOD]: 'Copy num. per mg BOD',
} as const;
