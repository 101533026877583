import { useProject } from '@resistapp/client/hooks/api';
import { PathParams, usePathParam } from '@resistapp/client/hooks/use-path-params';
import { isConsideredAbsolute } from '@resistapp/common/normalisation-mode';
import { FullProject, FullSamplesByUID } from '@resistapp/common/types';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { QueryFilters, useQueryFilters } from '../hooks/use-query-filters/use-query-filters';
import { ApplicationErrors } from '../utils/error';

export interface AbsoluteModeData {
  enabled: boolean; // Project has absolute numbers
  onAndEnabled: boolean; // Project has absolute numbers and absolute mode is on
}

export interface SampleData {
  data: FullProject | undefined;
  queryFilters: QueryFilters;
  absoluteModeData: AbsoluteModeData;
  loading: boolean;
  error: Error | null;
  refetchData: () => void;
}

const SampleDataContext = createContext<SampleData | undefined>(undefined);

export function SampleDataProvider({ children }: { children: React.ReactNode }) {
  const projectId = usePathParam(PathParams.projectId);
  if (!projectId) {
    throw new Error(ApplicationErrors.projectID_is_missing);
  }
  const { data, isLoading, error, refetch } = useProject(projectId);

  const absoluteModeEnabled = hasAbsoluteNumbers(data);
  const { queryFilters, focusSamplesByUID } = useQueryFilters(data, absoluteModeEnabled);
  const [focusedByUID, setFocusedSamples] = useState<FullSamplesByUID | undefined>();
  const oldDataRef = useRef<number | null>(null);
  const oldQueryFiltersRef = useRef<string | null>(null);
  const oldHasFocusRef = useRef<boolean | null>(null);

  useEffect(() => {
    if (
      data?.id !== oldDataRef.current ||
      JSON.stringify(queryFilters.filters) !== oldQueryFiltersRef.current ||
      queryFilters.hasFocus !== oldHasFocusRef.current
    ) {
      oldDataRef.current = data?.id || null;
      oldQueryFiltersRef.current = JSON.stringify(queryFilters.filters);
      oldHasFocusRef.current = queryFilters.hasFocus;
      if (!data || !queryFilters.hasFocus) {
        setFocusedSamples(data?.samplesByUID);
      } else {
        const focused = focusSamplesByUID(data.samplesByUID);
        setFocusedSamples(focused);
      }
    }
  }, [data, queryFilters.hasFocus, focusSamplesByUID, queryFilters.filters]);

  if (data) {
    data.focusedByUID = focusedByUID;
  }

  const contextData = {
    loading: isLoading,
    error,
    data,
    refetchData: refetch,
    queryFilters,
    absoluteModeData: {
      enabled: absoluteModeEnabled,
      onAndEnabled: absoluteModeEnabled && isConsideredAbsolute(queryFilters.filters.normalisationMode),
    },
  };

  return <SampleDataContext.Provider value={contextData}>{children}</SampleDataContext.Provider>;
}

export function useSampleDataContext() {
  const context = useContext(SampleDataContext);

  if (!context) {
    throw new Error('useSampleDataContext must be used within a SampleDataProvider');
  }

  return context;
}

function hasAbsoluteNumbers(sampling: FullProject | undefined): boolean {
  if (!sampling) {
    return false;
  }
  return !!Object.values(sampling.samplesByUID).find(samples =>
    samples.find(sample => sample.abundances.find(a => a.meanCt)),
  );
}
