import { getTarget, Target } from '@resistapp/common/assays';
import { filterMinorTarget, isMinorTarget, MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { PartialAbundance, WithAbundances } from '@resistapp/common/statistics/resistance-index';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { filterDetected } from '@resistapp/common/utils';
import { chain, isNil, keys, mean } from 'lodash';
import { getBeforeOrAfterAbundances } from './build-overview-line-data';

export interface DetectedAndAnalysedCounts {
  detectedCount: number;
  analysedCount: number;
}

export function getNumDetectedAndAnalysedAssays(
  overviewDatum: WithAbundances,
  target: Target | MinorTarget,
  metricMode: MetricMode,
  processMode: ProcessMode,
): DetectedAndAnalysedCounts {
  const abundances = getBeforeOrAfterAbundances(overviewDatum, metricMode, processMode);
  const targetedAbundancesByAssay = abundances ? targetAbundancesByAssay(abundances, target) : {};
  const analysedCount = keys(targetedAbundancesByAssay).length;
  const detectedCount = chain(targetedAbundancesByAssay)
    .mapValues(filterDetected)
    .pickBy(abs => abs.length)
    .keys()
    .value().length;
  return { detectedCount, analysedCount };
}

export function getAnalysedAssays(
  overviewDatum: WithAbundances,
  target: Target | MinorTarget,
  metricMode: MetricMode,
  processMode: ProcessMode,
) {
  const abundances = getBeforeOrAfterAbundances(overviewDatum, metricMode, processMode);
  const targetedAbundancesByAssay = abundances ? targetAbundancesByAssay(abundances, target) : {};
  return chain(targetedAbundancesByAssay).values().flatten().value();
}

export function targetAbundancesByAssay<T extends PartialAbundance>(
  abundances: T[],
  target: Target | MinorTarget,
  includeAY1: boolean = false,
): Record<string, T[]> {
  if (isMinorTarget(target)) {
    return chain(filterMinorTarget(abundances, target, includeAY1))
      .groupBy(d => d.assay)
      .value();
  } else {
    return chain(abundances)
      .filter(abundance => getTarget(abundance.gene) === target || (includeAY1 && abundance.assay === 'AY1'))
      .groupBy(d => d.assay)
      .value();
  }
}

export interface GeneAndCopyNumber {
  assay: string;
  gene: string;
  copyNumber: number;
  reduction?: number | null;
  log10?: number | null;
}

export function getGenesAndCopyNumbers(
  overviewDatum: WithAbundances,
  target: Target | MinorTarget,
  metricMode: MetricMode,
  processMode: ProcessMode,
): GeneAndCopyNumber[] {
  const considerSecondaryDetections = metricMode === MetricMode.REDUCTION && processMode === ProcessMode.DURING;
  const abundances = getBeforeOrAfterAbundances(overviewDatum, metricMode, processMode);
  const detectedPrimaryAssays = new Set(abundances?.filter(a => !isNil(a.absolute)).map(a => a.assay));
  const secondaryAbundances = considerSecondaryDetections
    ? getBeforeOrAfterAbundances(overviewDatum, MetricMode.RISK, ProcessMode.AFTER) || []
    : [];
  const detectedOnlyInSecondaryAssays = considerSecondaryDetections
    ? new Set(
        secondaryAbundances.filter(a => !isNil(a.absolute) && !detectedPrimaryAssays.has(a.assay)).map(a => a.assay),
      )
    : new Set();
  const targetedAbundancesByAssay = abundances ? targetAbundancesByAssay(abundances, target) : {};
  return chain(targetedAbundancesByAssay)
    .pickBy((_, assay) => detectedPrimaryAssays.has(assay) || detectedOnlyInSecondaryAssays.has(assay))
    .mapValues((assayAbundances, assay) => {
      const copyNumber = mean(assayAbundances.map(({ absolute }) => absolute));
      return {
        assay,
        gene: assayAbundances[0].gene,
        // TODO add both before and after copy number here
        // show based on process mode in the UI eiter before, after, or both
        // See eg. 1978 > Reduction > Blomminmäki > August > Pathodens > P.aeruginosa
        copyNumber: isNaN(copyNumber) ? 0 : copyNumber,
      };
    })
    .values()
    .value();
}
