import { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { MetricMode } from '@resistapp/common/types';
import { InfoIconProps } from './info-icons-general';
import { ReductionInfoIcon } from './reduction-info-icon';
import { ResistanceIndexInfoIcon } from './resistance-index-info-icon';
import { RiskScoreInfoIcon } from './risk-score-info-icon';

interface Props {
  metricMode: MetricMode;
  infoIconProps?: InfoIconProps;
  activeOverviewConfiguration?: OverviewChartConfiguration;
}

export function LegendInfoIcon({ metricMode, infoIconProps = {}, activeOverviewConfiguration }: Props) {
  switch (metricMode) {
    case MetricMode.RISK:
      return <RiskScoreInfoIcon {...infoIconProps} />;
    case MetricMode.ARGI:
      return <ResistanceIndexInfoIcon {...infoIconProps} />;
    case MetricMode.REDUCTION:
      return <ReductionInfoIcon {...infoIconProps} activeOverviewConfiguration={activeOverviewConfiguration} />;
    default:
      return null;
  }
}
