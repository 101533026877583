import { Box, Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { Target } from '@resistapp/common/assays';
import { MetricMode } from '@resistapp/common/types';
import { getGroupColor, getGroupTextColor } from './palettes';
import { theme } from './theme';

interface Props {
  noBold?: boolean;
  noColor?: boolean;
  noAntibiotic?: boolean;
  style?: React.CSSProperties;
  plural?: boolean;
  antibioticOverride?: Target | null;
}

export function MetricTitleWithDisclaimer(props: Props) {
  const { metricGeneCnt, isOneHealthProject } = useOverviewContext();
  if (isOneHealthProject) {
    return <MetricTitle {...props} />;
  }
  return (
    <Popover trigger="hover" placement="top">
      <PopoverTrigger>
        <Box as="span">
          <MetricTitle {...props} />
          <b>*</b>
        </Box>
      </PopoverTrigger>
      <PopoverContent width="200px">
        <PopoverBody>
          This metric is based on a custom set of {metricGeneCnt} genes, instead of Resistomap&apos;s standard 96 Gene
          Panel for One Health Framework.
          <br />
          <br />
          Data is comparable within this project, but might not be globally comparable to other sampling projects.
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export function MetricTitle(props: Props) {
  const { isOneHealthProject, metricMode, selectedAntibiotic, activeOverviewConfiguration } = useOverviewContext();
  const antibiotic = props.antibioticOverride === null ? undefined : props.antibioticOverride || selectedAntibiotic;

  const sMaybe = !props.plural ? '' : metricMode === MetricMode.ARGI ? 'es' : 's';

  switch (metricMode) {
    case MetricMode.ARGI: {
      if (antibiotic) {
        return (
          <span>
            <AntibioticText {...props} />
            &nbsp;Resistance&nbsp;Index{sMaybe}
          </span>
        );
      } else if (isOneHealthProject) {
        return <span>Global&nbsp;Resistance&nbsp;Gene&nbsp;Index{sMaybe}</span>;
      } else {
        return <span>Antibiotic&nbsp;Resistance&nbsp;Gene&nbsp;Index{sMaybe}</span>;
      }
    }
    case MetricMode.RISK:
      return (
        <span>
          {antibiotic ? <AntibioticText {...props} /> : 'Comparative\u00A0Health'}&nbsp;Risk&nbsp;Score{sMaybe}
        </span>
      );
    case MetricMode.REDUCTION:
      return (
        <span>
          <span style={{ whiteSpace: 'nowrap' }}>
            {antibiotic && (
              <>
                <AntibioticText {...props} />
                &nbsp;
              </>
            )}
            10-fold&nbsp;Change{sMaybe}&nbsp;
            {activeOverviewConfiguration.id === 'reduction-relative' ? '(relative)' : '(per\u00A0L)'}
          </span>
        </span>
      );
  }
}

function AntibioticText(props: Props) {
  const { selectedAntibiotic } = useOverviewContext();
  const antibiotic: Target | undefined = props.antibioticOverride || selectedAntibiotic;
  if (!antibiotic) {
    return null;
  }

  const groupColor = getGroupColor(antibiotic, 'antibiotic');
  const groupTextColor = getGroupTextColor(antibiotic, 'antibiotic');

  return (
    <span
      style={
        props.noColor || props.noAntibiotic
          ? { fontWeight: props.noBold ? 'inherit' : 'bold' }
          : {
              fontWeight: props.noBold ? 'inherit' : 'bold',
              backgroundColor: groupColor,
              color: groupTextColor,
              padding: theme.spacing[0.5],
              paddingLeft: theme.spacing[1],
              paddingRight: theme.spacing[1],
              borderRadius: theme.borders.radius.small,
              ...props.style,
            }
      }
    >
      {props.noAntibiotic ? '' : <span style={{ whiteSpace: 'nowrap' }}>{antibiotic}</span>}
    </span>
  );
}
