import { WithAbundances } from '@resistapp/common/statistics/resistance-index';
import { ProcessMode } from '@resistapp/common/types';

export function truncateLongString(string: string) {
  return string.length > 10 ? `${string.slice(0, 10)}...` : string;
}

export function get_inRawOrEffluentSamplesString(overviewDatum: WithAbundances | undefined, processMode: ProcessMode) {
  if (!overviewDatum) {
    return '';
  }
  const mainMetricLabel =
    processMode === ProcessMode.DURING ? '' : processMode === ProcessMode.BEFORE ? 'raw' : 'treated';
  return mainMetricLabel ? ` in ${mainMetricLabel} samples` : '';
}
