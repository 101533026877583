import { zEnvironmentSubType, zEnvironmentType } from '@resistapp/common/environment-types';
import { chain, compact, Dictionary, uniq } from 'lodash';
import { z } from 'zod';
import { Feature } from './features';
import { RequiredFields } from './type-utils';

// Keep consistent with analysis
export enum BioRep {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}
export const zBioRep = z.nativeEnum(BioRep);

export enum AccessSubjectType {
  ORG = 'ORG',
  USER = 'USER',
}
export const zAccessSubjectType = z.nativeEnum(AccessSubjectType);

export interface UserProject {
  userId: number;
  projectId: number;
}

export interface OrganizationProject {
  organizationId: number;
  projectId: number;
}

export interface Access {
  projectId: number;
  subjectType: AccessSubjectType;
  subjectId: number;
}

export interface OrganizationUser {
  organizationId: number;
  userId: number;
}

export interface RawUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface User extends RawUser {
  id: number;
  token?: string;
  directAccesses: Array<Pick<Access, 'projectId'>>;
  organizations: Organisation[];
}

export interface Organisation {
  id: number;
  name: string;
  isDemo: boolean;
  signupCode: string | null;
  accesses: Array<Pick<Access, 'projectId'>>;
  features: Feature[];
  defaultMetric: MetricMode | null;
}

export interface Project {
  id: number;
  name: string;
  status: ProjectStatus;
  statusUserId?: number;
  statusNote?: string;
  filenames?: string[];
  warnings?: string[];
  driveLink?: string;
  labSheetLink?: string;
  customerSheetLink?: string;
  publicationName?: string;
  publicationUrl?: string;
  createdAt: Date | string;
}

export interface ResultData {
  warnings: string[];
  abundances: AnalysisAbundance[];
  correlations: Array<{
    carrierGene: string;
    resistanceGene: string;
    assayVersion?: string;
    serverVersion?: string;
    analysisVersion: string;
    value: number;
  }>;
}

export interface ProjectData {
  ctsByChipName: Dictionary<CtRow[]>;
  runDateByChipName: Dictionary<Date | undefined>;
  parsedSamples: RawSample[];
  customerSheetLink: string | undefined;
  mmLot: string;
}

export enum ProjectStatus {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  APPROVED_WITH_ISSUES = 'APPROVED_WITH_ISSUES',
  FAILED = 'FAILED',
  POOLED = 'POOLED',
  POOLED_MANUAL = 'POOLED_MANUAL',
  DUPLICATE = 'DUPLICATE',
  RESEARCH = 'RESEARCH',
  LEGACY = 'LEGACY',
}

export function isApproved(status: ProjectStatus) {
  return status === ProjectStatus.APPROVED || status === ProjectStatus.APPROVED_WITH_ISSUES;
}

export type CopiesByUB = Dictionary<Dictionary<number | undefined>>; // By sample UID, bioRep
export type ReplicatedSamples = FullSample[]; // Array of biologibal replica samples
export type FullSamplesByUID = Dictionary<ReplicatedSamples>; // By sample UID, which is of format: <samplingId>-<sampleNum>, eg. 123-456

export interface FullProject extends Project {
  qpcrFiles: Dictionary<string>;
  samplesByUID: FullSamplesByUID;
  // The focused term in focusedByUID attempts to communicate that
  // - samples filtered based on query parameters
  // - sample abundances are filtered based on query parameters
  //   - 16S rRNA either filtered out, or all other genes are filtered out
  focusedByUID?: FullSamplesByUID;
}

export const zRawEnvironment = z.object({
  name: z.string(),
  type: zEnvironmentType,
  subtype: zEnvironmentSubType.optional(),
});
export type RawEnvironment = z.infer<typeof zRawEnvironment>;

export const zEnvironment = zRawEnvironment.extend({
  id: z.number(),
});
export type Environment = z.infer<typeof zEnvironment>;

export interface RawSample {
  number: number;
  bioRep: BioRep;
  environment: RawEnvironment;
  environmentId?: number;
  time?: Date;
  lat?: number;
  lon?: number;
  city?: string;
  region?: string;
  country?: string;
  inferredLat?: number;
  inferredLon?: number;
  adminLevels?: AdminAreaByLevel;
  // DNA measurements - diluted
  dilutedDnaVolume?: number;
  dilutedDnaConcentration?: number;
  dilutedDnaQuality280?: number;
  dilutedDnaQuality230?: number;
  // DNA measurements - eluted
  elutedDnaQuality280?: number;
  elutedDnaQuality230?: number;
  elutedDnaConcentration?: number;
  elutedDnaVolume?: number;
  // Specific for water samples
  filteredVolume?: number; // copies/L
  // Wastewater treatment plan specific
  bod?: number; // mg/L
  suspendedSolids?: number; // mg/L
  operatedFlowRate?: number; // L/h
  flowRate?: number; // L/h
}

// TODO remove level '1' hack
export type AdminLevelKey =
  | '1'
  | '2' // Finland
  | '3' // Åland
  | '4'
  | '5'
  | '6' // Uusimaa
  | '7' // Helsinki sub-region
  | '8' // Helsinki
  | '9' // Central major district
  | '10' // Vallila
  | '11'
  | '12'
  | '13'
  | '14'
  | '15';
export type AdminAreaByLevel = {
  [key in AdminLevelKey]?: AdminArea;
};
export type AdminAreaMetadataByLevel = {
  [key in AdminLevelKey]?: AdminAreaMetadata;
};

type MinLonLat = { lon: number; lat: number };
type MaxLonLat = MinLonLat;

export interface AdminAreaMetadata {
  level: number;
  name: string;
}

export type AdminAreaBoundaries = [MinLonLat, MaxLonLat]; // Accepted by mapbox

export interface AdminArea extends AdminAreaMetadata {
  boundaries?: AdminAreaBoundaries | undefined;
}

export interface AdminLevelWithParents extends AdminArea {
  parent?: AdminArea;
}

// HACK: originalEnvironmentNames and od that is only populated by the UI pooling code
// it is used for keeping track of the original environment, eg. for keeping environmentNames query param intact
// despite the formation of transient pooled (site or admin level samples in createPooledSample).
// TODO: consider removing when implementing sites
export interface PooledEnvHack extends Environment {
  originalEnvironmentNames?: string[];
  originalEnvironmentId?: number;
}
export function getAllOriginalEnvironmentNames(
  env1: Environment | PooledEnvHack,
  env2: Environment | PooledEnvHack | undefined,
): string[] {
  const beforeEnvironmentsNames = (env1 as PooledEnvHack).originalEnvironmentNames || [env1.name];
  const afterEnvironmentsNames = (env2 as PooledEnvHack | undefined)?.originalEnvironmentNames || [env2?.name];

  return compact(uniq([...beforeEnvironmentsNames, ...afterEnvironmentsNames]));
}

export interface Sample extends RawSample {
  id: number;
  projectId: number;
  environmentId: number;
  environment: Environment | PooledEnvHack;
}
export interface FullSample extends Sample {
  meanCts: MeanCt[];
  assayResults: AssayResult[];
  abundances: FullAbundance[]; // To be deprecated. UI still expects the legacy abundances result format, and it is populated by buildSamplesByUID
}

export type VersionedSample = Omit<FullSample, 'abundances'> &
  Required<Pick<FullSample, 'meanCts' | 'assayResults' | 'environment'>>;

//  The UI pooled samples have absolute abundace, but pooled project creation doesn't
export type AnalysisAbundanceWithAbsolute = AnalysisAbundance & { absolute?: null | number };

export type SampleForCreation = RawSample & Pick<Sample, 'projectId'>;
export type FullSampleWithTime = RequiredFields<FullSample, 'time'>;

// Keep consistent with rs_to_api
export const zAbundance = z.object({
  gene: z.string(),
  assay: z.string(),
  assayVersion: z.string(),
  serverVersion: z.string(),
  analysisVersion: z.string(),
  relative: z.number().nullable(),
  meanCt: z.number().nullable(),
  traces: z.boolean(),
});
export type Abundance = z.infer<typeof zAbundance>;

export const zFullAbundance = zAbundance.extend({
  absolute: z.number().nullable(),
  copiesPerL: z.number().nullable(),
  copiesPerHour: z.number().nullable(),
  copiesPerMgSS: z.number().nullable(),
  copiesPerMgBod: z.number().nullable(),
});
export type FullAbundance = z.infer<typeof zFullAbundance>;
export type StrippedFullAbundance = Pick<
  FullAbundance,
  'assay' | 'gene' | 'relative' | 'absolute' | 'copiesPerL' | 'copiesPerHour' | 'copiesPerMgSS' | 'copiesPerMgBod'
>;

export interface WithStartAndEndDate {
  startDate: string | Date | undefined;
  endDate: string | Date | undefined;
}

// Keep in sync with api_by_python_column and rs_to_api
export type AnalysisAbundance = Omit<_AnalysisAbundance, 'serverVersion' | 'assayVersion'> &
  Partial<Pick<_AnalysisAbundance, 'serverVersion' | 'assayVersion'>>;
interface _AnalysisAbundance extends Abundance {
  sampleNum: number;
  bioRep: BioRep;
  target: string;
}

export enum HeatmapType {
  ALL = 'resistomap_all',
  DETECTED = 'resistomap_det',
  QUANTIFIED = 'resistomap_qnt',
}

export interface Correlation {
  // carrierAssay: string;
  // resistanceAssay: string;
  carrierGene: string;
  resistanceGene: string;
  target: string;
  assayVersion: string;
  serverVersion: string;
  analysisVersion: string;
  value: number;
}

type Lat = number;
type Lon = number;
type Coordinate = [Lat, Lon];
export type AdminLevelGeodata = {
  id: number;
  localName: string;
  nameEn: string;
  countryId: string;
  adminLevel: number;
  coordinates: Coordinate[];
  boundaries: AdminAreaBoundaries | undefined; // High admin level boundaries may be missing if we haven't had credits to fetch them
};
export type AdminLevelBoundaryData = Omit<AdminLevelGeodata, 'coordinates'>;

export interface Country {
  alpha3: string;
  alpha2: string;
  name: string;
  continent: Continent;
}

export type CountryNameByAlpha3 = Record<string, string>;
export type CountryByAlpha3 = Record<string, Country>;

export function getCountryNameByAlpha3(countryByAlpha3: CountryByAlpha3): CountryNameByAlpha3 {
  return chain(countryByAlpha3)
    .mapValues(c => c.name)
    .value();
}

export enum Continent {
  AFRICA = 'AFRICA',
  ASIA = 'ASIA',
  EUROPE = 'EUROPE',
  NORTH_AMERICA = 'NORTH_AMERICA',
  OCEANIA = 'OCEANIA',
  SOUTH_AMERICA = 'SOUTH_AMERICA',
  ANTARCTICA = 'ANTARCTICA',
}

export type CsvRows = Array<Dictionary<string | number | undefined>>;

export type AbundanceByBG = Partial<{
  [key in BioRep]: Dictionary<FullAbundance>; // Not full for old projects
}>;

export type PartialDict<T> = Partial<Dictionary<T>>;

export type AbundancesByUBG = PartialDict<AbundanceByBG>;

export const zCoordinates = z.object({
  lat: z.number(),
  lon: z.number(),
});
export type Coordinates = z.infer<typeof zCoordinates>;

const assaySample = {
  assay: z
    .string()
    .min(3)
    .transform(val => val.trim()),
  sample: z
    .string()
    .min(1)
    .transform(val => val.trim()),
};
const analysisValues = {
  ct: z
    .string()
    .nullable()
    .transform(val => (val ? parseFloat(val.replace(',', '.')) : null)),
  tm: z
    .string()
    .nullable()
    .transform(val => (val ? parseFloat(val.replace(',', '.')) : null)),
  efficiency: z
    .string()
    .nullable()
    .transform(val => (val ? parseFloat(val.replace(',', '.')) : null)),
  flags: z.string().nullable(),
};
export const zAnalysisCtValue = z.object({ ...assaySample, ...analysisValues });
export type AnalysisCtValue = z.infer<typeof zAnalysisCtValue>;

// We cannot extend, because we want to maintain key (column) order lof the origincal cts file
export const zCtRow = z.object({
  row: z.string().transform(val => parseInt(val, 10)),
  column: z.string().transform(val => parseInt(val, 10)),
  ...assaySample,
  conc: z
    .string()
    .nullable()
    .transform(val => (val ? parseFloat(val.replace(',', '.')) : null)),
  ...analysisValues,
});
export type CtRow = z.infer<typeof zCtRow>;
export const ctsHeaders = Object.keys(zCtRow.shape) as Array<keyof CtRow>;

export const zChip = z.object({
  id: z.number(),
  projectId: z.number(),
  name: z.string(),
  mmLot: z.string(),
  runDate: z.date().nullable(),
  cts: z.array(zCtRow).optional(),
});
export type Chip = z.infer<typeof zChip>;

// The MetricModes order matters for default metric selection. ARGI the default one and reduction the last choice
export enum MetricMode {
  ARGI = 'ARGI',
  RISK = 'RISK',
  REDUCTION = 'REDUCTION',
}
export const zMetricMode = z.nativeEnum(MetricMode);

export enum ChartUnit {
  LOG2 = 'LOG2',
  COPIES_PER_L = 'COPIES_PER_L',
  COPYNUMBER = 'COPYNUMBER', // Absolute number of copies in the analyzed DNA (100 µl)
}

export enum ProcessMode {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  DURING = 'DURING',
}
export const zProcessMode = z.nativeEnum(ProcessMode);

// Normalization modes in priority order
export enum NormalisationMode {
  MG_SS = 'MG_SS', // Copier per mg Suspendedd Solids
  HOUR = 'HOUR', // Copier per hour
  LITRE = 'LITRE', // Copier per litre
  TEN_UL_DILUTED_DNA = 'TEN_UL_DILUTED_DNA', // old 'absolute' mode
  MG_BOD = 'MG_BOD', // Copier per mg Biochemical Oxygen Demand
  SIXTEEN_S = 'SIXTEEN_S', // relative
}
export const zNormalisationMode = z.nativeEnum(NormalisationMode);

export interface Version {
  id: number;
  assayVersion: string;
  serverVersion: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export type RawMeanCt = {
  versionId: number;
  sampleId: number;
  assay: string;
  meanCt: number | null;
  traces: boolean;
};

export interface MeanCt extends RawMeanCt {
  gene?: string;
  createdAt: Date;
}

export interface RawAssayResult {
  versionId: number;
  sampleId: number;
  assay: string;
  normalisationMode: NormalisationMode;
  value: number | null;
  traces: boolean;
}

export interface AssayResult extends RawAssayResult {
  gene?: string;
  createdAt: Date;
}
