import { Box, Link as ChakraLink, useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import analyseInDetailImageSrc from '@resistapp/client/assets/analyse-in-detail.png';
import clickMapMarkerGeneReductionImage from '@resistapp/client/assets/click-map-marker_gene-reduction.png';
import clickMapMarkerHealthRiskImage from '@resistapp/client/assets/click-map-marker_health-risk.png';
import clickMapMarkerResistanceIndexImage from '@resistapp/client/assets/click-map-marker_resistance-index.png';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useTutorialContext } from '@resistapp/client/contexts/tutorial-context';
import { useUrlContext } from '@resistapp/client/contexts/url-provider';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { useTutorial } from '@resistapp/client/hooks/use-tutorial';
import { MetricMode } from '@resistapp/common/types';
import { MAPBOX_TOKEN } from '@resistapp/common/utils';
import { chain, Dictionary } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactMapGL, { NavigationControl } from 'react-map-gl';
import { Link } from 'react-router-dom';
import { LegendInfoIcon } from '../info-icons/legend-info-icon';
import { OverviewActions } from '../overview-actions/overview-actions';
import { BackButtonOnMap } from '../selected-map-environment/back-button-on-map';
import { theme } from '../shared/theme';
import { TutorialContainer } from '../tooltips/tutorial-container';
import { TutorialFooter } from '../tooltips/tutorial-footer';
import {
  analyseTooltipRightMargin,
  analyseTooltipTop,
  infoTooltipBottom,
  infoTooltipRightMargin,
} from './map-legend/base-map-legend';
import { MapLegend } from './map-legend/map-legend';
import { Markers } from './markers/markers';
import { activateColoredRegions, getBoundingBoxForEnvironment } from './overview-map-utils';

export function OverviewMap() {
  const { projectId, query } = useUrlContext();
  const { setTutorialChanged } = useTutorialContext();
  const { currentTutorialStep, tutorial, nextStep, prevStep, stopTutorial } = useTutorial('overview');
  const {
    hasPreparedData,
    notAvailableReason,
    trendData,
    setSelectedOrHoveredAreaOrSiteEnvId: setSelectedEnvironmentId,
    activeMapSource,
    handleZoom,
    setMapRef,
    mapRef,
    zoomAndCenter,
    zoom,
    previousAdminAreasLifo,
    changeZoomedAdminArea,
    setMapLoaded,
    shownAdminLevel,
    metricMode,
    activeOverviewConfiguration,
    processMode,
    activeChartUnit,
  } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();
  // We have the fullSize map only in wastPan projects for now, for testing
  // const isWastPanProject = !!props.projectId && !!WastPanProjectIds.includes(props.projectId);
  const [isFullsize, setIsFullsize] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    setTimeout(() => {
      mapRef?.resize();
    });
  }, [isFullsize, mapRef]);

  const handleScroll = useCallback(
    (event: WheelEvent) => {
      if (event.shiftKey && mapRef) {
        event.preventDefault();
        const map = mapRef.getMap();
        const delta = -event.deltaY;
        const newZoom = delta > 0 ? (zoom || 0) + 0.3 : (zoom || 0) - 0.3;
        zoomAndCenter({ zoom: newZoom, center: map.getCenter() });
      }
    },
    [zoom],
  );

  useEffect(() => {
    const mapContainer = mapRef?.getContainer();
    if (mapContainer) {
      mapContainer.addEventListener('wheel', handleScroll, { passive: false });
    }

    return () => {
      if (mapContainer) {
        mapContainer.removeEventListener('wheel', handleScroll);
      }
    };
  }, [handleScroll, mapRef]);

  const onMapLoad = () => {
    const mapInstance = mapRef?.getMap();
    setMapLoaded(true);

    if (!mapInstance || !activeMapSource || !trendData?.length) {
      return;
    }

    const newMapData = trendData.map(envData => envData[envData.length - 1]);
    const boundingBoxes = chain(trendData)
      .map(data => getBoundingBoxForEnvironment(data))
      .map((box, index) => [newMapData[index].environment.id, box])
      .fromPairs()
      .value() as Dictionary<{ ne: [number, number]; sw: [number, number] }>;

    activateColoredRegions(
      mapInstance,
      activeMapSource,
      newMapData,
      setSelectedEnvironmentId,
      boundingBoxes,
      changeZoomedAdminArea,
      queryFilters.filters,
      metricMode,
      processMode,
      activeChartUnit,
    );
  };

  if (!trendData?.length) {
    if (!hasPreparedData) {
      return <MapContainer>Loading data from the project...</MapContainer>;
    } else {
      return (
        <MapContainer>
          <Box>This view is not available: {notAvailableReason || 'unknown reason'}</Box>
          <Box>
            You can still&nbsp;
            <ChakraLink as={Link} to={`/research/${projectId.viable}${query}`} style={{ textDecoration: 'underline' }}>
              analyse the data in detail
            </ChakraLink>
            .
          </Box>
        </MapContainer>
      );
    }
  }

  const previousAdminLevel = previousAdminAreasLifo.length
    ? previousAdminAreasLifo[previousAdminAreasLifo.length - 1]
    : null;
  const handleBackButtonClick = () => {
    if (previousAdminLevel) {
      changeZoomedAdminArea(previousAdminLevel, { previous: true });
      queryFilters.toggleEnvironment(undefined, true);
    }
  };

  const infoIconProps = {
    header: currentTutorialStep === 4 ? tutorial.getTutorialPhaseText() : undefined,
    selectedGeneGroup: queryFilters.filters.selectedTargets,
    style: {
      position: 'absolute' as const,
      right: infoTooltipRightMargin,
      bottom: infoTooltipBottom,
      zIndex: 20,
    },
    isForceOpen: currentTutorialStep === 4,
    tutorialFooter: currentTutorialStep === 4 && (
      <TutorialFooter
        nextStep={() => {
          setTutorialChanged();
          nextStep();
        }}
        prevStep={() => {
          setTutorialChanged();
          prevStep();
        }}
        stopTutorial={() => {
          setTutorialChanged();
          stopTutorial();
        }}
        currentStep={currentTutorialStep}
        stepCount={tutorial.stepCount}
      />
    ),
  };

  const isVisualTest = window.location.search.includes('noMap=true');

  return (
    <MapContainer isFullsize={isFullsize} className="test_map-container">
      {/* Making the RI info tooltip absolute is the only way to let it escape ReactMapGL overflow hidden (while hiding the overfloing markers)
       * See MapLegend
       */}
      <OverviewActionsContainer>
        <OverviewActions />
      </OverviewActionsContainer>
      <div
        style={{
          position: 'absolute',
          right: analyseTooltipRightMargin,
          top: analyseTooltipTop,
          zIndex: theme.zIndexes.tutorial,
        }}
      >
        <TutorialContainer
          currentTutorialStep={currentTutorialStep}
          thisTutorialStep={3}
          tutorial={tutorial}
          setTutorialChanged={setTutorialChanged}
          nextStep={nextStep}
          prevStep={prevStep}
          stopTutorial={stopTutorial}
          isMobile={!!isMobile}
        >
          <></>
          <div style={{ paddingTop: 10 }}>
            <p>
              Click <i>Analyse in detail</i> for an in-depth analysis of all measured genes.
            </p>
            <br />
            <img src={analyseInDetailImageSrc as string} alt={'analyse in detail'} />
          </div>
        </TutorialContainer>
      </div>
      <LegendInfoIcon
        metricMode={metricMode}
        infoIconProps={infoIconProps}
        activeOverviewConfiguration={activeOverviewConfiguration}
      />
      {!isVisualTest && (
        <ReactMapGL
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/janneresistomap/clsvr6uah005w01qw17sh3sd7"
          style={{ width: '100%', height: '100%' }}
          ref={setMapRef}
          onLoad={onMapLoad}
          // scrollZoom={!isWastPanProject && !pinnedMapData}
          scrollZoom={false}
          onZoomEnd={e => {
            handleZoom(e);
          }}
          trackResize
        >
          <NavigationControl position="bottom-left" />
          {<Markers adminLevelsActive={!!activeMapSource} />}
          <MapLegend />
          <MapToFullsizeControl
            onClick={() => {
              setIsFullsize(!isFullsize);
            }}
            style={{
              cursor: 'pointer',
            }}
          >
            <FullsizeToggleSVG isFullsize={isFullsize} isHovering={isHovering} setIsHovering={setIsHovering} />
          </MapToFullsizeControl>
          {previousAdminLevel && <BackButtonOnMap title={previousAdminLevel.name} onClick={handleBackButtonClick} />}
        </ReactMapGL>
      )}
      <TutorialContainer
        currentTutorialStep={currentTutorialStep}
        thisTutorialStep={1}
        tutorial={tutorial}
        setTutorialChanged={setTutorialChanged}
        nextStep={nextStep}
        prevStep={prevStep}
        stopTutorial={stopTutorial}
        isMobile={false}
      >
        <div style={{ position: 'absolute', top: '50%', left: '50%' }} />
        <div style={{ paddingTop: 10 }}>
          <img
            src={
              metricMode === MetricMode.ARGI
                ? (clickMapMarkerResistanceIndexImage as string)
                : metricMode === MetricMode.RISK
                  ? (clickMapMarkerHealthRiskImage as string)
                  : (clickMapMarkerGeneReductionImage as string)
            }
            alt={'clicking map marker'}
          />
          <br />
          {shownAdminLevel ? 'Click on colored areas to zoom in. Then, click on site labels to see site details.' : ''}
        </div>
      </TutorialContainer>
    </MapContainer>
  );
}

interface FullsizeToggleSVGProps {
  isFullsize: boolean;
  isHovering: boolean;
  setIsHovering: (hovering: boolean) => void;
}

function FullsizeToggleSVG(props: FullsizeToggleSVGProps) {
  const { isFullsize, isHovering, setIsHovering } = props;
  return (
    <>
      <svg
        width="70"
        height="25"
        viewBox="0 0 100 30"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        style={{ marginBottom: isFullsize ? '5px' : '0px', transform: isFullsize ? 'rotate(180deg)' : 'none' }}
      >
        <polyline
          points="5,5 35,20 65,5"
          fill="none"
          stroke={isHovering ? theme.colors.neutral700 : 'black'}
          strokeWidth="7"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ shapeRendering: 'geometricPrecision' }}
        />

        <polyline
          points="5,5 35,20 65,5"
          fill="none"
          stroke={isHovering ? theme.colors.neutral700 : theme.colors.neutral500}
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ shapeRendering: 'geometricPrecision' }}
        />
      </svg>
    </>
  );
}

const MapContainer = styled.div<{ isFullsize?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => (props.isFullsize ? '800px' : '400px')};
`;

const MapToFullsizeControl = styled.div`
  position: absolute;
  z-index: ${theme.zIndexes.overviewMapControls};
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 20px;
  padding: 0 5px;
  cursor: pointer;
`;

const OverviewActionsContainer = styled.div`
  position: absolute;
  z-index: ${theme.zIndexes.overviewMapControls};
  top: ${theme.spacing[4]};
  right: ${theme.spacing[4]};
`;
