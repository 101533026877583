import { chain } from 'lodash';
import { z } from 'zod';
import { MetricMode, User } from './types';

export const resistomapOrganizationId = 1;

export enum Feature {
  ADMIN = 'ADMIN',
  ARGI = 'ARGI',
  RISK = 'RISK',
  REDUCTION = 'REDUCTION',
}
export const zFeature = z.nativeEnum(Feature);

export function isNonDemo(user: User | undefined | null): boolean {
  return !!user && !getDemoStatus(user);
}

export function isAdmin(user: User | undefined | null): boolean {
  return hasFeature(user, Feature.ADMIN);
}

export function hasOverview(user: User | undefined | null): boolean {
  return hasFeature(user, Feature.ARGI) || hasFeature(user, Feature.RISK) || hasFeature(user, Feature.REDUCTION);
}

function metricModeToFeature(metricMode: MetricMode): Feature {
  return metricMode === MetricMode.ARGI
    ? Feature.ARGI
    : metricMode === MetricMode.RISK
      ? Feature.RISK
      : Feature.REDUCTION;
}

export function hasMetricMode(user: User | undefined | null, metricMode: MetricMode): boolean {
  return hasFeature(user, metricModeToFeature(metricMode));
}

export function hasFeature(user: User | undefined | null, feature: Feature): boolean {
  return !!user && getUserFeatures(user).includes(feature);
}

function getUserFeatures(user: User): Feature[] {
  if (!('organizations' in user)) {
    throw Error('User not fully loaded');
  }

  return chain(user.organizations)
    .flatMap(org => org.features)
    .uniq()
    .value();
}

function getDemoStatus(user: User): boolean {
  if (!('organizations' in user)) {
    throw Error('User not fully loaded');
  }

  return user.organizations.some(org => org.isDemo);
}
