import { Box, useOutsideClick } from '@chakra-ui/react';
import { ChakraStylesConfig, GroupBase, SingleValue } from 'chakra-react-select';
import { useRef, useState } from 'react';
import { ResistomapSelect } from '../forms/resistomap-select';
import { ArrowIcon } from '../icons/arrow-icon';
import { theme } from '../shared/theme';

interface Props<T extends { value: string; label: string }> {
  options: T[];
  value: T | null | undefined;
  onChange: (value: SingleValue<T>) => void;
  placeholder?: string;
  styles?: ChakraStylesConfig<T, false, GroupBase<T>>;
  size?: 'sm' | 'md' | 'lg';
  menuIsOpen?: boolean;
  onMenuClose?: () => void;
  children?: React.ReactNode;
  renderOption?: (option: T) => React.ReactNode;
  className?: string;
}

export function IntegratedTextSelect<T extends { value: string; label: string }>({
  options,
  value,
  onChange,
  placeholder,
  styles,
  size = 'sm',
  menuIsOpen = false,
  onMenuClose,
  children,
  renderOption,
  className,
}: Props<T>) {
  const blurRef = useRef(null);
  const [internalMenuIsOpen, setInternalMenuIsOpen] = useState(menuIsOpen);

  useOutsideClick({
    ref: blurRef,
    handler: () => {
      if (internalMenuIsOpen) {
        setInternalMenuIsOpen(false);
        onMenuClose?.();
      }
    },
  });

  const handleClick = () => {
    setInternalMenuIsOpen(!internalMenuIsOpen);
  };

  return (
    <Box ref={blurRef} position="relative" className={className}>
      <Box
        onClick={handleClick}
        cursor="pointer"
        position="relative"
        display="inline-flex"
        alignItems="center"
        _after={{
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          borderBottom: '2px dotted',
          borderColor: theme.colors.neutral500,
        }}
        style={{ cursor: 'pointer' }}
        className="test_integrated-text-select"
      >
        {children}
        <ArrowIcon
          direction={internalMenuIsOpen ? 'up' : 'down'}
          style={{
            color: theme.colors.neutral600,
            width: '8px',
            marginLeft: '4px',
          }}
        />
      </Box>
      {internalMenuIsOpen && (
        <Box position="absolute" zIndex={1} width="100%" minWidth="150px" top="100%" left="0">
          <ResistomapSelect<T, false>
            options={options}
            value={value}
            onChange={newValue => {
              onChange(newValue);
              setInternalMenuIsOpen(false);
            }}
            placeholder={placeholder}
            chakraStyles={{
              container: provided => ({
                ...provided,
                width: '100%',
              }),
              control: () => ({
                display: 'none',
              }),
              menu: base => ({
                ...base,
                marginTop: '4px',
                width: '100%',
              }),
              ...styles,
            }}
            size={size}
            menuIsOpen={true}
            className="stripped"
            formatOptionLabel={renderOption}
            components={{
              Control: () => null,
            }}
          />
        </Box>
      )}
    </Box>
  );
}
