import { Kbd, ListItem, UnorderedList } from '@chakra-ui/react';
import { getEnvColorById } from '@resistapp/client/components/shared/palettes';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { useFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { Target } from '@resistapp/common/assays';
import { FullProject, NormalisationMode } from '@resistapp/common/types';
import { scaleOrdinal } from '@visx/scale';
import { chain } from 'lodash';
import { ChartHotkeyInstructions } from '../../tooltips/chart-hotkey-instructions';
import { getEnvironmentDatas, getSampleSelector, getSelectedEnvironmentIds } from '../bar-box-map/utils';
import { BaseLegend } from './base-legend';
import { Legend } from './legend';

interface SampleLegendProps {
  toggleEnvironment: (envName: string | string[], only: boolean) => void;
  filters: Filters;
  project: FullProject;
  legendHeight: string;
  id?: string;
  showSampleNumbers?: boolean;
}

export function SampleLegend(props: SampleLegendProps) {
  const { project, filters, toggleEnvironment, showSampleNumbers = false } = props;
  const { samplesBeingSelected, setSamplesBeingSelected } = useResearchContext();
  const normalisationMode = useFilters().normalisationMode;

  const { environments, nameById, environmentIds, environmentNames } = getEnvironmentDatas(
    project.samplesByUID,
    filters.selectedEnvironmentTypeGroup,
  );

  const sampleLabelByEnvId = chain(environments)
    .keyBy(env => `${env.id}`)
    .mapValues(env => {
      if (!showSampleNumbers) {
        return env.name;
      } else {
        const sample =
          project.samplesByUID[
            Object.keys(project.samplesByUID).find(uid => project.samplesByUID[uid][0].environment.id === env.id) || ''
          ][0];
        return `${sample.number.toString()} - ${env.name}`;
      }
    })
    .value();
  const envColorById = getEnvColorById(project);

  const palette = environmentIds.map(id => envColorById[id]);
  const scale = scaleOrdinal<string, string>({
    domain: environmentIds,
    range: palette,
  });

  const selectedEnvironmentNames = filters.selectedEnvironmentNamesOrdered;
  const selectedEnvironmentIds = filters.selectedEnvironmentNamesOrdered
    .map(name => {
      const id = Object.keys(nameById).find(key => nameById[key] === name);
      return id ? String(id) : '';
    })
    .filter(Boolean);

  const getOpacity = (label: string) => {
    const correctLabel = showSampleNumbers ? label.split(' - ')[1] : label;
    const isBeingSelected = samplesBeingSelected.ids.includes(correctLabel);
    const isSelected =
      selectedEnvironmentNames.length === 0 ||
      selectedEnvironmentNames.includes(label) ||
      selectedEnvironmentIds.some(id => label === sampleLabelByEnvId[id]);

    return (isSelected && isBeingSelected) || (!isSelected && !isBeingSelected) ? 0.4 : 1;
  };

  const onClick = getSampleSelector(
    showSampleNumbers,
    selectedEnvironmentNames as Target[],
    environmentNames as Target[],
    samplesBeingSelected,
    setSamplesBeingSelected,
    toggleEnvironment,
    getSelectedEnvironmentIds(filters.selectedEnvironmentNamesOrdered, nameById),
    true,
    true,
  );
  const labelFormat = (label: string | number) =>
    !showSampleNumbers ? String(nameById[label]) : sampleLabelByEnvId[label];
  const { description, valueTip } = getNormalisationDescription(normalisationMode);

  return (
    <BaseLegend
      id={props.id}
      header="Samples"
      description={description}
      optionMinimum={2}
      TooltipContent={
        <UnorderedList>
          <ListItem>{valueTip}</ListItem>
          <ListItem>
            <Kbd>Click</Kbd> a label or chart bar to select a sample
          </ListItem>
          <ChartHotkeyInstructions label="samples" />
        </UnorderedList>
      }
    >
      <Legend
        colorScale={scale}
        labelFormat={labelFormat}
        getOpacity={getOpacity}
        onClick={onClick}
        height={props.legendHeight}
      />
    </BaseLegend>
  );
}

function getNormalisationDescription(mode: NormalisationMode): { description: string; valueTip: string } {
  switch (mode) {
    case NormalisationMode.MG_SS:
      return {
        description: 'Copies / mg of SS (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per mg of suspended solids',
      };
    case NormalisationMode.HOUR:
      return {
        description: 'Copies per hour (log)',
        valueTip: 'The graph presents Log₁₀ of copies per hour (flow normalised)',
      };
    case NormalisationMode.LITRE:
      return {
        description: 'Copies per litre (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per litre',
      };
    case NormalisationMode.MG_BOD:
      return {
        description: 'Copies / mg BOD (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per mg of biochemical oxygen demand',
      };
    case NormalisationMode.TEN_UL_DILUTED_DNA:
      return {
        description: 'Analysed copies (log)',
        valueTip:
          'The graph presents Log₁₀ of the numbers of gene copies in 10 uL of diluted DNA analysed on the SmartChip qPCR run.',
      };
    case NormalisationMode.SIXTEEN_S:
      return {
        description: 'Relative abundance (log)',
        valueTip: 'The graph presents Log₁₀ of abundance, relative to 16S rRNA genes',
      };
  }
}
