import { GeneGrouping, Targets, allGeneGroups } from '@resistapp/common/assays';
import {
  AllProjectEnvironmentTypesGroup,
  ComparableEnvGroupType,
  EnvironmentTypeGroup,
} from '@resistapp/common/comparable-env-groups';
import { EnvironmentType } from '@resistapp/common/environment-types';
import { NormalisationMode } from '@resistapp/common/types';
import { values } from 'lodash';
import { DEFAULT_GENE_GROUPING } from '../data-utils/gene-groups';

export enum QueryParams {
  GENE_GROUP_GROUPING = 'ggrouping',
  GENE_GROUPS = 'ggroups',
  ENVIRONMENT_TYPE = 'etype', // NOTE: this was extended to include comparable env groups
  ENVIRONMENTS = 'environments',
  NORMALISATION_MODE = 'normalisationMode', // Previously called absoluteMode
  START = 'start',
  END = 'end',
}

export enum OtherParams {
  VERIFICATION_CODE = 'code',
}

export function getGeneGroupingParams(searchParams: URLSearchParams) {
  const geneGroupParam = searchParams.get(QueryParams.GENE_GROUP_GROUPING);

  return isGeneGrouping(geneGroupParam) ? geneGroupParam : DEFAULT_GENE_GROUPING;
}

export function getGeneGroupsParams(searchParams: URLSearchParams) {
  const plainGeneGroupsParam = splitQueryParamArray(searchParams.get(QueryParams.GENE_GROUPS), true) ?? [];
  // Backwards compatibility for typo in antibiotic name
  const correctedGeneGroups = plainGeneGroupsParam.map(g => {
    if (g === 'Beta Lactam' || g === 'Beta+Lactam' || g === 'Beta%20Lactam') {
      return Targets['Beta-Lactam'];
    }
    return g;
  });

  return correctedGeneGroups.filter(g => g);
}

export function getEnvironmentTypeParam(searchParams: URLSearchParams): EnvironmentTypeGroup | undefined {
  const plainParam = searchParams.get(QueryParams.ENVIRONMENT_TYPE);
  // Backwards compatibility for old env type strings
  const environmentTypesParam =
    plainParam === 'Waste water' || plainParam === 'Waste+water' || plainParam === 'Waste%20water'
      ? EnvironmentType.WASTEWATER
      : plainParam === 'Surface water' || plainParam === 'Surface+water' || plainParam === 'Surface%20water'
        ? EnvironmentType.NATURAL_WATER
        : plainParam?.toUpperCase(); // 'Soil' etc

  if (!environmentTypesParam) {
    return undefined;
  }

  const environmentTypes = splitQueryParamArray(environmentTypesParam) as EnvironmentTypeGroup[];
  const validEnvironmentTypes = environmentTypes.filter(e => isComparableGroupOrEnvironmentType(e));

  if (validEnvironmentTypes.length > 1) {
    console.error(
      'Unexpectedly got multiple environment types in query params, reverting to one',
      validEnvironmentTypes,
    );
  }

  return validEnvironmentTypes[0] || undefined;
}

export function getEnvironmentsParams(searchParams: URLSearchParams, envNamesHaveComma: boolean) {
  const envsParamText = searchParams.get(QueryParams.ENVIRONMENTS);
  const environmentsParam = splitQueryParamArray(envsParamText, !envNamesHaveComma) ?? [];

  return environmentsParam.filter(g => !!g);
}

export function getNormalisationModeParam(searchParams: URLSearchParams): NormalisationMode {
  let mutatingNormalisationModeParam = searchParams.get(QueryParams.NORMALISATION_MODE);
  if (!mutatingNormalisationModeParam) {
    const legacyName = 'absoluteMode';
    const legacyAbsoluteModeParam = searchParams.get(legacyName);
    // Convert legacy boolean to new mode
    const newMode =
      legacyAbsoluteModeParam === 'true' ? NormalisationMode.TEN_UL_DILUTED_DNA : NormalisationMode.SIXTEEN_S;
    searchParams.set(QueryParams.NORMALISATION_MODE, newMode);
    mutatingNormalisationModeParam = newMode;
    searchParams.delete(legacyName);
  }

  // Validate that the mode is a valid NormalisationMode
  return Object.values(NormalisationMode).includes(mutatingNormalisationModeParam as NormalisationMode)
    ? (mutatingNormalisationModeParam as NormalisationMode)
    : NormalisationMode.SIXTEEN_S;
}

function isGeneGrouping(param?: string | null): param is GeneGrouping {
  return Boolean(param && param in allGeneGroups);
}

function isComparableGroupOrEnvironmentType(param?: string | null): param is EnvironmentTypeGroup {
  return Boolean(
    param &&
      (values(EnvironmentType as Record<string, string>).includes(param) ||
        values(ComparableEnvGroupType as Record<string, string>).includes(param)),
  );
}

export function mutateEnvironmentRelatedSearchParams(
  searchParams: URLSearchParams,
  group: EnvironmentTypeGroup,
  clearEnvs: boolean,
) {
  if (clearEnvs) {
    searchParams.delete(QueryParams.ENVIRONMENTS);
  }
  if (group === AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS) {
    searchParams.delete(QueryParams.ENVIRONMENT_TYPE);
  } else {
    // Note: we supported selecting multiple env types briefly in the past,
    // but to the best of my recollection the functionality was never really used
    const joinedTypes = joinQueryParamArray([group]);
    searchParams.set(QueryParams.ENVIRONMENT_TYPE, joinedTypes);
  }
}

export function mutateSearchParamsWithSelection(
  paramID: string,
  searchParams: URLSearchParams,
  wholeGroup: string[],
  next: string[],
  only = false,
) {
  // If the user wants to select only one value, we remove the old selections
  if (only) {
    searchParams.delete(paramID);
  }

  const filteredList = next.filter(n => wholeGroup.includes(n));
  // If the next group is the same as all groups, we don't add ALL the groups to the
  // URL, but instead empty URL === default to all groups selected
  const selectedEnvironments =
    JSON.stringify(next) !== JSON.stringify(wholeGroup) ? joinQueryParamArray(filteredList) : '';

  if (!selectedEnvironments) {
    searchParams.delete(paramID);
  } else {
    searchParams.set(paramID, selectedEnvironments);
  }
}

export function getVerificationCode(searchParams: URLSearchParams) {
  return searchParams.get(OtherParams.VERIFICATION_CODE);
}

// Note: changing this will break old URLs
// Split query param arrays with a human readable string unlikely to be found (though not forbidden) in env names
export const queryParamArraySeparator = '_-.-_';
export function splitQueryParamArray(param?: string | null, allowLegacyCommaSeparator?: boolean) {
  if (param && allowLegacyCommaSeparator && !param.includes(queryParamArraySeparator) && param.includes(',')) {
    return param.split(',');
  }
  return param?.split(queryParamArraySeparator);
}

export function joinQueryParamArray(param: string[]) {
  return param.join(queryParamArraySeparator);
}
