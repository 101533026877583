import { Box } from '@chakra-ui/react';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { RThirdTitle } from '@resistapp/client/components/headers/r-third-title';
import { Grid, GridCell } from '@resistapp/client/components/shared/grid';
import { FlexView } from '@resistapp/client/components/shared/layout';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { useScienceReference } from '@resistapp/client/hooks/use-science-reference';
import { FullProject } from '@resistapp/common/types';
import { chain } from 'lodash';

import { useUser } from '@resistapp/client/contexts/use-user-context';
import { safeVersionGte } from '@resistapp/common/version';
import { DownloadAndLinkSection } from './download-and-link-section';

interface Props {
  sampling: FullProject;
}

export function MethodAndDownloadSections(props: Props) {
  const { sampling } = props;
  const { user } = useUser();
  const analysisVersion =
    chain(sampling.samplesByUID).values().flatten().value()[0]?.abundances[0]?.analysisVersion || 'v0';

  if (!user) {
    return null;
  }

  return (
    <Grid>
      <GridCell widthpercentage={66}>
        <AnalysisDescription analysisVersion={analysisVersion} />
      </GridCell>
      <GridCell widthpercentage={34}>
        <DownloadAndLinkSection sampling={sampling} />
      </GridCell>
    </Grid>
  );
}

function AnalysisDescription(props: { analysisVersion: string }) {
  const [Ref1, Ref1Num] = useScienceReference({
    number: '1',
    href: 'https://doi.org/10.1073/pnas.1222743110',
    text: 'Zhu YG et al., 2013, Diverse and abundant antibiotic resistance genes in Chinese swine farms',
  });
  const [Ref2, Ref2Num] = useScienceReference({
    number: '2',
    href: 'https://doi.org/10.1093/femsec/fiw052',
    text: 'Muziasari WI et. al., 2016, Aquaculture changes the profile of antibiotic resistance and mobile genetic element associated genes…',
  });
  const [Ref3, Ref3Num] = useScienceReference({
    number: '3',
    href: 'https://doi.org/10.1093/femsec/fiy130',
    text: 'RD Stedtfeld et. al., 2018, Primer set 2.0 for highly parallel qPCR array targeting antibiotic resistance genes and mobile genetic elements',
  });
  const [Ref4, Ref4Num] = useScienceReference({
    number: '4',
    href: 'https://doi.org/10.1038/nprot.2008.73',
    text: 'TD Schmittgen & Livak JK., 2008, Analyzing real-time PCR data by the comparative Ct method',
  });
  return (
    <FlexView>
      <RSecondTitle>SmartChip qPCR Analysis Description</RSecondTitle>
      <Box style={{ paddingBottom: oldTheme.spacing.s }}>
        The presence and abundance of antibiotic resistance genes (ARGs), integrons and mobile genetic elements (MGEs),
        bacterial pathogens, microbial source tracking (MST) and 16S rRNA gene in each sample are analysed using
        customised primer sets in a high throughput method, SmartChip qPCR system. Several primer sets were designed to
        target sequence diversity within the gene target or to more specifically assess the environmental resistome,
        therefore, each primer set was analysed independently.
        <Ref1Num />
        <sup>,</sup>
        <Ref2Num />
        <sup>,</sup>
        <Ref3Num />
      </Box>
      <Box style={{ paddingBottom: oldTheme.spacing.s }}>
        Briefly, the SmartChip has 5184 reaction wells with a volume of 100 nL and filled using the SmartChip
        Multisample Nanodispenser. SmartChip TB Green Gene Expression Master Mix was used as recommended by the
        manufacturer. Other reaction mix reagents consisted of 20 nL of template DNA, 300 nM of forward and reverse
        primers and qPCR water. The qPCR cycling conditions and initial data processing was done as described below:
        <Box style={{ paddingLeft: oldTheme.spacing.l, paddingTop: oldTheme.spacing.xxs }}>
          <ul>
            <li>Initial denaturation step for 95°C for 10 minutes;</li>
            <li>40 cycles with denaturation at 95°C for 15 seconds;</li>
            <li>Annealing at 60°C for 15 seconds;</li>
            <li>Extension step at 72°C for 30 seconds;</li>
            <li>Melting curve analysis was measured at the end with an increase to 97°C by 0.4°C/step.</li>
          </ul>
        </Box>
      </Box>
      <Box style={{ paddingBottom: oldTheme.spacing.s }}>
        Melting curve analysis and PCR efficiency were performed on all of the samples for each primer set. Amplicons
        with unspecific melting curves and multiple peaks based on the slope of the melting profile according to the
        default settings from the manufacturer on SmartChip qPCR were considered to be false positives and discarded
        from the analysis.
      </Box>
      <Box style={{ paddingBottom: oldTheme.spacing.s }}>
        The threshold cycle (Ct), also known as the quantification cycle (Cq), uses a cutoff of 27 as the detection
        limit, so Ct values smaller than 28 are considered acceptable for detection.
        <Ref2Num />
        <sup>,</sup>
        <Ref3Num /> If the standard deviation between technical triplicates is greater than 0.5, the replicate furthest
        of the other two is removed. Similarly, one technical replicate with efficiency most outside the range of 2±0.1
        is removed.
        {safeVersionGte(props.analysisVersion, '3.2.0', '0.0.0') && (
          <>&nbsp;Only technical replicates with efficiencies between 2±0.3 were included in the analysis.</>
        )}
        {safeVersionGte(props.analysisVersion, '2.12.0', '0.0.0') && (
          <>
            &nbsp;Technical replicates identified as primer dimers based on Tm information from our continuous NTC
            analysis were removed from the analysis.
          </>
        )}
        &nbsp;Mean Ct of three technical replicates in each qPCR reaction was used to calculate the ΔCt values, unless
        the genes were detected in only one of the three technical replicates, in which case they were removed. The 2
        <sup>−ΔCt</sup> method (where ΔCt = Ct detected gene – Ct 16S rRNA gene) was used to calculate the relative
        abundances of the detected gene in proportion to the 16S rRNA gene in each sample <Ref4Num />. Estimated copy
        numbers for other genes have been calculated based on the relative abundance to 16S rRNA gene and the 16S rRNA
        slope run on SmartChip qPCR.
      </Box>
      <Box>
        <RThirdTitle>References</RThirdTitle>
        <Ref1 />
        <br />
        <Ref2 />
        <br />
        <Ref3 />
        <br />
        <Ref4 />
      </Box>
    </FlexView>
  );
}
