import type { FullSample } from '@resistapp/common/types';
import { chain, intersection, keys } from 'lodash';

export function getCountriesFromSamples(samples: FullSample[]) {
  return chain(samples)
    .map(s => s.country)
    .uniq()
    .value();
}

export function getCountryAdminLevelIntersection(flatSamples: FullSample[]) {
  // TODO: should we include the admin level intersection in this function (?)
  // TODO: support samples accross countries, and with mixed admin levels available
  const countries = chain(flatSamples)
    .map(s => s.country)
    .uniq()
    .value();

  // Ensure that we only use admin levels that are available for all samples
  // Rural samples might be lacking deeper admin levels that are present in big cities.
  // Eg. sample 36381 (-7.9734244,112.6308338) has levels 1-5 whereas sample 36369 (-6.1982399,106.8451211) has levels 1-9.

  const adminLevels =
    countries.length !== 1
      ? undefined
      : chain(flatSamples)
          .map(s => s.adminLevels)
          .map(levels => keys(levels || {}))
          .reduce((previous, current) => intersection(previous, current))
          .map(level => +level)
          .value();

  const country = countries.length === 1 ? countries[0] : undefined;
  return { country, adminLevels };
}
